import { Component, OnInit } from '@angular/core';
import { faAngleDoubleRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { SellerService } from '../../seller/services/seller.service'
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';

declare const $: any;

@Component({
  selector: 'app-edit-warehouse-address',
  templateUrl: './edit-warehouse-address.component.html',
  styleUrls: ['./edit-warehouse-address.component.css']
})
export class EditWarehouseAddressComponent implements OnInit {

  faAngleDoubleRight = faAngleDoubleRight;
  faCircle = faCircle;
  editAddId: any;
  dial_code: string = '+1';
  country: string;
  warehouse_city: any;
  municipalities_id: any;
  province_id: any;
  warehouse_address: string;
  zip_code: any;
  country_code: string = 'US';
  // mobile: any;
  // editCode: any = 'US';
  addressForm: FormGroup;
  initialCountrySet = "US"
  provinceList: any = [];
  municipalitiesDataList: any = [];
  provinceChanged: any = 0;

  constructor(
    private commonService: CommonService,
    private sellerService: SellerService,
    private router: Router,
    private fb: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,

) { translate.use(this.commonService.getLanguage()); }

ngOnInit() {

    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | Edit Address'); // set title 
    this.getProvinceList();

    //For Address form validation 
    this.addressForm = this.fb.group({

        address: ['', [Validators.required, Validators.minLength(2)]],
        add_province: ['', [Validators.maxLength(50), Validators.required]],
        municipalities: ['', [Validators.maxLength(50), Validators.required]],
        city: ['', [Validators.required, Validators.minLength(2)]],
        business_zip_code: ['', [Validators.required, Validators.minLength(5)]],
        warehouse_mobile_number: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(15), Validators.pattern('[0-9]+')]],
        name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]+')]]
    });
    // End
    
    // For petch address form value
    if (this.sellerService.userAddress) {
      this.getMunicipalities(this.sellerService.userAddress.province_id);
      this.editAddId = this.sellerService.userAddress.id;
    //   this.country = this.sellerService.userAddress.country;
      this.warehouse_city = this.sellerService.userAddress.city;
      this.warehouse_address = this.sellerService.userAddress.warehouse_address;
      this.zip_code = this.sellerService.userAddress.zip_code;
      // this.municipalities_id = this.sellerService.userAddress.municipalities_id;
      // this.province_id = this.sellerService.userAddress.province_id;

      this.dial_code = this.sellerService.userAddress.phone_dial_code;
      this.country = this.sellerService.userAddress.country;
      this.country_code = this.sellerService.userAddress.country_code;

      this.addressForm.patchValue({
          'address': this.sellerService.userAddress.warehouse_address,
          'warehouse_mobile_number': this.sellerService.userAddress.mobile_number,
          'city': this.sellerService.userAddress.city,
          'name': this.sellerService.userAddress.full_name,
          'business_zip_code': this.sellerService.userAddress.zip_code,
          'add_province': this.sellerService.userAddress.province_id,
          'municipalities': this.sellerService.userAddress.municipalities_id
      });

    } else {
        this.router.navigate(['/my-account/address']);
    }
}
// End

  // For edit address data
  editAddData(addData) {
    this.sellerService.userAddress = addData;
    this.router.navigate(['edit-warehouse-address']);
  }
  // End

      // For address form validation
      get address() { return this.addressForm.get('address'); }
      get add_province() { return this.addressForm.get('add_province'); }
      get municipalities() { return this.addressForm.get('municipalities'); }
      get city() { return this.addressForm.get('city'); }
    //   get warehouse_country() { return this.addressForm.get('warehouse_country'); }
      get business_zip_code() { return this.addressForm.get('business_zip_code'); }
      // End
      get warehouse_mobile_number() { return this.addressForm.get('warehouse_mobile_number'); }
      get name() { return this.addressForm.get('name'); }
  
      // For form validation check
      validateAllFormFields(formGroup: FormGroup) {         //{1}
          Object.keys(formGroup.controls).forEach(field => {  //{2}
              const control = formGroup.get(field);             //{3}
              if (control instanceof FormControl) {             //{4}
                  control.markAsTouched({ onlySelf: true });
              } else if (control instanceof FormGroup) {        //{5}
                  this.validateAllFormFields(control);            //{6}
              }
          });
      }
  
  
      //set auto detect country
      telInputObject(obj) {
          this.initialCountrySet = this.country_code
          // $.getJSON('https://ipapi.co/json/', function (data) {
          // 	this.initialCountrySet = data.country_code.toLowerCase();
          // 	this.country_code = data.country_code;
          // 	this.dial_code = data.country_calling_code;
          obj.setCountry(this.initialCountrySet);
          // });
      }
  
  
  
      // For get province list
      getProvinceList() {
          this.authService.getProvinceList()
              .subscribe(provinceData => {
                  this.commonService.hideLoader();
  
                  if (provinceData.status == 'success') {
                      this.provinceList = provinceData.data.province;
                  } else {
                      this.provinceList = [];
                  }
  
                  console.log(' this.provinceList', this.provinceList);
  
              }, err => {
                  this.commonService.hideLoader();
                  this.commonService.errorhandler(err);
              });
      }
      // End
  
      trimValue(formControl) {
          ((typeof formControl.value) && formControl.value.trim() == "") ?
              formControl.setValue("") : formControl.setValue(formControl.value);
      }
      // End
  
      // hasError($event) {
      //     if ($event) {
      //         this.addressForm.patchValue({ phone_number: "1" });
      //     } else {
      //         this.addressForm.patchValue({ phone_number: "" });
      //     }
      // }
  
      // For country change 
      onWarehouseCountryChange($event) {
          this.dial_code = `+${$event.dialCode}`;
          this.country = $event.name.split('(')[0];
          this.country_code = $event.iso2.toUpperCase();
      }
      // End
  
    //   For get number value
    //   getNumber($event) {
    //       this.addressForm.patchValue({ phone_number: $event.split(this.dial_code)[1] });
    //   }
    //   End
  
      // For edit address form 
      editAddSubmit() {

        
          
          if (this.addressForm.valid) {
              this.commonService.showLoader();

                let formData = `full_name=${this.addressForm.get('name').value}&mobile_number=${this.addressForm.get('warehouse_mobile_number').value}&phone_dial_code=${this.dial_code}&country=${this.country}&country_code=${this.country_code}&address=${this.addressForm.get('address').value}&zip_code=${this.addressForm.get('business_zip_code').value}&city=${this.addressForm.get('city').value}&province=${this.addressForm.get('add_province').value}&municipalities=${this.addressForm.get('municipalities').value}&address_id=${this.editAddId}`;
                
              this.sellerService.updateWarehouseAddress(formData).subscribe((data) => {

                  this.commonService.hideLoader();
  
                  if (data.status == 'fail') {
                      this.commonService.toastError(data.message);
                  } else {
                      this.commonService.toastSuccess(data.message);
                      this.router.navigate(['/seller/seller-account/warehouse']);
                      // seller/seller-account
                  }
  
              }, err => {
                  this.commonService.hideLoader();
                  this.commonService.errorhandler(err);
              });
  
          } else {

              // validate all form fields
              this.validateAllFormFields(this.addressForm); //{7}
          }
      }
      // End
  
      // For set enter only number for mobile number
      numberOnly(event): boolean {
          const charCode = (event.which) ? event.which : event.keyCode;
          if (charCode > 31 && (charCode < 48 || charCode > 57)) {
              return false;
          }
          return true;
      }
  
      // get provice id on change province 
      onProvinceChange(event: Event): void {
          const selectedProvinceID = (event.target as HTMLSelectElement).value;
          this.getMunicipalities(selectedProvinceID);
          this.provinceChanged = 1;
          this.addressForm.get('municipalities').setValue('');
      }
  
      // For get municipalities list 
      getMunicipalities(province_ids) {
  
          this.authService.getMunicipalities(province_ids).subscribe(municipalitiesData => {
              this.commonService.hideLoader();
              if (municipalitiesData.status == 'success') {
                  this.municipalitiesDataList = municipalitiesData.data.municipalities;
              } else {
                  this.municipalitiesDataList = [];
              }
  
          }, err => {
              this.commonService.hideLoader();
              this.commonService.errorhandler(err);
          });
      }
      // End

}
