import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShopService {
  apiUrl = environment.apiUrl;
  apiUrlVersionTwo = environment.apiUrlVersionTwo;
  apiUrlVersionThree = environment.apiUrlVersionThree;
  // apiUrlVersionThree = environment.apiUrlVersionTwo;


  constructor(private http: HttpClient) { }


  getProductList(limit, offset, userId, filter, page_type) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}product?limit=${limit}&offset=${offset}&userID=${userId}&searchTerm=${filter.search_term}&sort=${filter.sort}&category=${filter.category}&size=${filter.size}&color=${filter.color}&condition=${filter.condition}&warranty_applied=${filter.sellerWarranty}&rating=${filter.sellerRating}&arrival=${filter.newArrival}&discount=${filter.discount}&delivery_in=${filter.deliveryInData}&province=${filter.provinceData}&brand=${filter.brandData}&price=${filter.price}&deal=${filter.deal}&direct_pay=${filter.direct_pay}&page_type= ${page_type}&is_featured=${filter.is_featured}&is_promoted=${filter.is_promoted}&is_offer=${filter.is_offer}&seller_id=${filter.seller_id}`);
  }


  getProductListView(limit, offset, userId, filter, page_type,is_view_all) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}product?limit=${limit}&offset=${offset}&userID=${userId}&searchTerm=${filter.search_term}&sort=${filter.sort}&category=${filter.category}&size=${filter.size}&color=${filter.color}&condition=${filter.condition}&warranty_applied=${filter.sellerWarranty}&rating=${filter.sellerRating}&arrival=${filter.newArrival}&discount=${filter.discount}&delivery_in=${filter.deliveryInData}&province=${filter.provinceData}&brand=${filter.brandData}&price=${filter.price}&deal=${filter.deal}&direct_pay=${filter.direct_pay}&page_type= ${page_type}&is_featured=${filter.is_featured}&is_promoted=${filter.is_promoted}&is_offer=${filter.is_offer}&is_view_all=${is_view_all}`);
  }
  

    
  getProductListSuper(limit, offset, userId, filter, page_type, supermarket) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}product?limit=${limit}&offset=${offset}&userID=${userId}&searchTerm=${filter.search_term}&sort=${filter.sort}&category=${filter.category}&size=${filter.size}&color=${filter.color}&condition=${filter.condition}&warranty_applied=${filter.sellerWarranty}&rating=${filter.sellerRating}&arrival=${filter.newArrival}&discount=${filter.discount}&delivery_in=${filter.deliveryInData}&province=${filter.provinceData}&brand=${filter.brandData}&price=${filter.price}&deal=${filter.deal}&direct_pay=${filter.direct_pay}&page_type= ${page_type}&supermarket= ${supermarket}`);
  }





  //category subcategory list on shop page 
  getCategorySubacategoryList() {
    return this.http.get<any>(`${this.apiUrl}category`);
  }

  

  //super market 
  getSuperMarket(type) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}super-market/${type}`);
  }

  //super market 
  getSeller() {
    return this.http.get<any>(`${this.apiUrlVersionThree}seller-list`);
  }

  //super market 
  getSuperMarketSecondLevelCat() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}super-market-second-level-category`);
  }

    // //super market 
    // getBannerSuper(isCombo: any) {
    //   return this.http.get<any>(`${this.apiUrlVersionTwo}banner/${isCombo}`);
    // }

    getBannerSuper(isCombo?: any) {
      const url = `${this.apiUrlVersionTwo}banner${isCombo ? `/${isCombo}` : ''}`;
      return this.http.get<any>(url);
    }


  //category variant list on shop page 
  getVariantList() {
    return this.http.get<any>(`${this.apiUrl}variant`);
  }

  // new arrivals product lsit
  getNewArrivals(userId, limit = 8, offset = 0) {
    return this.http.get<any>(`${this.apiUrl}product?limit=${limit}&offset=${offset}&userID=${userId}`);
  }

  //Banner slider list on home page 
  getBannerSlider() {
    return this.http.get<any>(`${this.apiUrl}option/banner`);
  }

  // getProductDetail(id,userId) {
  //   return this.http.get<any>(`${this.apiUrl}product/${id}?userID=${userId}`);
  // }
  getProductDetail(id, userId) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}product/${id}?userID=${userId}`);
  }

  // product promote list
  // productPromote(formData) {
  //   return this.http.post<any>(`${this.apiUrlVersionTwo}product/promote-product-list`, formData);
  // }

  productPromote(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}product/promote-list`, formData);
  }

  // Ask question buyer
  askQuestions(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}product/question-answer`, formData);
  }

  // Offer status Check 
  offerAction(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}offer/offer-action`, formData);
  }

  //get card list
  getCardList() {
    return this.http.get<any>(`${this.apiUrl}card-list`);
  }

   card
  addCard(formData) {
    return this.http.post<any>(`${this.apiUrl}add-card`, formData);
  }

  //save card
  saveCard(formData) {
    return this.http.post<any>(`${this.apiUrl}card`, formData);
  }

  //add promote
  promote(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}product/promote-product`, formData);
  }

  //view count
  viewCount(formData) {
    return this.http.post<any>(`${this.apiUrl}product/view-count`, formData);
  }


  // //add to cart
  // addToCart(formdata) {
  //   return this.http.post<any>(`${this.apiUrl}cart`, formdata);
  // }


  // //add to cart
  // addToCart(formdata) {
  //   return this.http.post<any>(`${this.apiUrlVersionTwo}cart`, formdata);
    
  // }


   //add to cart  v3 api url
   addToCart(formdata) {
    return this.http.post<any>(`${this.apiUrlVersionThree}cart`, formdata);
    
  }



  // report Product 

  //add to cart
  reportProduct(formdata) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}product/product-report`, formdata);
  }

  //add to cart
  reportTitle() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}/report`);
  }


  //Featured product list on home page 
  getFeaturedList(userId) {
    return this.http.get<any>(`${this.apiUrl}product/featured?userID=${userId}`);
  }


  getBanner() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}option/banner`);
  }


  // //add wishlist
  // addUserWishlist(productId) {
  //   return this.http.put<any>(`${this.apiUrl}user/wishlist`, productId);
  // }


  //add wishlist
  addUserWishlist(productId) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}user/wishlist`, productId);
  }







  // //cart count
  // getCartCount() {
  //   return this.http.get<any>(`${this.apiUrl}cart/count`);
  // }


  //cart count
  getCartCount() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}cart/count`);
  }


  //cart count
  getWishCount() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}user/wishlist-count`);
  }

  // getSellerInfo(seller_id) {
  //   return this.http.get<any>(`${this.apiUrl}seller/seller-info?seller_id=${seller_id}`);
  // }


  getSellerInfo(seller_id) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}seller/seller-info?seller_id=${seller_id}`);
  }

  //Create customer on stripe 
  createStripeCustomer() {
    return this.http.put<any>(`${this.apiUrl}create-customer`, '');
  }

  getSubscriptionList() {
    return this.http.get<any>(`${this.apiUrl}seller/plan-list`);
  }

  getContent() {
    return this.http.get<any>(`${this.apiUrl}auth/content`);
  }

  getWebPushNotifiction(status) {
    return this.http.patch<any>(`${this.apiUrl}notification/web-push`, status);
  }

  getBrandList(category) {
    return this.http.get<any>(`${this.apiUrl}brand-list/?category=${category}`);
  }

  purchaseStripePlan(formdata) {
    return this.http.post<any>(`${this.apiUrl}seller/purchase-stripe-plan`, formdata);
  }

  purchaseSubscription(formdata) {
    return this.http.post<any>(`${this.apiUrl}seller/purchase-subscription`, formdata);
  }

  getSellerProfile() {
    return this.http.get<any>(`${this.apiUrl}seller/my-profile`);
  }

  //Remove offer from my list
  offerDelete(productId, productVariantID) {
    return this.http.delete<any>(`${this.apiUrl}offer/offer-delete/${productId}/${productVariantID}`);
  }

  cardVerify(formData) {
    return this.http.post<any>(`${this.apiUrl}card/verify`, formData);
  }

  //Deals list on home page 
  getDealList() {
    return this.http.get<any>(`${this.apiUrl}option/deal`);
  }

  //Deals list on home page 
  getCategory() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}product/home-category`);
  }

  //get product offer
  productOffer(limit, offset) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}offer/offer-list?limit=${limit}&offset=${offset}`);
  }

  promoteProductList() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}product/promote-list`);
  }


  getVariantQuantity(id) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}product/varinat-quantity?varian_id=${id}`);
  }



  getStoreList(limit,offset,filter) {
    return this.http.get<any>(`${this.apiUrlVersionThree}seller-list?limit=${limit}&offset=${offset}&search=${filter.search_term}&delivery_option=${filter.delivery_option}&category=${filter.category_option}`);
  }

  getStoreDetail(id, limit, offset) {
    return this.http.get<any>(`${this.apiUrlVersionThree}seller-list/${id}?limit=${limit}&offset=${offset}`);
  }
  


}
