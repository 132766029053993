import { Component, OnInit, ViewChild } from '@angular/core';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { BuyerService } from '../services/buyer.service';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { HeaderBuyerComponent } from '../../common/header-buyer/header-buyer.component';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
declare var $;

@Component({
	selector: 'app-cart-list',
	templateUrl: './cart-list.component.html',
	styleUrls: ['./cart-list.component.css']
})

export class CartListComponent implements OnInit {

	faTrashAlt = faTrashAlt
	faAngleDoubleRight = faAngleDoubleRight;
	limitOfCart: number = 10;
	offsetOfCart: number = 0;
	cartList = [];
	cartlistCount: any;
	pricing_detail: any = {};
	tax_detail: any = {};
	outOfStock: boolean = false;
	is_direct_payment_option: boolean = false;
	coupon;
	influencer_code_percentage: any;
	influencer_code_amount: any;
	@ViewChild('header', { static: true }) header: HeaderBuyerComponent;
	tax_detailPercent: any;
	currentUserId: any;
	constructor(
		private commonService: CommonService,
		private buyerService: BuyerService,
		private router: Router,
		private authService: AuthService,
		private translate: TranslateService
	) { translate.use(this.commonService.getLanguage()); }  // get user language 

	ngOnInit() {
		this.commonService.scrollToTop();
		this.commonService.setTitle('Mercadoba | Cart List'); // set titile 
		this.getCartList(); // get cart list api 
		localStorage.removeItem("coupon"); // 


		// for offer
		if (this.authService.getOfferId()) { // get offer item id
			localStorage.removeItem(environment.dataStore.offerId);
			
		}
	}

	// function for get card list 
	getCartList(): void {
		this.commonService.showLoader(); // show loader 
		var cardItemArray = [];
		var pickupAddressArray = [];
		localStorage.removeItem('cardJson');
		localStorage.removeItem('pickupAddsData');

		this.buyerService.getUserCartList().subscribe(  // call api for get user cart list 
			(cartlistData) => {

				this.commonService.hideLoader(); // hide loader 
				if (cartlistData.status == 'success') { // check  response status 

					this.cartlistCount = cartlistData.data.total_records; //total cart count 

					if (this.cartlistCount == undefined) { // check card count if cardlist cound is undefined 
						this.cartlistCount = 0; // than set count is 0 
					}

					cartlistData.data.cart_list.forEach(element => { // check  card data 

							if(element.is_delivery_option == 1 && element.is_pick_up_option ==1){
								var shippingType = 1; // delivery
							}else if(element.is_delivery_option == 1 && element.is_pick_up_option == 0 ){
								shippingType = 1; // delivery
							}else if(element.is_delivery_option == 0 && element.is_pick_up_option == 1 ){
								shippingType = 2; // pickup
							}else{
								shippingType = 1;
							}

							const cardItemsData: { [key: string]: any } = {};
							const pickupAddsData: { [key: string]: any } = {};
							if(element.warehouseAddress != undefined && shippingType == 2){
								// cardItemData
								cardItemsData.shipping_address_id = element.warehouseAddress.id;
								pickupAddsData.province = element.warehouseAddress.province;
								pickupAddsData.municipalities = element.warehouseAddress.municipalities;
								pickupAddsData.shipping_address_id = element.warehouseAddress.id;
								
							
							}else{
								cardItemsData.shipping_address_id = 0;
								pickupAddsData.province = 0;
								pickupAddsData.municipalities =0;
								pickupAddsData.shipping_address_id = 0;

							}
							// card data
							cardItemsData.cardItems = element.cartItemID;
							cardItemsData.shipping_type = shippingType;

							cardItemArray.push(cardItemsData);
							let cardJson = JSON.stringify(cardItemArray, null, 2);
							localStorage.setItem('cardJson', cardJson);


						console.log('element', element);
							pickupAddsData.shipping_type = shippingType;
							pickupAddsData.productId = element.productID;
							pickupAddsData.product_name = element.name;
							pickupAddressArray.push(pickupAddsData);

							let pickupAddresjson = JSON.stringify(pickupAddressArray, null, 2);
							localStorage.setItem('pickupAddsData',pickupAddresjson);


												
						
						var variantName = element.variant.map(function (item) {
						return item.variant_value.variant_value
						});

						element.quantity = +element.quantity;
						element.variantSize = variantName[0];
						element.variantColor = variantName[1];

						if (element.quantity == 1 && element.in_stock == 0) {
							element.minQty = 1;
							element.maxQty = element.quantity;
							this.outOfStock = true;


						} else if (element.quantity == 1) {
							element.minQty = 1;
						} else if (element.in_stock == 0) {
							element.minQty = element.quantity;
							element.maxQty = element.quantity;
							this.outOfStock = true;
						}

					});

					this.cartList = cartlistData.data.cart_list;
					this.pricing_detail = cartlistData.data.amount_details;

					if (this.cartList.length != 0) {
						var coupon = cartlistData.data.amount_details.coupon1_percentage;

						this.coupon = coupon.replace(/\.00$/, '');
						this.tax_detailPercent = this.pricing_detail.tax_percent.replace(/\.00$/, '');
						this.influencer_code_amount = this.pricing_detail.referral_code_amount;
						this.influencer_code_percentage = Math.round(this.pricing_detail.referral_code_percentage);
						this.tax_detail = cartlistData.data.tax_detail;
					}


				} else {
					this.cartlistCount = 0;
					this.cartList = [];
				}

			}, err => {
				this.commonService.hideLoader(); // hide loader 
				this.commonService.errorhandler(err);
			});

	}

	//Remove cart item
	removeCartItem(cartItemId) {
		this.commonService.showLoader();

		this.buyerService.removeCartItem(cartItemId).subscribe(
			(cartlistDataRemove) => {
				this.commonService.hideLoader();

				if (cartlistDataRemove.status == 'success') {

					this.getCartList();
					this.cartList = [];
					this.outOfStock = false;
					cartlistDataRemove.data.cart_list.forEach(element => {
						var variantName = element.variant.map(function (item) {
							return item.variant_value.variant_value
						});
						element.quantity = +element.quantity;
						element.variantSize = variantName[0];
						element.variantColor = variantName[1];

						if (element.quantity == 1 && element.in_stock == 0) {
							element.minQty = 1;
							element.maxQty = element.quantity;
							this.outOfStock = true;
						} else if (element.quantity == 1) {
							element.minQty = 1;
						} else if (element.in_stock == 0) {
							element.minQty = element.quantity;
							element.maxQty = element.quantity;
							this.outOfStock = true;
						}

					});

					this.cartList = cartlistDataRemove.data.cart_list;
					this.pricing_detail = cartlistDataRemove.data.pricing_detail;
					this.tax_detail = cartlistDataRemove.data.tax_detail;
					this.cartlistCount = cartlistDataRemove.data.total_records; //total cart 
					this.header.getCartCount(); //for decrease cart count
					// this.commonService.toastSuccess('Item removed');
					this.getCartList();


				} else {
					this.cartlistCount = 0;
					this.cartList = [];
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}




	//Change Quantity of cart 
	changeqty(index, value: number): void {

		let productData = this.cartList[index];
		if (productData.quantity > value) {
			this.decreaseCount(productData, value, index);

		} else {
			this.increaseCount(productData, value, index);

		}

	}


	//Decrease cart item
	decreaseCount(productData, decreaseQuantity, index) {
		this.commonService.showLoader();
		const cartItemId = productData.cartItemID;
		const formData = `product_varint_id=${escape(productData.productVariantID)}&product_quantity=${escape('1')}&type=${escape('decrease')}`;

		this.buyerService.updateCartDecrement(cartItemId, formData).subscribe(
			(cartlistDataDecrease) => {
				this.commonService.hideLoader();

				if (cartlistDataDecrease.status == 'success') {

					this.cartList[index].quantity = decreaseQuantity;

					if (decreaseQuantity == 1) {
						this.cartList[index].minQty = 1;
					}

					this.pricing_detail = cartlistDataDecrease.data.amount_details;
					var coupon = cartlistDataDecrease.data.amount_details.coupon1_percentage;

					this.coupon = coupon.replace(/\.00$/, '');
					this.tax_detailPercent = this.pricing_detail.tax_percent.replace(/\.00$/, '');

					this.influencer_code_amount = this.pricing_detail.referral_code_amount;
					this.influencer_code_percentage = Math.round(this.pricing_detail.referral_code_percentage);
					this.header.getCartCount();// for decrease card count
					// this.commonService.toastSuccess('Cart updated successfully!');

				} else {
					this.commonService.toastError(cartlistDataDecrease.message);
				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}

	//Increase cart item quantity
	increaseCount(productData, increaseQuantity, index) {
		this.commonService.showLoader();
		const cartItemId = productData.cartItemID;
		const formData = `product_varint_id=${escape(productData.productVariantID)}&product_quantity=${escape('1')}&type=${escape('increase')}`;
		this.buyerService.updateCartIncrement(cartItemId, formData).subscribe(
			(cartlistDataIncrease) => {
				this.commonService.hideLoader();

				if (cartlistDataIncrease.status == 'success') {
					this.cartList[index].quantity = increaseQuantity;

					this.pricing_detail = cartlistDataIncrease.data.amount_details;
					var coupon = cartlistDataIncrease.data.amount_details.coupon1_percentage;


					this.coupon = coupon.replace(/\.00$/, '');
					this.influencer_code_amount = this.pricing_detail.referral_code_amount;
					this.influencer_code_percentage = Math.round(this.pricing_detail.referral_code_percentage);


					this.header.getCartCount();// for increase card count
					// this.commonService.toastSuccess('Cart updated successfully!');

				} else {
					this.commonService.toastError(cartlistDataIncrease.message);
				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}


	//open popup for delete
	confirmation() {
		$('#deleteItem').modal('hide');
		this.ngOnInit();
		// this.router.navigate(['/cartlist']);

	}

	// confirmation pop up check products are available or not in cart 
	confirmationPopup() {
		$('#deleteItem').modal('show');

	}


	// redirect url 


	   // End
	   redirectFunction(detail, name,id1){
		var name1 = (name.split(' ').join(''));

		var url = encodeURIComponent(name1).replace(/[!'()*]/g, function (c) {
			return '-' + c.charCodeAt(0).toString(16);
		});

		var removeSpace = (url.split('%').join('-'));
		var validUrl = detail+'/'+removeSpace+'/'+id1;
		this.router.navigate([validUrl]);
	
	  }


	//Redirect from cart page to add address page
	address() {
		// check for any product is out of stock
		if (this.outOfStock) {
			this.commonService.toastError('Please remove out of stock item from your cart to continue.');
		} else {




			this.currentUserId = this.authService.getCurrentUserId(); //current user ID

			this.buyerService.checkProduct(this.currentUserId, this.cartlistCount).subscribe(
				(productCheck) => {

					// if (productCheck.status == 'success') {
						if (this.outOfStock) {
							this.commonService.toastError('Please remove out of stock item from your cart to continue.');
						} else {

							if (productCheck.flag == 0) {
								this.header.getCartCount();
								this.confirmationPopup();
							} else {


								if (this.authService.getOfferId()) {
									// this.router.navigate(['/checkout/offer']);
									this.router.navigate(['/checkout-list/offer']);

								} else {
									// this.router.navigate(['/checkout']);
									this.router.navigate(['/checkout-list']);
								}
							}
						}
					// }
				}, err => {
					this.commonService.hideLoader();
					this.commonService.errorhandler(err);
				});

		}
	}
}