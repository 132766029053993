export const environment = {
    production: true,
    projectName:'Mercadoba-staging',
	apiUrl:'https://api.staging.mercadoba.com/api/v1/',
	apiUrlVersionTwo:'https://api.staging.mercadoba.com/api/v2/',
	apiUrlVersionThree:'https://api.staging.mercadoba.com/api/v3/',


    // apiUrl:'http://192.168.1.180/mercadoba//api/v1/',
	// apiUrlVersionTwo:'http://192.168.1.180/mercadoba//api/v2/',
	// apiUrlVersionThree:'http://192.168.1.180/mercadoba//api/v3/',

    // siteUrl:'https://www.mercadoba.com',
    siteUrl:'https://staging.mercadoba.com',
    
    dataStore:{
        userData:'qvazonUserStaging',
        redirectUri:'redirectUriStaging',
        userAddress:'userAddressStaging',
        userCard:'userCardStaging',
        userDeviceId:'userDeviceIdStaging',
        offerId:'offerIdStaging',
        language:'userLanguage'
    },
    notificationKey:'AAAAiJcua80:APA91bFejHb3GF6eUQXvOGca2Rk1xA38Cp3hhADjONdQ8VnDIM4o0ABC0jTmR1y3ssGO-Imy6AwVIV3C4hajXF2cwoWTYeeLsDn3laxQCIaX56IVvoByolHnTZOyeY_q_0FZmiOrpQDS',
    striePkKey:'pk_live_51Lik7MBhvmIgOxg7OKKwbCEEcVeS88PjH1QLksAFUK4HipgZSp6CmoTC9Qj4PhhGvQaE3aMwEJrr4xB8fOUnqsnb00HQjkwvcu',
	googleClientId: '224500781666-ie8mu3tblti6tdhrnjhjn7v3g6qo0ov5.apps.googleusercontent.com',
    FacebookClientId:'584171212190946',
    storageBucketFile:'chat_photos/',
    
    // firebase : {
	// 	apiKey: "AIzaSyDWJevS-DakseGmiHuAN9bzpz_IJX9oiT8",
	// 	authDomain: "crack-audio-431302-q4.firebaseapp.com",
	// 	databaseURL: "https://crack-audio-431302-q4-default-rtdb.firebaseio.com",
	// 	projectId: "crack-audio-431302-q4",
	// 	storageBucket: "crack-audio-431302-q4.appspot.com",
	// 	messagingSenderId: "882600544135",
	// 	appId: "1:882600544135:web:cf9ed7584312a0fd0bb522"
	// }


    firebase : {
        apiKey: "AIzaSyB7sR0tkVz7R27V-ahe6u-XMwOKonF5Huc",
        authDomain: "mercadoba-sataging.firebaseapp.com",
        projectId: "mercadoba-sataging",
        storageBucket: "mercadoba-sataging.firebasestorage.app",
        messagingSenderId: "224500781666",
        appId: "1:224500781666:web:d0250fb03f400a53a9373b",
        databaseURL: "https://mercadoba-sataging-default-rtdb.firebaseio.com",
        
    }
      
};