import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { faAngleDoubleRight, faCheck, faCircle, faTimes, faUpload, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { SellerService } from '../services/seller.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ShopService } from '../../services/shop.service';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { FirebaseService } from '../services/firebase.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
declare const $: any;
declare const checkImageSize: any;

@Component({
	selector: 'app-edit-product',
	templateUrl: './edit-product.component.html',
	styleUrls: ['./edit-product.component.css'],
	animations: [
		trigger('enterTrigger', [
			state('fadeIn', style({
				opacity: '1',
			})),
			transition('void => *', [style({ opacity: '0' }), animate('500ms')])
		])
	]
})


export class EditProductComponent implements OnInit, AfterViewChecked {
	faAngleDoubleRight = faAngleDoubleRight;
	faCheck = faCheck;
	faCircle = faCircle;
	faTimes = faTimes;
	faUpload = faUpload;
	faEdit = faEdit;
	faTrashAlt = faTrashAlt;
	minDate: any;
	productId: any;
	productDetail: any = {};
	subCategoryLevel: number = 0;
	categoryList: any = [];
	subCategoryList1: any = [];
	subCategoryList2: any = [];
	subCategoryList3: any = [];
	subCategoryList4: any = [];
	variantsList: any = [];
	editProductForm: FormGroup;
	brandList: any = [];
	warrantyList: any = [];
	isWarrantyApply: boolean = true;
	finialVariantList: any = [];
	selectedVariantList: any = [];
	shipChargeFlag: boolean = false;
	keyFeatureList: any = [];
	variantsValuesList: any = [];
	variantModel: any = {};
	qtd: any = [];
	disabled: boolean = false;
	editqtd: any = [];
	product_image: any = [];
	removeImageArray: any = [];
	alldata :any = 0;
	editVariantModel: any = {
		amount: "",
		sku: "",
		showlist: "",
		editqtd: []
	};
	editVariantindex: any;
	variantsEditValuesList: any = [];
	imageChangedEvent: any = '';
	croppedImage: any = '';
	cropImageShow: boolean = false;
	galleryImageChangedEvent: any = '';
	croppedGalleryImage: any = '';
	galleryList: any = [];
	dropdownProvinceSettings: any = {};
	ShowFilter = false;
	provinceList: any = [];
	selectedprovince: any = [];
	selectedmunicipalities: any = [];
	sellingAmount;
	sellingAmount1;
	sellingAmountPercentage;
	sellingAmountPercentagev: 0;
	selling_price1
	comissionData: any = [];
	selling_price
	gallery_image: any = [];
	is_add = 1;
	gallery_image_url;
	gallery_image1;
	returningDay: boolean = false;
	offerList;


	offerv;
	selevtedInmg: [];
	offerVariantPrice;
	offerVariantPriceCommission
	offer_price_commision_percent;

	cod: any;
	globlepay: any;
	directpay: any;
	make_and_offer: any;
	pickup: any;
	delivery: any;
	addPakageList: any = [];
	offerListcomission
	public value = "1";

	limitedOffer: boolean = false;
	municipalitiesAddDataList: any = [];
	provinceIdForMunicipalities: any = [];
	dropdownMunicipalitiesSettings: any = {};
	scale = 1;
	transform: ImageTransform = {};
	transformgallery: ImageTransform = {};
	is_no_variants: any = 0;

	@ViewChild('f', { static: true }) form: any;
	@ViewChild('editF', { static: true }) editF: any;
	provinceGetListForShipping: any;
	lastSel: any;
	provinceIdss: any[];
	shippingIds: any[];
	shiipingChargesList: any;
	shippingListCount: number;
	ImageUrl: any;
	swalShow: any = 0;
	PackageLimit: any = 0;
	noVariantsImage: any = [];
	noVariantQuantity: any;
	noVariantWeight: any;
	imageId: any = [];
	FImage: any;
	isUrl: any;
	variantsCheck: any = 0;
	categoryType: any;
	ProvinceFieldValidation: any = [];

	provinceIdsArray: any;
	filteredProvinces: any = [];
	selectedValues: string[] = [];
	selectedValuesCheck: { [key: number]: string } = {};

	is_pickup_checked: any = '0';
	is_delivery_checked: any ;

	convertedObject: any;
	seller_warehouse_ids: any;

	warehouseAddressProductList: any = [];
    warehouseAddressProductListCount: any;
	is_pick_up_option_active: string = '0';
	is_pickup_options: any;

	constructor(
		private activatedRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private commonService: CommonService,
		private sellerService: SellerService,
		private shopService: ShopService,
		private firebaseService: FirebaseService,
		private router: Router,
		private translate: TranslateService,
		private authService: AuthService
	) { translate.use(this.commonService.getLanguage());

		this.formBuilder.group({
			province: [''], // Default value
			municipality: ['']
		});
	 }

	ngAfterViewChecked(): void {
		this.dropdownProvinceSettings = {
			singleSelection: false,
			idField: 'provinceID',
			textField: 'name',
			itemsShowLimit: 2,
			allowSearchFilter: this.ShowFilter,
			selectAllText: this.translate.instant('HOME.selectAll'),
			unSelectAllText: this.translate.instant('HOME.unselectAll'),
		};
		this.dropdownMunicipalitiesSettings = {
			singleSelection: false,
			idField: 'id',
			idField2: 'province_id',
			textField: 'municipalityName',
			itemsShowLimit: 2,
			allowSearchFilter: this.ShowFilter,
			selectAllText: this.translate.instant('HOME.selectAll'),
			unSelectAllText: this.translate.instant('HOME.unselectAll'),
		};
	}

	ngOnInit() {

		// console.log('is_pick_up_option_active',this.is_pick_up_option_active);
		// console.log('is_pickup_checked',this.is_pickup_checked);
		
		
		
		console.log('filteredProvinces--- +++',this.filteredProvinces);
		

		this.dropdownProvinceSettings = {
			singleSelection: false,
			idField: 'provinceID',
			textField: 'name',
			itemsShowLimit: 1,
			allowSearchFilter: this.ShowFilter,
			selectAllText: this.translate.instant('HOME.selectAll'),
			unSelectAllText: this.translate.instant('HOME.unselectAll'),
		};
		this.dropdownMunicipalitiesSettings = {
			singleSelection: false,
			idField: 'id',
			idField2: 'province_id',
			textField: 'municipalityName',
			itemsShowLimit: 1,
			allowSearchFilter: this.ShowFilter,
			selectAllText: this.translate.instant('HOME.selectAll'),
			unSelectAllText: this.translate.instant('HOME.unselectAll'),
		};


		this.commonService.scrollToTop();
		this.commonService.setTitle('Mercadoba | Edit Product');
		this.productId = this.activatedRoute.snapshot.paramMap.get("id"); //For product Id
		this.minDate = this.commonService.getCurrentDateObject();

		const currentUser = JSON.parse(localStorage.getItem(environment.dataStore.userData));

		this.cod = currentUser.business_info.is_cod;
		this.pickup = currentUser.business_info.is_pickup;
		this.globlepay = currentUser.business_info.id_global_cod;
		this.directpay = currentUser.business_info.is_direct_pay;
		this.make_and_offer = currentUser.business_info.make_and_offer; //Direct Pay


		// Function for edit product form
		this.editProductForm = this.formBuilder.group({
			category: ['', Validators.required],
			subcategory1: ['', Validators.required],
			subcategory2: ['', Validators.required],
			subcategory3: ['', Validators.required],
			subcategory4: ['', Validators.required],
			isVariant: ['', Validators.required],
			name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
			condition: ['', Validators.required],
			brand: ['', Validators.required],
			stocks: [''],
			feature: [''],
			deliveryIn: ['', Validators.required],
			showTill: [''],
			startDAte: [''],
			endDAte: [''],
			make_offer: [''],
			warrantyApply: ['1'],
			warranty: ['', Validators.required],
			regularPrice: ['', [Validators.required, Validators.pattern('^[0-9]{1,10}((\.[0-9]{1,10}))?$')]],
			province: ['', [Validators.required]],
			municipalities: ['', [Validators.required]],
			shipCharge: ['1'],
			shipAmount: ['', [Validators.required]],

			offer_price: [''],
			days_of_return: [''],
			description: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5000)]],
			description_es: [''],
			featuredImage: ['', Validators.required],
			isVariantAdded: ['', Validators.required],
			return: ['', Validators.required],
			is_cod: [''],
			is_pickup: [''],
			is_delivery: [''],
			is_direct_payment: [''],
			make_and_offer_option: [''],
			product_sku: ['', Validators.required],
			product_quantity: ['', Validators.required],
			product_weight: ['', Validators.required],
		});
		// End

		this.getCategoryList(); //For category list
		this.getWarrantyList(); //For warranty list
		this.getProvinceList(); //For province list
		this.getComissionList(); //For commission list
		this.getShippingList();  //For shipping list

		// console.log('is_pickup_checked',this.is_pickup_checked);
		// console.log('is_pick_up_option_active',this.is_pick_up_option_active);
		

	}
	// End oninit
	placeholderText: string = this.translate.instant('COMMON.select_address');


	onPickupChange(event: Event): void {
		
		const isChecked = (event.target as HTMLInputElement).checked;
		console.log('Pickup Checkbox is checked:', isChecked);
		console.log('ddd:', this.editProductForm.value.is_delivery);
	
		if (isChecked) {
			// When Pickup is checked, update its state
			this.is_pickup_checked = '1';
		} else {
			this.placeholderText = this.translate.instant('COMMON.select_address');

			// When Pickup is unchecked
			this.is_pickup_checked = '0';
			this.is_pick_up_option_active = '0';

			// If Delivery is also OFF, turn it ON
			const isDeliveryChecked = this.editProductForm.value.is_delivery;
			if (!isDeliveryChecked) {
				this.editProductForm.patchValue({ is_delivery: true });
				this.is_delivery_checked = '1';
				this.is_pickup_checked = '0';
				const deliveryCtrl = this.editProductForm.get('deliveryIn');
			if (this.is_delivery_checked == 1) {
				deliveryCtrl.setValidators(Validators.required);
			} else {
				deliveryCtrl.clearValidators();
				this.editProductForm.get('deliveryIn').setValue('');
			}
			deliveryCtrl.updateValueAndValidity();
			}
		}
	
		console.log('Pickup status:', this.is_pickup_checked);
		console.log('Delivery status:', this.is_delivery_checked);
	}
	
	onDeliveryChange(event: Event): void {
		const isChecked = (event.target as HTMLInputElement).checked;
		console.log('Delivery Checkbox is checked:', isChecked);
	
		if (isChecked) {
			// When Delivery is checked, update its state
			this.is_delivery_checked = '1';
		} else {
			// When Delivery is unchecked
			this.is_delivery_checked = '0';
	
			// If Pickup is also OFF, turn it ON
			const isPickupChecked = this.editProductForm.value.is_pickup;
			if (!isPickupChecked) {
				this.editProductForm.patchValue({ is_pickup: true });
				this.is_pickup_checked = '1';
				this.is_delivery_checked = '0';
			}
		}
		
		console.log('Pickup status:', this.is_pickup_checked);
		console.log('Delivery status:', this.is_delivery_checked);
	}

		// For check delivery validation
		checkDelivery($event) {
		
			const deliveryCtrl = this.editProductForm.get('deliveryIn');
			const returnctrl = this.editProductForm.get('days_of_return');

			if ($event.target.checked) {
				deliveryCtrl.setValidators(Validators.required);
			} else {
				deliveryCtrl.clearValidators();
				this.editProductForm.get('deliveryIn').setValue('');
				returnctrl.clearValidators();
				this.returningDay = false;
				this.editProductForm.patchValue({ return: false });
				this.editProductForm.get('days_of_return').setValue('');
				returnctrl.updateValueAndValidity();

			}
			deliveryCtrl.updateValueAndValidity();
		}
	

	getSelectedValuesCount(): number {
		let count = 0;
	  
		// Iterate through the select elements by their dynamically generated IDs
		for (let i = 0; i < this.filteredProvinces.length; i++) {
		  const selectElement = document.getElementById(`municipalitySelect${i}`) as HTMLSelectElement;
	  
		  if (selectElement && selectElement.value) {
			count++;
		  }
		}
	  
		return count;
	}

	checkFinalSubmit() {


		const totalSelected = this.getSelectedValuesCount();
		// console.log('Total selected values:', totalSelected);
		// console.log('filteredProvinces :::::::::', this.filteredProvinces);
		// console.log('filteredProvinces values:', this.filteredProvinces.length);
		 


			// Check if all `filteredProvinces` have corresponding selected values
			const allSelected = totalSelected === this.filteredProvinces.length;

			if (allSelected) {
				console.log('All dropdowns have selected values:', this.selectedValues);
				$('#addProdConfirm').modal('show');
			} else {
				console.log('Some dropdowns are still unselected:', this.selectedValues);
	
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.warehouse_addresses_required'));
				return false;

				// validate all form fields
				// this.validateAllFormFields(this.editProductForm); //{7}
			}
	}

	// Function for get commision list
	getComissionList() {
		this.sellerService.getComissionList().subscribe(comissionData => {
			this.commonService.hideLoader();

			if (comissionData.status == 'success') {
				this.comissionData = comissionData.data.commission_range;
			}
		}, err => {
			this.commonService.errorhandler(err);
		});
	}
	// End

	//get province list
	getProvinceList() {
		this.authService.getProvinceList()
			.subscribe(provinceData => {
				this.commonService.hideLoader();
				if (provinceData.status == 'success') {
					this.provinceList = provinceData.data.province;
					this.provinceList.map((val) => {
					})
				} else {
					this.provinceList = [];
				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}
	// End

	// For Form validation
	get category(): any { return this.editProductForm.get('category'); }
	get subcategory1(): any { return this.editProductForm.get('subcategory1'); }
	get subcategory2(): any { return this.editProductForm.get('subcategory2'); }
	get subcategory3(): any { return this.editProductForm.get('subcategory3'); }
	get subcategory4(): any { return this.editProductForm.get('subcategory4'); }
	get isVariant(): any { return this.editProductForm.get('isVariant'); }
	get name(): any { return this.editProductForm.get('name'); }
	get condition(): any { return this.editProductForm.get('condition'); }
	get brand(): any { return this.editProductForm.get('brand'); }
	get deliveryIn(): any { return this.editProductForm.get('deliveryIn'); }
	get warranty(): any { return this.editProductForm.get('warranty'); }
	get regularPrice(): any { return this.editProductForm.get('regularPrice'); }
	get province(): any { return this.editProductForm.get('province'); }
	get municipalities(): any { return this.editProductForm.get('municipalities'); }
	get shipAmount(): any { return this.editProductForm.get('shipAmount'); }
	get offer_price(): any { return this.editProductForm.get('offer_price'); }
	get description(): any { return this.editProductForm.get('description'); }
	get description_es(): any { return this.editProductForm.get('description_es'); }
	get featuredImage(): any { return this.editProductForm.get('featuredImage'); }
	get isVariantAdded(): any { return this.editProductForm.get('isVariantAdded'); }
	get days_of_return(): any { return this.editProductForm.get('days_of_return'); }
	get return(): any { return this.editProductForm.get('return'); }
	get make_offer(): any { return this.editProductForm.get('make_offer'); }
	get product_sku(): any { return this.editProductForm.get('product_sku'); }
	get product_quantity(): any { return this.editProductForm.get('product_quantity'); }
	get product_weight(): any { return this.editProductForm.get('product_weight'); }
	// End

	//Get product Detail
	getProductDetail(productId, currentUserId) {

		this.shopService.getProductDetail(productId, currentUserId).subscribe((productDetailData) => {

			console.log('productDetailData productDetailData 123',productDetailData.data.product_detail
			);
			


			if (productDetailData.data.product_detail.is_no_variants == 1) {
				this.is_no_variants = 1;
				this.noVariantQuantity = productDetailData.data.product_detail.variant_list[0].quantity;
				// this.noVariantWeight = productDetailData.data.product_detail.variant_list[0].weight;

				if (productDetailData.data.product_detail.variant_list[0].weight > 0) {

					this.noVariantWeight = productDetailData.data.product_detail.variant_list[0].weight
				} else {
					this.noVariantWeight = ""
				}
			}

			if (productDetailData.status == 'success') {
				this.productDetail = productDetailData.data.product_detail;
				this.is_delivery_checked=  this.productDetail.is_delivery_option;


				if(this.productDetail.is_pick_up_option == '1'){

					this.is_pick_up_option_active = '1';
				}

				console.log('is_pick_up_option_active ---', this.is_pick_up_option_active);
				

				this.is_pickup_options = this.productDetail.pickup_options;
				this.is_pickup_checked = this.productDetail.is_pick_up_option;

				console.log('pickup_options -----',this.productDetail.pickup_options);
				

				this.categoryType = this.productDetail.category_type;
				var ImageUrl = productDetailData.data.product_detail.variant_list[0];
				this.ImageUrl = ImageUrl.gallery_image_url;
				this.sellingAmount = productDetailData.data.product_detail.selling_price;
				this.sellingAmountPercentage = Number(productDetailData.data.product_detail.selling_product_commission);

				this.offerList = productDetailData.data.product_detail.offer_selling_price;
				this.offerListcomission = Number(productDetailData.data.product_detail.offer_selling_price_commission_percent);
				this.selevtedInmg

				if (productDetailData.data.product_detail.is_offer == 1) {
					this.limitedOffer = true;
				}

				if (productDetailData.data.product_detail.is_return == 1) {
					this.returningDay = true;
				}

				if (productDetailData.data.product_detail.shipping_applied == '1') {
					this.shipChargeFlag = true;

				}

				this.subCategoryLevel = Number(this.productDetail.category_id.split(',').length) - Number(1);
				this.showSelectedCategory();
				// get province data
				if (this.productDetail.province) {
					var provincesArr = this.productDetail.province.split(",")
					this.getAddMunicipalities(provincesArr.toString());

					this.provinceList.map((val) => {
						if (provincesArr.includes(val.provinceID)) {
							this.selectedprovince.push(val);
							this.provinceIdForMunicipalities.push(val.provinceID)
							this.ProvinceFieldValidation.push(val.provinceID)

						}

					})


				}

				console.log('productDetail.municipalitiesproductDetail.municipalities -----',this.productDetail.municipalities);
				
				// Check if municipalities data exists
				if (this.productDetail.municipalities) {
					const selectedMunicipalitiesArr = this.productDetail.municipalities.split(",");
					this.commonService.showLoader();
					// Use a setTimeout to simulate async behavior if necessary
					setTimeout(() => {
						this.selectedmunicipalities = this.municipalitiesAddDataList.filter(val => 
							selectedMunicipalitiesArr.includes(val.id)
						);
						// console.log('this.selectedmunicipalities', this.selectedmunicipalities);

						// console.log('selectedMunicipalitiesArr', selectedMunicipalitiesArr);

						// Set the province list for shipping
						this.provinceGetListForShipping = [...this.selectedmunicipalities];
						console.log('provinceGetListForShipping', this.provinceGetListForShipping);
						this.alldata = 1;
						this.commonService.hideLoader();
					}, 5000);
				}

				// warranty applied
				const warrantyCtrl = this.editProductForm.get('warranty');
				if (this.productDetail.warranty_applied == '1') {
					warrantyCtrl.setValidators(Validators.required);
					this.isWarrantyApply = true;
				} else {
					warrantyCtrl.clearValidators();
					this.isWarrantyApply = false;
				}
				warrantyCtrl.updateValueAndValidity();

				const deliveryCtrl = this.editProductForm.get('deliveryIn');
				if (this.productDetail.is_delivery_option == '1') {
					deliveryCtrl.setValidators(Validators.required);
					this.isWarrantyApply = true;
				} else {
					deliveryCtrl.clearValidators();
					this.isWarrantyApply = false;
				}
				warrantyCtrl.updateValueAndValidity();

				const offerCtrl = this.editProductForm.get('offer_price');
				if (this.productDetail.is_offer == '1') {
					offerCtrl.setValidators([Validators.required, Validators.pattern('^[0-9]{1,10}((\.[0-9]{1,2}))?$')]);
					this.limitedOffer = true;
				} else {
					offerCtrl.clearValidators();
					this.limitedOffer = false;
					this.productDetail.offer_price = "";


				}
				offerCtrl.updateValueAndValidity();
				const returnctrl = this.editProductForm.get('days_of_return');
				if (this.productDetail.is_return == '1') {

					returnctrl.setValidators([Validators.required]);
					this.returningDay = true;
				} else {
					returnctrl.clearValidators();
					this.returningDay = false;
					this.editProductForm.get('days_of_return').setValue('');

				}
				returnctrl.updateValueAndValidity();

				// shipping applied
				const shipAmountCtrl = this.editProductForm.get('shipAmount');
				if (this.productDetail.shipping_applied == '1') {
					shipAmountCtrl.setValidators([Validators.required]);
					this.shipChargeFlag = true;
				} else {
					shipAmountCtrl.clearValidators();
					this.shipChargeFlag = false;
				}
				shipAmountCtrl.updateValueAndValidity();

				this.keyFeatureList = JSON.parse(this.productDetail.key_features);

				if (this.productDetail.packages == "") {

				} else {
					this.addPakageList = JSON.parse(this.productDetail.packages);
				}

				this.setVariantsFilled(JSON.parse(this.productDetail.variants));
				// this.product_image = this.productDetail.gallery_images;
				this.productDetail.gallery_images.forEach((element) => {
					// element['gallery_image_url'] =`${element.gallery_image_url}card/${element.gallery_image}`
					let imageObject = {
						'productAttachmentID': element.gallery_image,
						'gallery_image_url': `${element.gallery_image_url}${element.gallery_image}`,
						'imageId': element.temp_id,
						'type': 1,
					}

					this.product_image.push(imageObject);
					this.imageId.push(Number(element.temp_id));
				})

				// this.croppedImage = `${this.productDetail.feature_image_url}thumb/${this.productDetail.feature_image}`;
				this.cropImageShow = true;
				this.galleryList = this.productDetail.gallery_images;

				let showTillDate = {};
				if (this.productDetail.show_till != null) {
					let showTillDateArray = this.productDetail.show_till.split('-');
					showTillDate = {
						"year": Number(showTillDateArray[0]),
						"month": Number(showTillDateArray[1]),
						"day": Number(showTillDateArray[2])
					}
				} else {
					showTillDate = '';
				}

				let startDAte1 = {};
				if (this.productDetail.offer_start != null || this.productDetail.is_offer != 1) {
					let offer_start_array = this.productDetail.offer_start.split(' ');
					let offer_start_array1 = offer_start_array[0].split('-');

					startDAte1 = {
						"year": Number(offer_start_array1[0]),
						"month": Number(offer_start_array1[1]),
						"day": Number(offer_start_array1[2])
					}
				} else {
					startDAte1 = '';
				}


				let endDAte1 = {};
				if (this.productDetail.offer_start != null) {
					let offer_start_array = this.productDetail.offer_end.split(' ');
					let offer_start_array1 = offer_start_array[0].split('-');

					endDAte1 = {
						"year": Number(offer_start_array1[0]),
						"month": Number(offer_start_array1[1]),
						"day": Number(offer_start_array1[2])
					}
				} else {
					endDAte1 = '';
				}

				this.editProductForm.patchValue({
					name: this.productDetail.name,
					description: this.productDetail.description,
					description_es: this.productDetail.description_es,
					stocks: (this.productDetail.in_stock == '1') ? true : false,
					is_cod: (this.productDetail.cod_option == '1') ? true : false,
					is_direct_payment: (this.productDetail.is_direct_payment_option == '1') ? true : false,
					make_and_offer_option: (this.productDetail.make_and_offer_option == '1') ? true : false,
					is_pickup: (this.productDetail.is_pick_up_option == '1') ? true : false,
					is_delivery: (this.productDetail.is_delivery_option == '1') ? true : false,

					return: (this.productDetail.is_return == '1') ? true : false,
					make_offer: (this.productDetail.is_offer == '1') ? true : false,
					feature: (this.productDetail.is_featured == '1') ? true : false,
					warrantyApply: (this.productDetail.warranty_applied == '1') ? true : false,
					product_sku: this.productDetail.sku,
					// shipCharge: (this.productDetail.shipping_applied == '1') ? true : false,
					showTill: showTillDate,
					shipAmount: (this.productDetail.shipping_charge != '0.00') ? this.productDetail.shipping_charge : '',
					brand: this.productDetail.brand_id,
					condition: this.productDetail.condition_id,
					warranty: (this.productDetail.warranty_duration == '0') ? "" : this.productDetail.warranty_duration,
					deliveryIn: (this.productDetail.delivery_in == '0') ? "" : this.productDetail.delivery_in,
					shipCharge: this.productDetail.shipping_applied,
					// days_of_return: this.productDetail.days_of_return,
					days_of_return: (this.productDetail.is_return == '0') ? "" : this.productDetail.days_of_return,

					isVariant: '1',
					isVariantAdded: '1',
					regularPrice: this.productDetail.regular_price,
					offer_price: this.productDetail.offer_price,
					startDAte: startDAte1,
					endDAte: endDAte1,

					featuredImageFlag: "1",
					product_quantity: this.noVariantQuantity,
					product_weight: this.noVariantWeight,




				});
			} else {
				this.productDetail = {};
			}

			this.commonService.hideLoader();

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}
	// End

	

	// For select category
	selectSubCategorys(id, level) {
		this.getBrandList(id);
	}
	// End

	// remove key feature
	removeKey1(index) {
		if (index > -1) {
			this.addPakageList.splice(index, 1);
		}
	}
	// add key feature

	addPakage(value) {
		if (value.trim() != "") {
			if (this.addPakageList.length >= 20) {
				Swal.fire(this.translate.instant('PROFILE.alert'), this.translate.instant('ADD_PRODUCT.max_text_pacakge'));
				return false;
			} else {
				this.addPakageList.push(value);

			}

		}
	}

	// Function for variants
	setVariantsFilled(variants) {

		variants.forEach((element, key) => {

			var offer_price = element.offer_price;
			var offer_price_with_admin_commission = element.offer_price_with_admin_commission;
			var offer_price_commission_percent = element.offer_price_commission_percent;

			if (this.productDetail.is_offer == '0') {

				offer_price = 0;
				offer_price_with_admin_commission = 0;
				offer_price_commission_percent = 0;

			} else if (this.productDetail.is_offer != '0' && this.productDetail.is_no_variants == 1) {
				offer_price = this.productDetail.offer_price;
				offer_price_with_admin_commission = this.productDetail.variant_list[0].offer_price_with_admin_commission;
				offer_price_commission_percent = this.productDetail.variant_list[0].offer_price_commission_percent;
			}

			if (element.product_weight > 0) {

				element.product_weight
			} else {
				element.product_weight = ""
			}
			let variantData = {
				discountPercent: Number(element.discount_percent),
				discountAmount: Number(element.discount_amount),
				amount: element.price,
				sku: element.sku,
				quantity: element.quantity,
				product_weight: element.product_weight,
				showlist: element.show_in_list,
				offer_price: offer_price,
				offer_price_with_admin_commission: offer_price_with_admin_commission,
				offer_price_commission_percent: offer_price_commission_percent,
				selling_price: element.selling_price,
				selling_variant_commission: element.selling_variant_commission,
				variant_image: element.variant_image,
				vatiantsData: [],
			}

			element.variant_id.forEach((element2, key2) => {
				const variant = this.productDetail.variants_options.find((item) => item.variantID === element2);

				const variantValue = variant.variant_value.find((item) => item.variantValueID === element.variant_value[key2]);

				let variantArray = {
					"name": variant.variant_name,
					"variantID": variant.variantID,
					"variant_value": variantValue.variant_value,
					"variantValueID": variantValue.variantValueID
				}
				variantData.vatiantsData.push(variantArray);
			});

			this.finialVariantList.push(variantData);
		});
	}
	// End

	// For check form validation 
	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});
	}
	// End

	// For clear offer
	clearOffer(value = "", key = '', ids = "") {

		if (key != "" && ids != '2') {
			value = "";
			this.offerVariantPrice = 0;
			this.offerVariantPriceCommission = 0;
		}
		if (key != "" && ids == '2') {

			value = "";
			this.editVariantModel.offer_price_with_admin_commission = 0;
			this.editVariantModel.offer_price_commision_percent = 0;
			this.offerVariantPrice = 0;
			this.offerVariantPriceCommission = 0;
		}
	}
	// End

	singleValidationFields(formGroup: FormGroup, fields: Array<any>) {
		fields.forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}


	onNext(step) {

		console.log('this.variantsCheck', this.variantsCheck);

		console.log('this.product_image ', this.product_image);
		
		
		this.commonService.scrollToTop();

		// remove gallery image data 
		if (this.variantsCheck == 1 && step == 3) {
			this.product_image.map((val) => {
				this.removeImageArray.push(Number(val.imageId));

			})
			this.product_image = [];
			this.editProductForm.get('featuredImage').setValue('');

            if(this.editProductForm.get('featuredImage').invalid && this.product_image.length == 0){
                
                this.singleValidationFields(this.editProductForm, ['featuredImage']);
            }

		}

		console.log('this.product_image',this.product_image);
		console.log('this.product_image',this.removeImageArray);



		let checkValidData: any = [];
		if (step == 2) { // for step 1 all validation check
			checkValidData = ['category', 'isVariant', 'brand'];

			if (this.subCategoryLevel == 1) {
				checkValidData.push('subcategory1');

				if (this.editProductForm.get('category').invalid || this.editProductForm.get('isVariant').invalid || this.editProductForm.get('brand').invalid || this.editProductForm.get('subcategory1').invalid) {
					this.singleValidationFields(this.editProductForm, checkValidData);
				} else {
					this.value = step;
				}

			} else if (this.subCategoryLevel == 2) {

				checkValidData.push('subcategory1', 'subcategory2');

				if (this.editProductForm.get('category').invalid || this.editProductForm.get('isVariant').invalid || this.editProductForm.get('brand').invalid || this.editProductForm.get('subcategory1').invalid || this.editProductForm.get('subcategory2').invalid) {
					this.singleValidationFields(this.editProductForm, checkValidData);
				} else {
					this.value = step;
				}

				// this.singleValidationFields(this.addProductForm,checkValidData);
			} else if (this.subCategoryLevel == 3) {
				checkValidData.push('subcategory1', 'subcategory2', 'subcategory3');

				if (this.editProductForm.get('category').invalid || this.editProductForm.get('isVariant').invalid || this.editProductForm.get('brand').invalid || this.editProductForm.get('subcategory1').invalid || this.editProductForm.get('subcategory2').invalid || this.editProductForm.get('subcategory3').invalid) {
					this.singleValidationFields(this.editProductForm, checkValidData);
				} else {
					this.value = step;
				}

				// this.singleValidationFields(this.addProductForm,checkValidData);
			} else if (this.subCategoryLevel == 4) {
				checkValidData.push('subcategory1', 'subcategory2', 'subcategory3', 'subcategory4');

				if (this.editProductForm.get('category').invalid || this.editProductForm.get('isVariant').invalid || this.editProductForm.get('brand').invalid || this.editProductForm.get('subcategory1').invalid || this.editProductForm.get('subcategory2').invalid || this.editProductForm.get('subcategory3').invalid || this.editProductForm.get('subcategory4').invalid) {
					this.singleValidationFields(this.editProductForm, checkValidData);
				} else {
					this.value = step;
				}

			} else {
				this.singleValidationFields(this.editProductForm, checkValidData)
			}
			if (this.selectedVariantList[0].is_no_varinat == 1) {
				this.is_no_variants = 1;

				// this.removeImageArray.push(Number());
			} else {
				this.is_no_variants = 0;
			}

		} else if (step == 3) {

			console.log('this.municipalitiesAddDataList',this.municipalitiesAddDataList);
			
			

			if (this.sellingAmount == 0) {
				Swal.fire({
					text: this.translate.instant('ADD_PRODUCT.warring_text'),
					icon: 'warning',
					showCancelButton: true,
					cancelButtonText: this.translate.instant('COMMON.cancel'),
					confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
					reverseButtons: true
				}).then((result) => {
					if (result.isConfirmed) {
						var url = '/seller/seller-account/commission';
						window.open(url, "_blank");


						// this.router.navigate(['/seller/seller-account/commission']);

					} else {
						return false;
					}
				})
			}

			// code for offer commision check 

			else if (this.offerListcomission == 0 && this.limitedOffer != false) {
				Swal.fire({
					text: this.translate.instant('ADD_PRODUCT.warring_text_for_offer'),
					icon: 'warning',
					showCancelButton: true,
					cancelButtonText: this.translate.instant('COMMON.cancel'),
					confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
					reverseButtons: true
				}).then((result) => {
					console.log('result', result);
					if (result.isConfirmed) {
						var url = '/seller/seller-account/commission';

						window.open(url, "_blank");

					}

					if (result.isDismissed) {

						this.value = '2';


					}
				})
			}

			if (this.sellingAmount != 0) {


				var ship = $("#clickShipID").val();
				this.getShippingList();
				this.editProductForm.get('shipCharge').setValue(ship);

				var asd = this.editProductForm.get('startDAte').value;
				var end_date = this.editProductForm.get('endDAte').value;
				if (this.editProductForm.get('product_sku').invalid || this.editProductForm.get('name').invalid || this.editProductForm.get('condition').invalid || this.editProductForm.get('deliveryIn').invalid || this.editProductForm.get('warranty').invalid || this.editProductForm.get('regularPrice').invalid || this.editProductForm.get('province').invalid || this.editProductForm.get('municipalities').invalid || this.editProductForm.get('shipAmount').invalid || this.editProductForm.get('offer_price').invalid || this.editProductForm.get('days_of_return').invalid) {

					

					this.singleValidationFields(this.editProductForm, ['product_sku', 'name', 'condition', 'deliveryIn', 'warranty', 'regularPrice', 'shipAmount', 'province','municipalities', 'offer_price', 'days_of_return']);
				} else {

					console.log('this.limitedOffe', this.limitedOffer);
					console.log('regular price', this.editProductForm.get('regularPrice').value);
					console.log('Offer price', this.editProductForm.get('offer_price').value);

					if (this.limitedOffer == true) {
						if (Number(this.editProductForm.get('regularPrice').value) < Number(this.editProductForm.get('offer_price').value)) {
							this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.price_validation')); return false;

						}
					}

					let startDAte1 = (this.editProductForm.get('startDAte').value != "") ? this.commonService.dateFormatConverter(this.editProductForm.get('startDAte').value) : "";
					let endDAte1 = (this.editProductForm.get('endDAte').value != "") ? this.commonService.dateFormatConverter(this.editProductForm.get('endDAte').value) : "";
					var asd = this.editProductForm.get('startDAte').value;

					var price = this.editProductForm.get('regularPrice').value;
					var price1 = this.editProductForm.get('offer_price').value;


					// if(this.limitedOffer == true && price < price1){
					// 	  this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.price_validation')); return false;

					// }

					if ((this.limitedOffer == true && startDAte1 == "") || (this.limitedOffer == true && startDAte1 == "0-0-0") || (this.limitedOffer == true && end_date == "") || (this.limitedOffer == true && endDAte1 == "0-0-0")) {
						this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.offer_date_is_required'));
						return false;


					} else {
						if (new Date(startDAte1) > new Date(endDAte1)) {
							this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.offer_end_date_should_be_grater'));
							return false;

						} else {
							this.value = step;

						}
					}
					if (this.is_no_variants == 1) {
						this.value = '4';

					} else {
						this.value = step;

					}
					// this.value = step;

				}
			}


		} else if (step == 4) {


			if (this.is_no_variants == 0) {
				if (this.editProductForm.get('isVariantAdded').invalid || this.finialVariantList.length == 0) {
					this.singleValidationFields(this.editProductForm, ['isVariantAdded']);
				} else {
					this.value = step;
				}
			}

		} else if (step == 5) {

			if (this.is_no_variants == 1) {
				this.noVariantsQuantity()
			}

			// if (this.editProductForm.get('featuredImage').invalid) {
			// 	this.singleValidationFields(this.editProductForm, ['featuredImage']);
			// }
			if(this.editProductForm.get('featuredImage').invalid && this.product_image.length == 0){
				
				this.singleValidationFields(this.editProductForm, ['featuredImage']);
			}
			
			else if (this.editProductForm.get('description').invalid) {
			
			// if (this.editProductForm.get('description').invalid) {
				this.singleValidationFields(this.editProductForm, ['description']);
			}
			else if (typeof $('#KeyFeat').val() == 'undefined') {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.minimum_one_key_featured_is_required'));
				return false;
			} else {
				this.value = step;
			}


		}

	}

	// For add product step form prev button
	onPrev(step) {

		if (this.is_no_variants == 1 && step == '3') {
			this.value = '2';
		} else {
			this.value = step;
		}
	}

	trimValue(formControl) {
		((typeof formControl.value) && formControl.value.trim() == "") ?
			formControl.setValue("") : formControl.setValue(formControl.value);
	}

	clearVariant(value, key) {
		if (key != "") {
			value = "";
			this.sellingAmount = 0;
			this.sellingAmountPercentage = 0;
		}
	}


	calcPercent(num, percentage) {
		return num * (percentage / 100);
	}

	// For offer commision
	offercommisoion($value = "", key, ids) {
		if ($value != "") {
			var getAmount = this.comissionData.filter((val) => Number($value) >= Number(val.minimum) && Number($value) <= Number(val.maximum));

			if (getAmount.length != 0) {
				var asss = getAmount[0].commission_rate
				this.offerVariantPrice = getAmount;


				var Amt = getAmount.filter((val) => val.commission_type == 1)

				if (Amt != 0) {
					asss = Amt[0].commission_rate;

				}

				const percentVal = this.calcPercent($value, asss);
				var numberValue1 = Number(percentVal);
				var numberValue2 = Number($value);
				var offerVariantPrice = numberValue2 + numberValue1;
				this.offerVariantPrice = offerVariantPrice.toFixed(2);
				this.offerVariantPriceCommission = asss;


				if (ids == 2) {
					this.editVariantModel.offer_price_with_admin_commission = this.offerVariantPrice;
					this.editVariantModel.offer_price_commision_percent = this.offerVariantPriceCommission;

				}
			}
			else {

				if (key == '0' && ids != '2') {


					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text_for_offer'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true
					}).then((result) => {
						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) { // if swal is cancel then it's not redirect to any where 

							return false;

						}
					})

					// $('#rangeCommisionModal').modal('show');

					// this.offerVariantPrice = this.variantModel.offer_variant_price;

					// this.offerVariantPrice = this.variantModel.offer_variant_price;
				}


				// else {
				// 	this.offerVariantPrice = this.editVariantModel.offer_variant_price;

				// }


				if (key == '0' && ids == '2') {

					this.editVariantModel.offer_price_with_admin_commission = this.offerVariantPrice;
					this.editVariantModel.offer_price_commision_percent = this.offerVariantPriceCommission;
				} else {


					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text_for_offer'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true,

					}).then((result) => {
						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) {

							return false;

						}
					})



					// this.editVariantModel.offer_price_with_admin_commission = this.editVariantModel.offer_variant_price;
					// this.editVariantModel.offer_price_commision_percent = 0;

				}

				this.offerVariantPriceCommission = 0;


			}
		}


	}
	// End

	commisoionCheckForVarinat($value = "", key, ids) {
		if ($value != "") {
			var getAmount1 = this.comissionData.filter((val) => Number($value) >= Number(val.minimum) && Number($value) <= Number(val.maximum));

			if (getAmount1.length != 0) {
				var asss = getAmount1[0].commission_rate
				this.selling_price1 = getAmount1;

				var Amt = getAmount1.filter((val) => val.commission_type == 1)

				if (Amt != 0) {
					asss = Amt[0].commission_rate;

				}

				const percentVal = this.calcPercent($value, asss);
				var numberValue1 = Number(percentVal);
				var numberValue2 = Number($value);
				var sellingAmount = numberValue2 + numberValue1;
				this.sellingAmount1 = sellingAmount.toFixed(2);
				this.sellingAmountPercentagev = asss;

				if (ids == 2) {
					this.editVariantModel.selling_price = this.sellingAmount1;
					this.editVariantModel.selling_variant_commission = this.sellingAmountPercentagev;
				}

			}
			else {

				if (key == '0' && ids != '2') {

					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true,

					}).then((result) => {
						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) {

							return false;

						}
					})
					// this.sellingAmount1 = this.variantModel.amount;
					// $('#rangeCommisionModal').modal('show');
				} else {

					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true,

					}).then((result) => {
						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) {

							return false;

						}
					})
					// $('#rangeCommisionModal').modal('show');

					// this.sellingAmount1 = this.editVariantModel.amount;
				}

				if (key == '0' && ids == '2') {
					this.editVariantModel.selling_price = this.sellingAmount1;
					this.editVariantModel.selling_variant_commission = this.sellingAmountPercentagev;
				} else {

					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true,

					}).then((result) => {
						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) {

							return false;

						}
					})
					// $('#rangeCommisionModal').modal('show');

					// this.editVariantModel.selling_price = this.editVariantModel.amount;
					// this.editVariantModel.selling_variant_commission = 0;
				}
				this.sellingAmountPercentagev = 0;
			}
		}
	}

	commisoion($value = "") {
		if (this.editProductForm.get('regularPrice').value <= 0) {
			this.editProductForm.get('regularPrice').setValue('');
		}


		// alert('hello');
		if ($value != "") {
			var getAmount = this.comissionData.filter((val) => Number($value) >= Number(val.minimum) && Number($value) <= Number(val.maximum));

			if (getAmount.length != 0) {
				var asss = getAmount[0].commission_rate
				this.selling_price = getAmount;
				var Amt = getAmount.filter((val) => val.commission_type == 1)

				if (Amt != 0) {
					asss = Amt[0].commission_rate;
				}

				const percentVal = this.calcPercent($value, asss);
				var numberValue1 = Number(percentVal);
				var numberValue2 = Number($value);
				var sellingAmount = numberValue2 + numberValue1;
				this.sellingAmount = sellingAmount.toFixed(2);
				this.sellingAmountPercentage = asss;
			}
			else {


				this.swalShow == 1
				Swal.fire({
					text: this.translate.instant('ADD_PRODUCT.warring_text'),
					icon: 'warning',
					showCancelButton: true,
					cancelButtonText: this.translate.instant('COMMON.cancel'),
					confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
					reverseButtons: true
				}).then((result) => {
					if (result.isConfirmed) {
						var url = '/seller/seller-account/commission';
						window.open(url, "_blank");


						// this.router.navigate(['/seller/seller-account/commission']);

					}
				})
				// this.sellingAmount = this.editProductForm.get('regularPrice').value;
				// this.sellingAmountPercentage = 0;
			}
		}
	}

	// For category list
	getCategoryList() {
		this.commonService.showLoader();
		this.sellerService.getCategoryList(1).subscribe(catData => {

			if (catData.status == 'success') {
				this.categoryList = catData.data.category;
				this.getProductDetail(this.productId, 0);
			}
		}, err => {
			this.commonService.errorhandler(err);
		});
	}
	// End

	// For warranty list
	getWarrantyList() {
		this.sellerService.getWarrantyList().subscribe(data => {
			this.commonService.hideLoader();

			if (data.status == 'success') {
				this.warrantyList = data.data.warranty;
			}
		}, err => {
			this.commonService.errorhandler(err);
		});
	}
	// End

	checkWarranty($event) {
		const warrantyCtrl = this.editProductForm.get('warranty');
		if ($event.target.checked) {
			warrantyCtrl.setValidators(Validators.required);
			this.isWarrantyApply = true;
		} else {
			warrantyCtrl.clearValidators();
			this.isWarrantyApply = false;
		}
		warrantyCtrl.updateValueAndValidity();
	}

	shipAmountValidationerror() {
		if (this.editProductForm.get('shipAmount').value <= 0) {
			this.editProductForm.get('shipAmount').setValue('');
		}
	}

	getShippingList() {
		this.shippingIds = [];
		// this.commonService.showLoader();
		var shipping_id = '';
		var offset = '';

		this.sellerService.shippingChargesget(shipping_id, offset).subscribe(
			(data) => {
				this.commonService.hideLoader();
				if (data.status == 'success') {
					this.shiipingChargesList = data.data


					if (this.shiipingChargesList.length == 0) {
						this.shippingListCount = 0;
					} else {
						this.shippingListCount = 1;
					}
				} else {
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}

	changeShip($event, e) {
		this.provinceIdss = [];
		this.getShippingList(); //For shipping price list

		if (this.ProvinceFieldValidation.length == 0) {
			this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_select_province_first'));
			$("#clickShipID").val(this.lastSel);
			e.preventDefault();
			return false;
		}

		if (this.provinceGetListForShipping.length == 0) {
			this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_select_municipalities_first'));
			$("#clickShipID").val(this.lastSel);
			e.preventDefault();
			return false;
		}


		
		this.provinceGetListForShipping.map(object => {
			this.provinceIdss.push(object.id);
		});

		console.log('this.provinceIdss',this.provinceIdss);
		
		var checkValue = this.shiipingChargesList.filter((val) => this.provinceIdss.includes(val.municipalityId));
		this.getShippingList(); //For shipping price list
		checkValue.length
		console.log('checkValue.length',checkValue.length);

		if (checkValue.length != this.provinceIdss.length && $event == 2) {
			Swal.fire({
				text: this.translate.instant('EDIT_PRODUCT.please_add_kg_shipping_price_for_all_province'),
				icon: 'warning',
				showCancelButton: true,
				cancelButtonText: this.translate.instant('COMMON.cancel'),
				confirmButtonText: this.translate.instant('ADD_PRODUCT.add_price'),
				reverseButtons: true
			}).then((result) => {
				if (result.isConfirmed) {
					var url = '/seller/seller-account/shipping';
					window.open(url, "_blank");

					// this.router.navigate(['/seller/seller-account/commission']);

				}
			})
			// this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_add_kg_shipping_price_for_all_province'));
			this.getShippingList();
			$("#clickShipID").val(this.lastSel);
			e.preventDefault();
			return false;
			return false;
		}

		const shipAmountCtrl = this.editProductForm.get('shipAmount');
		if ($event == 1) {
			shipAmountCtrl.setValidators([Validators.required]);
			this.shipChargeFlag = true;
		} else {
			shipAmountCtrl.clearValidators();
			this.shipChargeFlag = false;
			this.editProductForm.get('shipAmount').setValue('');
		}
		shipAmountCtrl.updateValueAndValidity();
	}

	changeshowList($event) {
		if ($event.target.checked) {
			this.variantModel.showlist = true;
		} else {
			this.variantModel.showlist = false;
		}
	}

	editChangeshowList($event) {
		if ($event.target.checked) {
			this.editVariantModel.showlist = true;
		} else {
			this.editVariantModel.showlist = false;
		}
	}

	showSelectedCategory() {
		let selectedCat = this.productDetail.category_id.split(',');


		this.getVariantList(selectedCat[0], true); // get variantList
		// this.getBrandList(selectedCat[0]); // get brandlist

		this.categoryList.forEach(element => { // for category loop

			this.editProductForm.get('category').setValue(selectedCat[0]);

			if (element.categoryID == selectedCat[0]) {
				this.subCategoryList1 = element.sub_category;

				if (element.sub_category.length > 0) {
					this.getBrandList(selectedCat[1].trim());
					this.editProductForm.get('subcategory1').setValue(selectedCat[1].trim());
					element.sub_category.forEach(element2 => { // for subcategory1 loop

						if (element2.categoryID == selectedCat[1].trim()) {
							this.subCategoryList2 = element2.sub_category;

							console.log('element2.sub_category.length ', element2.sub_category)

							if (element2.sub_category.length > 0) {

								if (selectedCat[2] != undefined) {

									console.log('selectedCat', selectedCat[2])

									this.getBrandList(selectedCat[2].trim());
									this.editProductForm.get('subcategory2').setValue(selectedCat[2].trim());

									element2.sub_category.forEach(element3 => { // for subcategory2 loop

										if (element3.categoryID == selectedCat[2].trim()) {
											this.subCategoryList3 = element3.sub_category;

											if (element3.sub_category.length > 0 && selectedCat[3] != undefined) {
												this.getBrandList(selectedCat[3].trim());

												this.editProductForm.get('subcategory3').setValue(selectedCat[3].trim());

												element3.sub_category.forEach(element4 => { // for subcategory3 loop

													if (element4.categoryID == selectedCat[3].trim()) {
														this.subCategoryList4 = element4.sub_category;

														if (element4.sub_category.length > 0 && selectedCat[4] != undefined) {
															this.getBrandList(selectedCat[4].trim());
															this.editProductForm.get('subcategory4').setValue(selectedCat[4].trim());
														}
													}

												});
											}
										}
									});
								}
							}
						}
					});
				}
			}
		});
	}

	// use for variants
	getVariantList(categoryId, isCategory) {
		this.variantsList = [];

		this.sellerService.getVariantList(categoryId).subscribe(data => {
			this.commonService.hideLoader();

			if (data.status == 'success') {
				data.data.variants_List.forEach(element => {
					element.select = false;

					if (isCategory) {
						this.productDetail.variants_options.forEach(element2 => {
							if (element2.variantID == element.variantID) {
								element.select = true;
								this.selectedVariantList.push(element);
							}
						});
					}

					this.variantsList.push(element);
				});
			}
		}, err => {
			this.commonService.errorhandler(err);
		});
	}

	// getBrandList(categoryId) {
	// 	this.sellerService.getBrandList(categoryId).subscribe(data => {
	// 		this.commonService.hideLoader();

	// 		if (data.status == 'success') {
	// 			// this.brandList = data.data.brand_List;
	// 		} else {
	// 			this.brandList = [];
	// 		}

	// 	}, err => {
	// 		this.commonService.errorhandler(err);
	// 	});
	// }



	getBrandList(categoryId) {
		this.sellerService.getBrandList(categoryId).subscribe(data => {

			this.commonService.hideLoader();

			if (data.status == 'success') {
				if (data.data.brand_List == undefined) {
					this.brandList = [];
					// this.brandList = [
					// 	{
					// 		brandID: this.productDetail.brand_id,
					// 		name: this.productDetail.brand_name,
					// 		status: 1
					// 	},

					// ];


				} else {
					this.brandList = data.data.brand_List;

				}

			} else {
				this.brandList = [];
			}

		}, err => {
			this.commonService.errorhandler(err);
		});
	}


	// For category
	selectCategory(id) {


		// let res = this.categoryList.filter(item => item.categoryID === id);	
		// this.categoryType = res[0].category_type;
		// Filter categoryList to find item with matching categoryID

		let foundItem = this.categoryList.find(item => item.categoryID === id);
		this.categoryType = foundItem.category_type;

		if (this.categoryType == 'food') {
			this.editProductForm.get('condition').setValue(1);

		} else {
			this.editProductForm.get('condition').setValue('');

		}

		let subCategoryList1Object: any = {};

		if (id) {
			subCategoryList1Object = this.categoryList.find(function (entry) { return entry.categoryID === id; });
			this.subCategoryList1 = subCategoryList1Object.sub_category;

			this.subCategoryLevel = 1;
			this.getVariantList(id, false); // get variants list
			this.getBrandList(id); // get brand list
			this.resetCategory(1);
		} else {
			subCategoryList1Object = {};
			this.subCategoryList1 = [];
			this.subCategoryLevel = 0;
			this.variantsList = [];
			this.brandList = [];
		}

		this.finialVariantList = [];
		this.editProductForm.get('isVariantAdded').setValue('');
		this.editProductForm.get('brand').setValue('');

	}
	// End

	// For reset category
	resetCategory(level) {
		switch (level) {
			case 1:
				this.editProductForm.get('subcategory1').reset();
				this.editProductForm.get('subcategory2').reset();
				this.editProductForm.get('subcategory3').reset();
				this.editProductForm.get('subcategory4').reset();
				this.editProductForm.get('isVariant').reset();
				break;
			case 2:
				this.editProductForm.get('subcategory2').reset();
				this.editProductForm.get('subcategory3').reset();
				this.editProductForm.get('subcategory4').reset();
				break;
			case 3:
				this.editProductForm.get('subcategory3').reset();
				this.editProductForm.get('subcategory4').reset();
				break;
		}

	}
	// End

	selectSubCategory(id, level) {
		this.getBrandList(id);
		let subCategoryListObject: any = {};

		if (id) {

			switch (level) {
				case 2:
					subCategoryListObject = this.subCategoryList1.find(function (entry) { return entry.categoryID === id; });
					this.subCategoryList2 = subCategoryListObject.sub_category;
					if (this.subCategoryList2.length) {
						this.subCategoryLevel = Number(level);
					} else {
						this.subCategoryLevel = Number(level) - Number(1);
					}
					break;
				case 3:
					subCategoryListObject = this.subCategoryList2.find(function (entry) { return entry.categoryID === id; });
					this.subCategoryList3 = subCategoryListObject.sub_category;
					if (this.subCategoryList3.length) {
						this.subCategoryLevel = Number(level);
					} else {
						this.subCategoryLevel = Number(level) - Number(1);
					}
					break;
				case 4:
					subCategoryListObject = this.subCategoryList3.find(function (entry) { return entry.categoryID === id; });
					this.subCategoryList4 = subCategoryListObject.sub_category;
					if (this.subCategoryList4.length) {
						this.subCategoryLevel = Number(level);
					} else {
						this.subCategoryLevel = Number(level) - Number(1);
					}
					break;
			}

		} else {

			switch (level) {
				case 2:
					this.subCategoryList2 = [];
					this.subCategoryList3 = [];
					this.subCategoryList4 = [];
					this.subCategoryLevel = Number(level) - Number(1);
					break;
				case 3:
					this.subCategoryList3 = [];
					this.subCategoryList4 = [];
					this.subCategoryLevel = Number(level) - Number(1);
					break;
				case 4:
					this.subCategoryList4 = [];
					this.subCategoryLevel = Number(level) - Number(1);
					break;
			}
		}

		this.resetCategory(level);
	}

	// variantAddRemove(index, $event) {
	// 	this.selectedVariantList = [];
	// 	this.finialVariantList = [];

	// 	this.variantsList[index].select = ($event.target.checked) ? true : false;

	// 	this.variantsList.forEach(element => {
	// 		if (element.select) {
	// 			this.selectedVariantList.push(element);
	// 		}
	// 	});

	// 	if (this.selectedVariantList.length > 0) {
	// 		this.editProductForm.get('isVariant').setValue('1');
	// 	} else {
	// 		this.editProductForm.get('isVariant').setValue('');
	// 	}
	// }





	// For variants add & remove function
	variantAddRemove(index, $event) {

		this.selectedVariantList = [];
		this.finialVariantList = [];


		// check old and new varinats are same or not 

		if (this.productDetail.is_no_variants == 1 && this.variantsList[index].is_no_varinat == 0) {
			this.variantsCheck = 1;
		}


		if (this.productDetail.is_no_variants == 0 && this.variantsList[index].is_no_varinat == 1) {
			this.variantsCheck = 1;
		}



		// check condition for no variant
		if (this.variantsList[index].is_no_varinat == 1) {
			$('.removeVariant').removeClass('varinat');
			$(`.newVariants${index}`).addClass('varinat');
			this.variantsList.forEach(element => {

				if (element.is_no_varinat == 1 && element.select == false) {
					element.select = true;
				} else {
					element.select = false;

				}
			});
		} else {
			this.variantsList[index].select = ($event.target.checked) ? true : false;
			this.variantsList.forEach(function callback(element, indexs) {

				if (element.is_no_varinat == 1) {
					element.select = false;
					$(`.newVariants${indexs}`).removeClass('varinat');


				} else {
					$(`.newVariants${index}`).addClass('varinat');

				}
			});
		}

		this.variantsList.forEach(element => {
			if (element.select) {
				this.selectedVariantList.push(element);
			}
		});

		if (this.finialVariantList.length == 0) {
			this.editProductForm.get('isVariantAdded').setValue('');
			this.editProductForm.get('isVariant').setValue('');

		} else {
			this.editProductForm.get('isVariantAdded').setValue('1');
			this.editProductForm.get('isVariant').setValue('1');


		}


		if (this.selectedVariantList.length > 0) {
			this.editProductForm.get('isVariant').setValue('1');
		} else {
			this.editProductForm.get('isVariant').setValue('');
		}
	}
	// End

	// add key feature
	addKeyFeature(value) {
		if (value.trim() != "") {

			if (this.keyFeatureList.length >= 5) {
				Swal.fire(this.translate.instant('PROFILE.alert'), this.translate.instant('ADD_PRODUCT.max_text_key'));
				return false;
			} else {
				this.keyFeatureList.push(value);

			}
		}
	}

	// remove key feature
	removeKey(index) {
		if (index > -1) {
			this.keyFeatureList.splice(index, 1);
		}
	}

	addVariantsModalOpen() {


		var totalLength = this.finialVariantList.length
		if (totalLength >= 10) {
			Swal.fire(this.translate.instant('PROFILE.alert'), this.translate.instant('ADD_PRODUCT.max_text'));
			return false;
		}

		this.form.reset();
		this.variantsValuesList = [];
		this.galleryList = [];
		this.gallery_image = [];
		this.is_add = 1;
		this.offerVariantPrice = 0;
		this.offerVariantPriceCommission = 0;
		this.sellingAmount1 = 0;
		this.sellingAmountPercentagev = 0;



		// $('#addVarNew').modal('show');
		$('#addVarNew').modal({
			backdrop: 'static',
			keyboard: false
		})
	}

	selectVariantsValue($event) {
		let variantSelArray = $event.target.value.split('-');
		if (variantSelArray[1] != '0') {
			let check = this.variantsValuesList.find((selectVal) => { return selectVal.variantID == variantSelArray[0] });
			let key = this.selectedVariantList.find((keyVal) => { return keyVal.variantID == variantSelArray[0] });
			let value = key.variant_value.find((valueVal) => { return valueVal.variantValueID == variantSelArray[1] });

			if (typeof check == "undefined") {
				let pushObject = {
					'name': key.name,
					'variantID': key.variantID,
					'variant_value': value.variant_value,
					'variantValueID': value.variantValueID,
				}

				this.variantsValuesList.push(pushObject);
			} else {
				var stIndex = this.variantsValuesList.indexOf(check);
				if (stIndex > -1) {
					this.variantsValuesList.splice(stIndex, 1);
				}

				let pushObject = {
					'name': key.name,
					'variantID': key.variantID,
					'variant_value': value.variant_value,
					'variantValueID': value.variantValueID,
				}

				this.variantsValuesList.push(pushObject);
			}
		}
	}
	// submit varinats form  and check all validation 
	onSubmitVariant() {

		let addVariant: any = {};
		this.gallery_image1 = this.gallery_image;




		// // check form is valid or not and  check varinat lenghth and regular price and product variant price 
		// if (this.form.valid && this.variantsValuesList.length == this.selectedVariantList.length && Number(this.editProductForm.get('regularPrice').value) >= Number(this.form.value.amount)) {

		if (this.form.valid && this.variantsValuesList.length == this.selectedVariantList.length) {


			if (this.sellingAmount1 == 0) { // check commission 
				// $('#rangeCommisionModal').modal('show'); // show popup for erroe message 
				Swal.fire({
					text: this.translate.instant('ADD_PRODUCT.warring_text'),
					icon: 'warning',
					showCancelButton: true,
					cancelButtonText: this.translate.instant('COMMON.cancel'),
					confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
					reverseButtons: true,

				}).then((result) => {
					if (result.isConfirmed) {
						var url = '/seller/seller-account/commission';

						window.open(url, "_blank");

					}

					if (result.isDismissed) {

						return false;

					}
				})
			} else if (this.offerVariantPrice == 0 && this.limitedOffer != false) {
				// $('#rangeCommisionModal').modal('show');


				Swal.fire({
					text: this.translate.instant('ADD_PRODUCT.warring_text_for_offer'),
					icon: 'warning',
					showCancelButton: true,
					cancelButtonText: this.translate.instant('COMMON.cancel'),
					confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
					reverseButtons: true,

				}).then((result) => {
					if (result.isConfirmed) {
						var url = '/seller/seller-account/commission';

						window.open(url, "_blank");

					}

					if (result.isDismissed) {

						return false;


					}
				})
			} else if (this.gallery_image1.length == 0) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_add_variant_images')); return false;  // check  image 
			}
			
			else if (Number(this.form.value.amount) < Number(this.form.value.offer_variant_price)) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.amount_should_be_less_then_or_equal_to_offer_price')); return false;
			} 
			
			else {


				addVariant.discountPercent = 0;
				addVariant.discountAmount = 0;

				addVariant.vatiantsData = this.variantsValuesList;
				addVariant.variant_image = this.gallery_image1;
				addVariant.amount = parseFloat(this.variantModel.amount).toFixed(2);
				addVariant.selling_price = this.sellingAmount1;
				addVariant.selling_variant_commission = this.sellingAmountPercentagev;

				this.offerv = this.form.value.offer_variant_price;

				if (this.variantModel.offer_variant_price == undefined || this.variantModel.offer_variant_price == '0') {
					this.offerv = 0;
				}

				addVariant.sku = this.variantModel.sku;
				addVariant.quantity = this.variantModel.quantity;
				addVariant.product_weight = this.variantModel.product_weight;
				addVariant.offer_price_with_admin_commission = this.offerVariantPrice;
				addVariant.offer_price_commission_percent = this.offerVariantPriceCommission;
				addVariant.offer_price = this.offerv;

				var show_list = (typeof this.variantModel.showlist == 'undefined') ? false : this.variantModel.showlist;
				if (show_list == true) {
					addVariant.showlist = 1;
				} else {
					addVariant.showlist = 0;
				}

				// this.checkVariantExist()
				if (addVariant.discountPercent == '100') {
					this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.you_cant_add_product_with_discount'));
				} else if (!this.checkVariantExist()) {
					this.finialVariantList.push(addVariant);
					$('#addVarNew').modal('hide');
					this.editProductForm.get('isVariantAdded').setValue('1');
				} else {
					this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.already_added_this_type_of_variant_combination'));
				}

			}
		}

		// if all validation is passed then  preceed next step 

		else {

			// if form is not valid then check all validation 

			if (this.form.value.amount == "" || this.form.value.amount == null || this.form.value.amount == 0) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.amount_is_required'));
				return false; // check amount is available or not 
			}


			//check make an offer on and offer price is available or not 
			else if ((this.limitedOffer && this.form.value.offer_variant_price == "") || (this.limitedOffer && this.form.value.offer_variant_price == null)) {
				this.commonService.toastError(this.translate.instant('ADD_PRODUCT.offer_amount_required')); return false;
			}

			// check variant sku 
			else if (this.form.value.sku == null || this.form.value.sku.trim() == "") {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.sku_is_required'));
				return false;
			}

			// check quantity
			else if (this.form.value.quantity == "" || this.form.value.quantity == null || this.form.value.quantity == 0) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.quantity_is_required'));
				return false;
			}


			// else if (this.form.value.product_weight == "" || this.form.value.product_weight == null) {
			// 	this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.product_weight_is_required'));
			// }

			// // check product regular price and product varinat price 
			// else if (Number(this.editProductForm.get('regularPrice').value) < Number(this.form.value.amount)) {
			// 	this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.amount_should_be_less_then'));
			// }

			// check product offer price and varinat offer price 	
			else if (this.limitedOffer && Number(this.form.value.amount) < Number(this.form.value.offer_variant_price)) {
				this.commonService.toastError('EDIT_PRODUCT.amount_should_be_less_then_or_equal_to_offer_price'); return false;
			}
			// check varinats 
			else if (this.variantsValuesList.length != this.selectedVariantList.length) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_select_variant'));

			}
			// check gallery image 
			else if (this.gallery_image1.length == 0) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_add_variant_images')); return false;
			}

		}

	}

	variantClose() {
		this.sellingAmount1 = undefined;
		this.sellingAmountPercentagev = undefined;
		this.offerVariantPriceCommission = undefined;
		this.offerVariantPrice = undefined;
		$('#editvariants').modal('hide');
	}

	checkVariantExist() {
		let finalarray = [];
		const isFlag = this.finialVariantList.some(element1 => {
			element1.vatiantsData.forEach(element2 => {
				this.variantsValuesList.forEach(element3 => {
					if (element3.variantID == element2.variantID && element3.variantValueID == element2.variantValueID) {
						finalarray.push(element3);
					}
				});
			});

			if (finalarray.length == this.selectedVariantList.length) {
				return true;
			}

			finalarray = [];

		});

		return isFlag;
	}

	deleteAddedVariant(index) {
		if (index > -1) {
			this.finialVariantList.splice(index, 1);

			if (this.finialVariantList.length == 0) {
				this.editProductForm.get('isVariantAdded').setValue('');
			}
		}
	}


	editvariants(index) {
		this.is_add = 0;
		this.editVariantModel.variant_image = [];
		this.editVariantindex = index;
		this.selevtedInmg = [];
		this.editqtd = [];
		this.variantsEditValuesList = [];
		this.editVariantModel.amount = this.finialVariantList[index].amount;
		this.editVariantModel.selling_price = this.finialVariantList[index].selling_price;
		this.editVariantModel.selling_variant_commission = this.finialVariantList[index].selling_variant_commission;
		this.editVariantModel.showlist = this.finialVariantList[index].showlist;
		this.editVariantModel.sku = this.finialVariantList[index].sku;
		this.editVariantModel.quantity = this.finialVariantList[index].quantity;
		this.editVariantModel.product_weight = this.finialVariantList[index].product_weight;
		this.editVariantModel.variant_image = this.finialVariantList[index].variant_image;
		this.editVariantModel.offer_variant_price = this.finialVariantList[index].offer_price;
		this.editVariantModel.offer_price_commision_percent = this.finialVariantList[index].offer_price_commission_percent;
		this.editVariantModel.offer_price_with_admin_commission = this.finialVariantList[index].offer_price_with_admin_commission;
		this.selevtedInmg = this.finialVariantList[index].variant_image;


		this.selectedVariantList.forEach(element1 => {
			element1.variant_value.forEach(element2 => {
				element2.select = false;
				this.finialVariantList[index].vatiantsData.forEach(element3 => {
					if (element3.variantValueID == element2.variantValueID) {
						element2.select = true;
						this.variantsEditValuesList.push(element3);
					}
				});

			});
		});

		$('#editvariants').modal({
			backdrop: 'static',
			keyboard: false
		})

	}

	selectEditVariantsValue($event) {
		let variantEditSelArray = $event.target.value.split('-');

		if (variantEditSelArray[1] != '0') {

			let check = this.variantsEditValuesList.find((selectVal) => { return selectVal.variantID == variantEditSelArray[0] });

			let key = this.selectedVariantList.find((keyVal) => { return keyVal.variantID == variantEditSelArray[0] });

			let value = key.variant_value.find((valueVal) => { return valueVal.variantValueID == variantEditSelArray[1] });


			if (typeof check == "undefined") {

				let pushObject = {
					'name': key.name,
					'variantID': key.variantID,
					'variant_value': value.variant_value,
					'variantValueID': value.variantValueID,
				}

				this.variantsEditValuesList.push(pushObject);

			} else {

				var stIndex = this.variantsEditValuesList.indexOf(check);

				if (stIndex > -1) {
					this.variantsEditValuesList.splice(stIndex, 1);
				}

				let pushObject = {
					'name': key.name,
					'variantID': key.variantID,
					'variant_value': value.variant_value,
					'variantValueID': value.variantValueID,
				}

				this.variantsEditValuesList.push(pushObject);

			}

		}

	}

	rangeRediraction() {
		$('#rangeCommisionModal').modal('hide');
		var url = '/seller/seller-account/commission';

		setTimeout(() => {
			window.open(url, "_blank");

			// window.location.href = url;
		}, 100);

		// this.router.navigate(['seller/seller-account/commission']);
		// this.router.navigate(['seller/seller-account/commission']);

	}

	changeZoom(stepSize) {

		let newScale = this.scale + parseFloat(stepSize);
		if (newScale < 0) {
			newScale = 0;
		}
		this.scale = newScale;
		this.transform = {
			...this.transform,
			scale: this.scale,
		};
	}


	changeZoomGallery(stepSize) {

		let newScale = this.scale + parseFloat(stepSize);
		if (newScale < 0) {
			newScale = 0;
		}
		this.scale = newScale;
		this.transformgallery = {
			...this.transform,
			scale: this.scale,
		};
	}

	onSubmitEditVariant() {
		let editVariant: any = {};

		// if (this.editF.valid && this.variantsEditValuesList.length == this.selectedVariantList.length && Number(this.editProductForm.get('regularPrice').value) >= Number(this.editF.value.amount)) {
		if (this.editF.valid && this.variantsEditValuesList.length == this.selectedVariantList.length) {

			if (this.editVariantModel.variant_image.length == 0) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_add_variant_images')); return false;


			}

			if (this.limitedOffer) {
				if (this.editF.value.offer_variant_price == "" || this.editF.value.offer_variant_price == null) {
					this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.offer_amount_is_required')); return false;
				}


				if (Number(this.form.value.amount) < Number(this.editF.value.offer_variant_price)) {
					this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.amount_should_be_less_then_or_equal_to_offer_price')); return false;
				}
			}


			if (!this.checkEditVariantExist()) {



				if (this.editVariantModel.selling_price == 0) {
					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true,

					}).then((result) => {
						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) {

							return false;

						}
					})
				} else if (this.editVariantModel.offer_price_commision_percent == 0 && this.limitedOffer) {
					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text_for_offer'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true,

					}).then((result) => {
						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) {

							return false;

						}
					})

				}
				else {


					if (this.editVariantModel.showlist == true) {
						this.editVariantModel.showlist = 1;
					} else {
						this.editVariantModel.showlist = 0;
					}


					editVariant.discountPercent = 0;
					editVariant.discountAmount = 0;
					editVariant.vatiantsData = this.variantsEditValuesList;
					editVariant.amount = parseFloat(this.editVariantModel.amount).toFixed(2);
					editVariant.selling_price = this.editVariantModel.selling_price;
					editVariant.selling_variant_commission = this.editVariantModel.selling_variant_commission;
					editVariant.sku = this.editVariantModel.sku;
					editVariant.quantity = this.editVariantModel.quantity;
					editVariant.product_weight = this.editVariantModel.product_weight;
					editVariant.showlist = this.editVariantModel.showlist;
					editVariant.variant_image = this.editVariantModel.variant_image;
					editVariant.offer_price = this.editVariantModel.offer_variant_price;
					editVariant.offer_price_commission_percent = this.editVariantModel.offer_price_commision_percent;
					editVariant.offer_price_with_admin_commission = this.editVariantModel.offer_price_with_admin_commission;



					if (editVariant.discountPercent == '100') {
						this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.you_cant_add_product_with_discount'));
					} else {
						this.finialVariantList[this.editVariantindex] = editVariant;
						$('#editvariants').modal('hide');
					}
				}

			} else {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.already_added_this_type_of_variant_combination'));
			}

		} else {

			if (this.editF.value.amount == "" || this.editF.value.amount == null) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.amount_is_required'));
				return false;
			}

			// else if (Number(this.editProductForm.get('regularPrice').value) < Number(this.editF.value.amount)) {
			// 	this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.amount_should_be_less_then'));
			// 	return false;
			// }


			else if ((this.limitedOffer && this.form.value.offer_variant_price == "") || (this.limitedOffer && this.form.value.offer_variant_price == null)) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.offer_amount_is_required')); return false;

			}

			else if (this.limitedOffer && Number(this.form.value.amount) < Number(this.form.value.offer_variant_price)) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.amount_should_be_less_then_or_equal_to_offer_price')); return false;
			}

			else if (this.editF.value.sku.trim() == "" || this.editF.value.sku.trim() == null) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.sku_is_required'));
				return false;
			}
			else if (this.editF.value.quantity == "" || this.editF.value.quantity == null) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.quantity_is_required'));
				return false;
			}

			else if (this.variantsEditValuesList.length != this.selectedVariantList.length) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_select_variant'));

			}

			else if (this.gallery_image1.length == 0) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_add_variant_images')); return false;

			}
		}
	}


	checkNumberLimitQuantity(e) {
		if (e.keyCode == 46) {
			e.preventDefault();
			return false
		}

		if (e.target.value.length >= 5 || e.keyCode == 45) {
			e.preventDefault();
			return false;
		}

	}


	checkEditVariantExist() {
		let finalarray = [];
		const isFlag = this.finialVariantList.some((element1, key) => {
			element1.vatiantsData.forEach(element2 => {
				this.variantsEditValuesList.forEach(element3 => {
					if (element3.variantID == element2.variantID && element3.variantValueID == element2.variantValueID) {
						finalarray.push(element3);
					}
				});
			});

			if (finalarray.length == this.selectedVariantList.length && key != this.editVariantindex) {
				return true;
			}

			finalarray = [];

		});

		return isFlag;

	}

	featuredImgSelect() {
		setTimeout(() => {
			this.editProductForm.get('featuredImage').setValue('');
			this.croppedImage = "";
			this.cropImageShow = false;
		}, 1000);
	}

	// code for image cropper
	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}

	imageLoaded() {
		// show cropper
		checkImageSize(this.imageChangedEvent.target, (res) => {
			if (res) {
				this.scale = 1;
				this.transform = {};

				$('#imageCropper').modal('show');
			} else {
				this.editProductForm.get('featuredImageFlag').setValue('');
				this.croppedImage = "";
				this.cropImageShow = false;
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.file_size_should_be_at_least_dimensions'));
			}
		});
	}

	loadImageFailed() {
		// show message
	}

	cropImage() {
		$('#imageCropper').modal('hide');
		// this.editProductForm.get('featuredImageFlag').setValue('1');
		this.cropImageShow = true;
	}

	resetCropper() {

		if (this.product_image.length == 0) {
			this.editProductForm.get('featuredImage').setValue('');

		}
		this.cropImageShow = false;
	}

	// add product
	editProductModel() {


		// if (this.product_image.length == 0) {

		if(this.editProductForm.get('featuredImage').invalid && this.product_image.length == 0){

			this.editProductForm.get('featuredImage').setValue('');
			this.singleValidationFields(this.editProductForm, ['featuredImage']);
			return false;
		}


		if (this.is_no_variants == 1) {

			if (this.editProductForm.get('product_quantity').invalid) {

				this.singleValidationFields(this.editProductForm, ['product_quantity']);
				return false;
			}


		}
		if (this.editProductForm.get('description').invalid) {

			this.singleValidationFields(this.editProductForm, ['description']);
			return false;
		}



		if (this.editProductForm.get('isVariantAdded').valid && this.is_no_variants == 0) {

			if (typeof $('#KeyFeat').val() == 'undefined') {
				// return false;
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.minimum_one_key_featured_is_required'));
				return false;
			} else {

				// $('#addProdConfirm').modal('show');

				if(this.is_pickup_checked != '1' || this.is_pick_up_option_active != '1'){
					$('#addProdConfirm').modal('show');
				}
			}

		}

		else {
			// validate all form fields
			this.validateAllFormFields(this.editProductForm); //{7}
		}


		// no varinats case 
		if (this.is_no_variants == 1) {

			if (typeof $('#KeyFeat').val() == 'undefined') {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.minimum_one_key_featured_is_required'));
				return false;
			} else {
				$('#addProdConfirm').modal('show');

			}

		}

		else {
			// validate all form fields
			this.validateAllFormFields(this.editProductForm); //{7}
		}

	}

	cancelEdit() {
		// alert('hello');
		// this.editProductForm.get('featuredImageFlag').setValue('1');
		$('#addProdConfirm').modal('hide');

	}


	// edit product
	editProduct() {
		if (this.is_no_variants == 1) {
			this.noVarinatsDefault();

		}

		if (this.editProductForm.get('description').valid) {
			// this.isUrl = this.croppedImage.includes("http");
			if (this.isUrl == 1) {
				var block = this.croppedImage.split(";");
				// Get the content type of the image
				var contentTypes = block[0].split(":")[1];// In this case "image/png"
				// get the real base64 content of the file
				var realData = block[1].split(",")[1];
				var blobImg = this.b64toBlob(realData, contentTypes, '');
			}

			let showTillDate = (this.editProductForm.get('showTill').value != "") ? this.commonService.dateFormatConverter(this.editProductForm.get('showTill').value) : "";
			let startDAte1 = (this.editProductForm.get('startDAte').value != "") ? this.commonService.dateFormatConverter(this.editProductForm.get('startDAte').value) : "";
			let endDAte1 = (this.editProductForm.get('endDAte').value != "") ? this.commonService.dateFormatConverter(this.editProductForm.get('endDAte').value) : "";
			let subCategory: any = [];
			for (let index = 1; index <= this.subCategoryLevel; index++) {
				subCategory.push(this.editProductForm.get('subcategory' + index).value);
			}

			this.commonService.showLoader();
			let provinceArray = this.commonService.ArrayFilter(this.editProductForm.get('province').value, 3);
			let municipalitiesArray = this.commonService.ArrayFilter(this.editProductForm.get('municipalities').value, 4);

			console.log('aaaaaaaa bbbbbb',municipalitiesArray);
			
			
			var provinces = provinceArray.join();
			var municipalities = municipalitiesArray.join();

			console.log('ccccc dddddd',municipalities);

			const formData = new FormData();
			formData.append('product_id', this.productId);
			formData.append('name', this.editProductForm.get('name').value);
			formData.append('province', provinces);
			formData.append('municipalities', municipalities);
			formData.append('description', this.editProductForm.get('description').value);
			formData.append('description_es', this.editProductForm.get('description_es').value);
			formData.append('category', this.editProductForm.get('category').value);
			formData.append('sub_category', subCategory.join());
			formData.append('shipping_applied', this.editProductForm.get('shipCharge').value);
			formData.append('days_of_return', this.editProductForm.get('days_of_return').value);

			if (this.editProductForm.get('shipCharge').value) {
				formData.append('shipping_charge', this.editProductForm.get('shipAmount').value);
			} else {
				formData.append('shipping_charge', '0.00');
			}

			formData.append('regular_price', this.editProductForm.get('regularPrice').value);
			formData.append('in_stock', (this.editProductForm.get('stocks').value) ? '1' : '0');
			formData.append('is_featured', (this.editProductForm.get('feature').value) ? '1' : '0');
			formData.append('brand_id', this.editProductForm.get('brand').value);
			formData.append('condition_id', this.editProductForm.get('condition').value);
			formData.append('warranty_applied', (this.editProductForm.get('warrantyApply').value) ? '1' : '0');
			formData.append('warranty_duration', this.editProductForm.get('warranty').value);
			formData.append('show_till', showTillDate);
			formData.append('delivery_in', this.editProductForm.get('deliveryIn').value);
			formData.append('key_features', JSON.stringify(this.keyFeatureList));
			formData.append('variants', JSON.stringify(this.variantFormat(this.finialVariantList)));
			formData.append('show_till', showTillDate);
			formData.append('offer_start', startDAte1);
			formData.append('offer_price', this.editProductForm.get('offer_price').value);
			formData.append('offer_end', endDAte1);
			formData.append('delivery_in', this.editProductForm.get('deliveryIn').value);
			formData.append('key_features', JSON.stringify(this.keyFeatureList));
			formData.append('package', JSON.stringify(this.addPakageList));
			formData.append('is_cod', (this.editProductForm.get('is_cod').value) ? '1' : '0');

			formData.append('is_pickup', (this.editProductForm.get('is_pickup').value) ? '1' : '0');
			formData.append('is_delivery', (this.editProductForm.get('is_delivery').value) ? '1' : '0');

			
			

			formData.append('seller_warehouse_id', (this.seller_warehouse_ids) ? this.seller_warehouse_ids : '');
			formData.append('pickup_address_json', (this.convertedObject) ? JSON.stringify(this.convertedObject) : '');

			formData.append('is_direct_pay', (this.editProductForm.get('is_direct_payment').value) ? '1' : '0');
			formData.append('is_make_and_offer', (this.editProductForm.get('make_and_offer_option').value) ? '1' : '0');
			formData.append('is_return', (this.editProductForm.get('return').value) ? '1' : '0');
			formData.append('sku', this.editProductForm.get('product_sku').value);
			formData.append('selling_price', this.sellingAmount);
			formData.append('selling_product_commission', this.sellingAmountPercentage);
			formData.append('is_no_variants', this.is_no_variants);
			formData.append('offer_selling_price', this.offerList);
			formData.append('offer_selling_price_commission_percent', this.offerListcomission);

			formData.append('product_image_json', JSON.stringify(this.imageId));
			formData.append('remove_image_josn', JSON.stringify(this.removeImageArray));


			formData.append('is_offer', (this.editProductForm.get('make_offer').value) ? '1' : '0');

			if (this.isUrl == 1) {
				formData.append('featured_image', this.FImage, 'featuredImage.png');
			}
			formData.forEach((value, key) => {
				console.log(key + " " + value)
			});

			// return false;
			this.sellerService.updateProduct(formData).subscribe(data => {
				this.commonService.hideLoader();

				if (data.status == 'success') {
					$('#addProdConfirm').modal('hide');

					this.commonService.toastSuccess(data.message);
					this.productId = data.data.product_detail.productID;
					// this.value = "5";

					let productDataStore = {
						'product_id': this.productId,
						'name': this.editProductForm.get('name').value,
						'regular_price': this.editProductForm.get('regularPrice').value,
						'sale_price': "100",
						'sale_discount': "15",
						'shipping_charge': this.editProductForm.get('shipAmount').value,
						'feature_image_url': data.data.product_detail.feature_image_url,
						'feature_image': data.data.product_detail.feature_image,
						'recently_deleted': 0,
					}

					// this.addOnFirebase(productDataStore); // add  product data on firebase 
					// this.router.navigate(['seller/my-products']);
					this.router.navigate(['seller/my-product-detail/' + this.productId]);
				} else {
					this.value = "4";

					this.commonService.toastError(data.message);
				}

			}, err => {
				this.value = "4";

				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});


		} else {
			// validate all form fields
			this.validateAllFormFields(this.editProductForm); //{7}
		}

	}

	clearVariant1(value = "", key = '', ids = "") {
		if (key != "" && ids != '2') {
			value = "";
			this.sellingAmount1 = 0;
			this.sellingAmountPercentagev = 0;
		}

		if (key != "" && ids == '2') {
			value = "";
			this.editVariantModel.selling_price = 0;
			this.editVariantModel.selling_variant_commission = 0;
		}
	}

	makeLimitedOffer($event) {
		const offerCtrl = this.editProductForm.get('offer_price');
		if ($event.target.checked) {
			offerCtrl.setValidators([Validators.required]);
			this.limitedOffer = true;
		} else {
			offerCtrl.clearValidators();
			this.limitedOffer = false;
			this.editProductForm.get('offer_price').setValue('');
			this.editProductForm.get('startDAte').setValue('');
			this.editProductForm.get('endDAte').setValue('');
			this.offerVariantPrice = '0';
			this.offerVariantPriceCommission = '0';
			this.editVariantModel.offer_variant_price = '0';
			this.variantModel.offer_variant_price = '0';
			this.editF.value.offer_variant_price = '0';
			this.offerListcomission = 0;
			this.offerList = 0;
		}
		offerCtrl.updateValueAndValidity();
	}


	checkNumberLimitWeight(e) {
		// if (e.target.value.length >= 4 || e.keyCode == 45) {
		// 	e.preventDefault();
		// 	return false;
		// }

		if (e.target.value.includes('.') && e.keyCode == 46) {

			e.preventDefault();
			return false
		}

		var valid = /^\d{0,4}(\.\d{0,2})?$/.test(e.target.value),
			val = e.target.value;

		if (!valid) {

			// return false;
			e.target.value = val.substring(0, val.length - 1);
		}

	}




	checkNumberLimit(e) {
		let val = e.target.value;
		
		// Get the key that is pressed
		let char = String.fromCharCode(e.which || e.keyCode);
	
		// Allow control keys like backspace, delete, arrows
		if (e.ctrlKey || e.metaKey || ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
			return;
		}
	
		// Prevent entering multiple decimal points
		if (e.key === '.' && val.includes('.')) {
			e.preventDefault();
			return false;
		}
	
		// Only allow numbers and a single decimal point
		if (!/^\d*\.?\d*$/.test(val + e.key)) {
			e.preventDefault();
			return false;
		}
	
		// Allow input but validate the value on the fly
		setTimeout(() => {
			// Limit to 5 digits before the decimal point and 2 after the decimal point
			let regex = /^\d{0,5}(\.\d{0,2})?$/;
	
			if (!regex.test(e.target.value)) {
				e.target.value = val; // Restore previous valid value if the current one is invalid
			}
		}, 0);
	}
	
	




	// For shipping charges limit set
	checkNumberLimitForShipping(e) {

		let val = e.target.value;
		
		// Get the key that is pressed
		let char = String.fromCharCode(e.which || e.keyCode);
	
		// Allow control keys like backspace, delete, arrows
		if (e.ctrlKey || e.metaKey || ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
			return;
		}
	
		// Prevent entering multiple decimal points
		if (e.key === '.' && val.includes('.')) {
			e.preventDefault();
			return false;
		}
	
		// Only allow numbers and a single decimal point
		if (!/^\d*\.?\d*$/.test(val + e.key)) {
			e.preventDefault();
			return false;
		}
	
		// Allow input but validate the value on the fly
		setTimeout(() => {
			// Limit to 5 digits before the decimal point and 2 after the decimal point
			let regex = /^\d{0,2}(\.\d{0,2})?$/;
	
			if (!regex.test(e.target.value)) {
				e.target.value = val; // Restore previous valid value if the current one is invalid
			}
		}, 0);
	}

	restrictValue() {
		var input = $('#someid');
		var num = parseFloat(input.val());
	
		// Restrict to values greater than 0 and up to 10
		if (num <= 0) {
			input.val(''); // Clear input if it is 0 or negative
		} else if (num > 10) {
			// return false;
			input.val(10); // Cap the value at 10
			this.editProductForm.get('shipAmount').setValue(10);

		}
	}

	variantFormat(variantList) {
		let array: any = [];
		variantList.forEach(element1 => {

			let variantIDArray: any = [];
			let variantValueIDArray: any = [];

			element1.vatiantsData.forEach(element2 => {
				variantIDArray.push(element2.variantID);
				variantValueIDArray.push(element2.variantValueID);
			});

			let object = {
				"price": element1.amount,
				"discount_percent": element1.discountPercent.toString(),
				"discount_amount": element1.discountAmount.toString(),
				"sku": element1.sku,
				"quantity": element1.quantity,
				"product_weight": element1.product_weight,
				"show_in_list": element1.showlist,
				"selling_price": element1.selling_price,
				"selling_variant_commission": element1.selling_variant_commission,
				"offer_price": element1.offer_price,
				"offer_price_commission_percent": element1.offer_price_commission_percent,
				"offer_price_with_admin_commission": element1.offer_price_with_admin_commission,
				"variant_id": variantIDArray,
				"variant_value": variantValueIDArray,
				"variant_image": element1.variant_image,

			}

			array.push(object);
		});

		return array;
	}

	// gallery image cropper
	fileChangeEventGallery(event: any): void {
		this.galleryImageChangedEvent = event;
	}

	GalleryImageCropped(event: ImageCroppedEvent) {
		this.croppedGalleryImage = event.base64;
	}

	GalleryImageLoaded() {
		// show cropper
		checkImageSize(this.galleryImageChangedEvent.target, (res) => {
			if (res) {

				$('#galleryImageCropper').modal('show');
			} else {
				$('#galleryImage').val('');
				$('#galleryImages').val('');
				this.commonService.toastError('File size should be at least 252*315px dimensions.');
				this.translate.instant(this.translate.instant('EDIT_PRODUCT.file_size_should_be_at_least_dimensions'))
			}
		});
	}

	loadGalleryImageFailed() {
		// show message
	}

	resetGalleryCropper() {


		$('#galleryImage').val('');
		$('#galleryImages').val('');
	}

	addProductImage() {
		// this.scale = 1;
		// this.transform = {};
		this.commonService.showLoader();
		var block = this.croppedGalleryImage.split(";");
		// Get the content type of the image
		var contentTypes = block[0].split(":")[1];// In this case "image/png"
		// get the real base64 content of the file
		var realData = block[1].split(",")[1];
		var blobImg = this.b64toBlob(realData, contentTypes, '');

		const formData = new FormData();
		formData.append('gallery_image', blobImg, 'gallery.png');
		this.sellerService.attachments(this.productId, formData).subscribe(data => {
			this.commonService.hideLoader();

			if (data.status == 'success') {

				this.commonService.toastSuccess(data.message);
				$('#galleryImageCropper').modal('hide');
				this.resetGalleryCropper();
				let imageObject = {
					'productAttachmentID': data.data.attachment_detail.gallery_image,
					'gallery_image_url': `${data.data.attachment_detail.gallery_image_url}${data.data.attachment_detail.gallery_image}`,
				}
				if (this.is_add == 1) {

					this.galleryList.push(imageObject);
					this.gallery_image.push(data.data.attachment_detail.gallery_image);

				}
				if (this.is_add == 0) {

					if (!this.finialVariantList[this.editVariantindex].variant_image.includes(data.data.attachment_detail.gallery_image)) {
						this.finialVariantList[this.editVariantindex].variant_image.push(data.data.attachment_detail.gallery_image)
					}
				}
				this.gallery_image_url = data.data.attachment_detail.gallery_image_url;
				this.scale = 1;
				this.transformgallery = {};

			} else {
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}

	deleteGalleryImage(index, productAttachmentID) {
		// alert(productAttachmentID);
		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('image', productAttachmentID);
		this.sellerService.deleteGalleyImage(formData).subscribe(data => {
			this.commonService.hideLoader();

			if (data.status == 'success') {
				this.commonService.toastSuccess(data.message);
				this.galleryList.splice(index, 1);
			} else {
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}

	deleteGalleryImage1(index, productAttachmentID) {
		// alert(index);
		if (this.editVariantModel.variant_image.length == 1) {
			this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.you_cant_delete_all_variant_images'));
			return false;
		}
		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('image', productAttachmentID);
		this.sellerService.deleteGalleyImage(formData).subscribe(data => {
			this.commonService.hideLoader();

			if (data.status == 'success') {
				this.commonService.toastSuccess(data.message);
				// this.galleryList.splice(index, 1);
				// this.gallery_image.splice(index, 1);

				console.log('this.editVariantModel.variant_image', this.editVariantModel.variant_image);
				this.editVariantModel.variant_image.splice(index, 1);

			} else {
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}

	OfferPriceListcommisoion($value = "") {
		if (this.editProductForm.get('offer_price').value <= 0) {
			this.editProductForm.get('offer_price').setValue('');
		}

		if (this.editProductForm.get('regularPrice').value <= 0) {
			this.editProductForm.get('regularPrice').setValue('');
		}

		// alert('hello');
		if ($value != "") {
			var getAmount = this.comissionData.filter((val) => Number($value) >= Number(val.minimum) && Number($value) <= Number(val.maximum));

			if (getAmount.length != 0) {
				var asss = getAmount[0].commission_rate
				this.offerList = getAmount;

				var Amt = getAmount.filter((val) => val.commission_type == 1)

				if (Amt != 0) {
					asss = Amt[0].commission_rate;

				}

				const percentVal = this.calcPercent($value, asss);
				var numberValue1 = Number(percentVal);
				var numberValue2 = Number($value);
				var sellingAmount = numberValue2 + numberValue1;
				this.offerList = sellingAmount.toFixed(2);
				this.offerListcomission = asss;

			}
			else {
				// this.offerList = this.editProductForm.get('offer_price').value;
				// this.offerListcomission = 0;

				//==========================offer price check comission ================================


				Swal.fire({
					text: this.translate.instant('ADD_PRODUCT.warring_text_for_offer'),
					icon: 'warning',
					showCancelButton: true,
					cancelButtonText: this.translate.instant('COMMON.cancel'),
					confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
					reverseButtons: true
				}).then((result) => {
					console.log('result', result);
					if (result.isConfirmed) {
						var url = '/seller/seller-account/commission';
						window.open(url, "_blank");

						// this.router.navigate(['/seller/seller-account/commission']);

					}
				})
			}
		}


	}


	clearVariantOfferList(value = "", key = '') {
		if (key != "") {
			value = "";
			this.offerList = 0;
			this.offerListcomission = 0;
		}
	}

	maximumDayReturning($event) {
		const returnctrl = this.editProductForm.get('days_of_return');
		if ($event.target.checked) {
			// returnctrl.setValidators([Validators.required, Validators.pattern('^[1-9]*$')]);
			returnctrl.setValidators([Validators.required]);

			this.returningDay = true;
		} else {
			returnctrl.clearValidators();
			this.returningDay = false;
			this.editProductForm.get('days_of_return').setValue('');
		}
		returnctrl.updateValueAndValidity();
	}

	clickShip() {
		this.getShippingList();
		this.lastSel = $("#clickShipID option:selected").val();
	}






	// For province
	onMunicipalitiesItemSelect(e) {
		$("#clickShipID").val('1');
		this.getShippingList(); //For shipping price list
		const shipAmountCtrl = this.editProductForm.get('shipAmount');
		shipAmountCtrl.setValidators([Validators.required]);
		this.shipChargeFlag = true;
		this.provinceGetListForShipping.push(e);
	}




	removeMunicipalitiesById(ids: any, flag = "") {
		console.log('ids', ids);

		// let provinceIdArrays = [];
		// provinceIdArrays.push(...ids);  // Spread the elements of ids (if any) and push them into the array
		// console.log('provinceIdArrays 111',provinceIdArrays);
		// get current municipalities ids 
		const currentSelection = this.editProductForm.get('municipalities').value;

		let provinceArrayIds = this.commonService.ArrayFilter(this.editProductForm.get('province').value, 3);
		// // Filter the municipalitiesAddDataList based on province_id not in ids
		// const filteredProvince = this.municipalitiesAddDataList.filter(municipality => !ids.includes(municipality.province_id));
		
		console.log('municipalitiesAddDataList ------',this.municipalitiesAddDataList);


		const filteredProvince = this.municipalitiesAddDataList.filter(municipality => {
			return municipality.province_id !== ids.toString(); // Convert ids to string for comparison

			// return !provinceIdArrays.includes(municipality.province_id);
		});

		// console.log('provinceIdArrays 222',provinceIdArrays);
		// console.log('filteredProvince',filteredProvince);

		// console.log('currentSelection -----',currentSelection);
		console.log('filteredProvince -----',filteredProvince);

		// Filter the currentSelection based on the filteredSelection ids
		const filteredMunicipalities = currentSelection.filter(municipality =>
			filteredProvince.some(filtered => filtered.id === municipality.id)
		);
						
		// this.provinceGetListForShipping.splice(this.provinceGetListForShipping.findIndex(item => item.id === filteredMunicipalities), 1)

		console.log('filteredMunicipalities',filteredMunicipalities);
		// Set the filtered current selection back to the form
		this.editProductForm.get('municipalities').setValue(filteredMunicipalities);

		this.provinceGetListForShipping = [];
		// this.provinceGetListForShipping = filteredMunicipalities;

		console.log('222222222',filteredProvince);

		
		this.provinceIdForMunicipalities = this.provinceIdForMunicipalities.filter(item => item !== ids);
		console.log('aassee', this.provinceIdForMunicipalities);
		
		// if(filteredProvince.length == 0){
		
		if(this.provinceIdForMunicipalities.length == 0){
			this.provinceIdForMunicipalities =[];
		}else{
			// Filter the provinces based on provinceIdForMunicipalities
			const filteredProvinceIds = filteredProvince
			.filter(val => this.provinceIdForMunicipalities.includes(val.province_id))
			.map(val => val.province_id);

		console.log('00000000',filteredProvinceIds);
			

			// Combine the filtered province IDs with existing ones (avoiding duplicates)
  
			this.provinceIdForMunicipalities = [...new Set([...this.provinceIdForMunicipalities, ...filteredProvinceIds])];

			this.provinceIdForMunicipalities = this.provinceIdForMunicipalities.filter(
				(id) => id != ids.toString()
			  );
			
		console.log('remove province',this.provinceIdForMunicipalities);
			// Call the getAddMunicipalities method with the updated list

			setTimeout(() => {
				// this.getAddMunicipalities(filteredProvinceIds);
				this.getAddMunicipalities(provinceArrayIds,'1');
			  }, 100);
			


		}
	}


	//For Province uncheck 
	onMunicipalitiesDeSelect(item: any, flag = '') {
		if (flag == '1') {
			this.removeMunicipalitiesById(item, '1')

		}else{
		this.provinceGetListForShipping.splice(this.provinceGetListForShipping.findIndex(itemCheck => itemCheck.id === item.id), 1)
		console.log('this.provinceGetListForShipping', this.provinceGetListForShipping);
		}
		$("#clickShipID").val('1');
		this.getShippingList(); //For shipping price list
		const shipAmountCtrl = this.editProductForm.get('shipAmount');
		shipAmountCtrl.setValidators([Validators.required]);
		this.shipChargeFlag = true;

		
	}





	// For select all province multiselect
	onMunicipalitiesSelectAll(items: any) {
		$("#clickShipID").val('1');
		this.getShippingList(); //For shipping price list
		const shipAmountCtrl = this.editProductForm.get('shipAmount');
		shipAmountCtrl.setValidators([Validators.required]);
		this.shipChargeFlag = true;
		this.provinceGetListForShipping = [];
		this.provinceGetListForShipping = items;
	}
	// End

	// For unselect all province
	onMunicipalitiesDeSelectAll(items: any) {
		$("#clickShipID").val('1');
		this.getShippingList(); //For shipping price list
		const shipAmountCtrl = this.editProductForm.get('shipAmount');
		shipAmountCtrl.setValidators([Validators.required]);
		this.shipChargeFlag = true;
		this.provinceGetListForShipping = [];

	}


	// For province
	onItemSelect(e) {
		this.ProvinceFieldValidation.push(e);
		console.log('before', this.provinceIdForMunicipalities);
		this.provinceIdForMunicipalities.push(e.provinceID);
		console.log('before 123', this.provinceIdForMunicipalities);
		var provinceIdForMunicipalities = this.provinceIdForMunicipalities.join();

		console.log('after', provinceIdForMunicipalities);
		// console.log('checkProvinceId', provinceIdForMunicipalities);
		this.getAddMunicipalities(provinceIdForMunicipalities)
	}
	// End




	resetDropdown() {
		this.editProductForm.get('municipalities').setValue([]);
		this.editProductForm.get('municipalities').setValue('');
	}





	// //For Province uncheck 
	//For Province uncheck 
	onDeSelect(item: any) {

		// console.log('ooooooopppp',this.ProvinceFieldValidation);
		console.log('here ...',item);

		const index = this.ProvinceFieldValidation.findIndex(
			id => id === item.provinceID
		);

		if (index !== -1) {
			this.ProvinceFieldValidation.splice(index, 1);
		}

		// this.ProvinceFieldValidation.splice(this.ProvinceFieldValidation.findIndex(item => item.provinceID === item.provinceID), 1)
		console.log('eee rrr',this.ProvinceFieldValidation);


		console.log('firstly', this.provinceIdForMunicipalities);
		// this.resetDropdown();
		// this.removeMunicipalityById(item.provinceID)
		this.onMunicipalitiesDeSelect(item.provinceID, '1');
		this.municipalitiesAddDataList = [];
		console.log('bbeefforree', this.provinceIdForMunicipalities);
		// this.provinceIdForMunicipalities.splice(this.provinceIdForMunicipalities.findIndex(item => item == item.provinceID), 1)
		// console.log('aafftteerr', this.provinceIdForMunicipalities);
		

		// var provinceIdForMunicipalities = this.provinceIdForMunicipalities.join();
	}


	// For select all province multiselect
	onSelectAll(items: any) {

		this.ProvinceFieldValidation = [];
		this.ProvinceFieldValidation = items;
		// Ensure items is an array and map over it to extract provinceIDs
		this.provinceIdForMunicipalities = items.map((item: any) => item.provinceID);
		var provinceIdForMunicipalities = this.provinceIdForMunicipalities.join();
		this.getAddMunicipalities(provinceIdForMunicipalities);
	}


	// // For select all province multiselect
	// onSelectAll(items: any) {
	// 	console.log('itemsPreview', items)
	// 	this.provinceIdForMunicipalities =[];
	// 	this.provinceIdForMunicipalities.push(items.provinceID);
	// 	console.log('checkProvinceId', this.provinceIdForMunicipalities);

	// 	var provinceIdForMunicipalities = this.provinceIdForMunicipalities.join();
	// 	console.log('checkProvinceId', provinceIdForMunicipalities);
	// 	this.getAddMunicipalities(provinceIdForMunicipalities)
	// }

	//code for shipping price validation

	// $("#clickShipID").val('1');
	// this.getShippingList(); //For shipping price list
	// const shipAmountCtrl = this.addProductForm.get('shipAmount');
	// shipAmountCtrl.setValidators([Validators.required]);
	// this.shipChargeFlag = true;
	// this.provinceGetListForShipping = [];
	// this.provinceGetListForShipping = items;
	//}
	// End

	// For unselect all province
	onDeSelectAll(items: any) {
		this.ProvinceFieldValidation = [];
		this.provinceIdForMunicipalities = [];
		this.municipalitiesAddDataList = [];
		this.provinceIdss = [];
		this.provinceGetListForShipping = [];
		this.resetDropdown();


		//code for shipping price validation
		// $("#clickShipID").val('1');
		// this.getShippingList(); //For shipping price list
		// const shipAmountCtrl = this.addProductForm.get('shipAmount');
		// shipAmountCtrl.setValidators([Validators.required]);
		// this.shipChargeFlag = true;
		// this.provinceGetListForShipping = [];

	}




	// onItemSelect(e) {
	// 	this.getShippingList();
	// 	$("#clickShipID").val('1');

	// 	const shipAmountCtrl = this.editProductForm.get('shipAmount');
	// 	shipAmountCtrl.setValidators([Validators.required]);
	// 	this.shipChargeFlag = true;
	// 	// $("#clickShipID").val(this.lastSel);
	// 	this.provinceGetListForShipping.push(e);
	// }

	// onDeSelect(item: any) {
	// 	// $("#clickShipID").val(this.lastSel);
	// 	this.getShippingList();
	// 	$("#clickShipID").val('1');

	// 	const shipAmountCtrl = this.editProductForm.get('shipAmount');
	// 	shipAmountCtrl.setValidators([Validators.required]);
	// 	this.shipChargeFlag = true;

	// 	this.provinceGetListForShipping.splice(this.provinceGetListForShipping.findIndex(item => item.provinceID === item.provinceID), 1)
	// }

	// onSelectAll(items: any) {
	// 	this.getShippingList();
	// 	$("#clickShipID").val('1');

	// 	const shipAmountCtrl = this.editProductForm.get('shipAmount');
	// 	shipAmountCtrl.setValidators([Validators.required]);
	// 	this.shipChargeFlag = true;
	// 	// $("#clickShipID").val(this.lastSel);
	// 	this.provinceGetListForShipping = [];
	// 	this.provinceGetListForShipping = items;
	// }


	// onDeSelectAll(items: any) {
	// 	this.getShippingList();
	// 	$("#clickShipID").val('1');

	// 	// $("#clickShipID").val(this.lastSel);
	// 	const shipAmountCtrl = this.editProductForm.get('shipAmount');
	// 	shipAmountCtrl.setValidators([Validators.required]);
	// 	this.shipChargeFlag = true;

	// 	this.provinceGetListForShipping = [];
	// }


	// Update product on firebase
	addOnFirebase(productData) {
		this.firebaseService.productAddFB(productData.product_id, productData)
	}



	// quantity validation if no variants 
	noVariantsQuantity() {
		const product_quantity = this.editProductForm.get('product_quantity');
		if (this.is_no_variants == 1) {
			product_quantity.setValidators([Validators.required]);
		} else {
			product_quantity.clearValidators();
			this.editProductForm.get('product_quantity').setValue('');
		}
		product_quantity.updateValueAndValidity();
	}
	// End

	// set default variant in case of no varinat select 


	// Gallery image croper
	addProductMultiImage() {

		this.commonService.showLoader();
		var block = this.croppedImage.split(";");
		// Get the content type of the image
		var contentTypes = block[0].split(":")[1];// In this case "image/png"
		// get the real base64 content of the file
		var realData = block[1].split(",")[1];
		var blobImg = this.b64toBlob(realData, contentTypes, '');


		if (this.product_image.length == 0) {
			this.FImage = blobImg;
			this.isUrl = 1;

		}

		const formData = new FormData();
		// formData.append('gallery_image', blobImg, 'gallery.png');
		formData.append('featured_image', blobImg, 'featuredImage.png');
		formData.append('is_no_variants', this.is_no_variants);


		const featuredImage = this.editProductForm.get('featuredImage');


		this.sellerService.attachmentsProduct(this.productId, formData).subscribe(data => {
			this.commonService.hideLoader();

			featuredImage.clearValidators(); // clear validation
			featuredImage.updateValueAndValidity(); // upddate validation 

			if (data.status == 'success') {
				// this.commonService.toastSuccess(data.message);
				$('#imageCropper').modal('hide');
				// this.resetCropper();

				let imageObject = {
					'productAttachmentID': data.data.attachment_detail.gallery_image,
					'gallery_image_url': `${data.data.attachment_detail.gallery_image_url}${data.data.attachment_detail.gallery_image}`,
					'imageId': data.data.attachment_detail.imageId,
					'type': 2,
				}
				this.product_image.push(imageObject);
				this.imageId.push(data.data.attachment_detail.imageId);
				this.scale = 1;
				this.transform = {};

			} else {
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}



	// Product images
	deleteProductImage(index, productAttachmentID, type) {



		if (type == 1) {
			this.removeImageArray.push(Number(productAttachmentID));
		}

		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('id', productAttachmentID);
		formData.append('is_no_variants', this.is_no_variants);
		this.sellerService.deleteProductGalleyImage(formData).subscribe(data => {
			this.commonService.hideLoader();

			if (data.status == 'success') {
				// this.commonService.toastSuccess(data.message);
				this.product_image.splice(index, 1);
				this.imageId.splice(index, 1);

			

				if (this.product_image.length == 0) {
					this.FImage = "";
					this.editProductForm.get('featuredImage').setValue('');


					const featuredImage = this.editProductForm.get('featuredImage');
					featuredImage.setValidators(Validators.required);
					featuredImage.updateValueAndValidity();



				}

			} else {
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End
	// End
	noVarinatsDefault() {
		this.finialVariantList = [];

		if (this.finialVariantList.length == 0 && this.is_no_variants == 1) {
			this.editProductForm.get('isVariantAdded').setValue('');
		}
		var imange = this.editProductForm.get('featuredImage').value;
		const myArray = imange.split("\\");
		this.noVariantsImage.push(myArray[2]);


		let pushObject = {
			'name': this.selectedVariantList[0].name,
			'variantID': this.selectedVariantList[0].variantID,
			'variant_value': this.selectedVariantList[0].variant_value[0].variant_value,
			'variantValueID': this.selectedVariantList[0].variant_value[0].variantValueID,
		}

		this.variantsValuesList.push(pushObject);


		if (this.offerList == undefined) {
			this.offerList = 0
		}

		if (this.offerListcomission == undefined) {
			this.offerListcomission = 0
		}



		if (this.editProductForm.get('product_weight').value != 0) {
			var product_weight = this.editProductForm.get('product_weight').value;

		} else {
			product_weight = 0;

		}


		let addVariant: any = {};
		addVariant.discountPercent = 0;
		addVariant.discountAmount = 0;
		this.editProductForm.get('featuredImage')
		addVariant.vatiantsData = this.variantsValuesList;
		addVariant.variant_image = [];
		addVariant.amount = this.editProductForm.get('regularPrice').value;
		addVariant.selling_price = this.sellingAmount;
		addVariant.selling_variant_commission = this.sellingAmountPercentage;
		addVariant.sku = this.editProductForm.get('product_sku').value;
		addVariant.quantity = this.editProductForm.get('product_quantity').value;
		addVariant.product_weight = product_weight;
		addVariant.offer_price_with_admin_commission = this.offerList;
		addVariant.offer_price_commission_percent = this.offerListcomission;
		addVariant.offer_price = this.editProductForm.get('offer_price').value;
		addVariant.showlist = 1;


		// image issue remaming 
		// if(this.finialVariantList.lenght < 0){
		this.finialVariantList.push(addVariant);

		// }


	}


	
	// For get municipalities list 
	getAddMunicipalities(province_ids, flag = "") {
		console.log('check ids new', province_ids);

		this.authService.getMunicipalities(province_ids).subscribe(municipalitiesData => {
			console.log('municipalitiesData 112233', municipalitiesData);
			this.commonService.hideLoader();

			if (municipalitiesData.status == 'success') {
				this.municipalitiesAddDataList = municipalitiesData.data.municipalities;
			setTimeout(() => {

				// console.log('municipalitiesAddDataList 112233',this.municipalitiesAddDataList);
				if(flag == '1'){
					const result = Array.isArray(this.municipalitiesAddDataList)
					? this.municipalitiesAddDataList.map(({ id, municipalities }) => ({ id, municipalities }))
					: [];

					// this.provinceGetListForShipping =[];
					// this.provinceGetListForShipping = result;

					// console.log('result', result);
				}
				this.municipalitiesAddDataList = Array.isArray(this.municipalitiesAddDataList)
				? this.municipalitiesAddDataList.map(municipality => ({
					...municipality,
					municipalityName: `${municipality.municipalities} (${municipality.province_name})`
				  }))
				: [];
			  
			  }, 100);
				

			} else {
				this.municipalitiesAddDataList = [];
			}
			// console.log('this.province_ids -----',this.municipalitiesAddDataList);

			// console.log('this.province_ids -----',province_ids);
			// this.provinceIdsArray = province_ids.split(",");
			// this.provinceIdsArray = province_ids;

			this.provinceIdsArray = typeof province_ids === 'string' && province_ids.includes(",") 
			? province_ids.split(",") 
			: province_ids;

		
			// console.log('this.provinceIdsArray -----',this.provinceIdsArray);
			// console.log('this.provinceList -----',this.provinceList);
			

			// this.filteredProvinces = this.provinceList.filter(province =>
			// 	this.provinceIdsArray.includes(province.provinceID)
			// );

			if (!Array.isArray(this.provinceIdsArray)) {
				this.provinceIdsArray = Array.isArray(this.provinceIdsArray) ? this.provinceIdsArray : [this.provinceIdsArray];
			}

			this.filteredProvinces = this.provinceList.filter(province =>
				this.provinceIdsArray.some(id => id === province.provinceID)
			);

			// this.filteredProvinces = this.provinceList.filter(province =>
			// 	this.provinceIdsArray.map(String).includes(String(province.provinceID))
			// );
			
			console.log('aaaqqq ----',this.filteredProvinces);


			this.filteredProvinces.forEach(province => {
				this.getProvinceWarehouseList(province.provinceID);
			});

			// console.log('bbbnnn ----',this.filteredProvinces);


		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}

	getProvinceWarehouseList(provinceID: string) {

		this.warehouseAddressProductList = [];
		const formData = new FormData();
		formData.append('provinces', provinceID); // Send the selected provinceID
		
		console.log('Fetching warehouse addresses for Province ID:', provinceID);
		
		// Call the service to fetch warehouse addresses for the selected province
		this.sellerService.getWarehouseAddressByProvince(formData).subscribe(
		  (warehouseAddressList) => {
			// console.log('Warehouse Address List:', warehouseAddressList.data.warehouseAddress);
		
			this.commonService.hideLoader(); // Hide loader after the response
		
			if (warehouseAddressList.status === 'success') {
			  if (warehouseAddressList.data.warehouseAddress.length === 0) {
				this.warehouseAddressProductListCount = 0; // No warehouse addresses
			  }
			  
			  // Append the fetched warehouse addresses to the list
			  this.warehouseAddressProductList.push(...warehouseAddressList.data.warehouseAddress);

			// console.log('Warehouse Address List:', this.warehouseAddressProductList);

			}
		  },
		  (err) => {
			this.commonService.hideLoader(); // Hide loader on error
			this.commonService.errorhandler(err); // Handle error
		  }
		);
	}

	getPreselectedValue(provinceID: string): string | null {
		// Check if productDetail or pickup_options is undefined
		if (!this.productDetail || !this.productDetail.pickup_options || !Array.isArray(this.productDetail.pickup_options)) {
		//   console.warn('pickup_options is not defined or not an array');
		  return null;
		}
	  
		// Find the corresponding pickup option for the province
		const pickupOption = this.productDetail.pickup_options.find(option => option.provinceID === provinceID);
	  
		if (pickupOption && pickupOption.warehouseData && pickupOption.warehouseData.wa_id) {
		  const preselectedValue = `${pickupOption.provinceID}:${pickupOption.warehouseData.wa_id}`;
	  
		  // Ensure the preselected value is added to selectedValues if not already present
		  if (!this.selectedValues.includes(preselectedValue)) {
			this.selectedValues.push(preselectedValue);
		  }

		//   console.log('aaww ssdd', this.selectedValues);



		  // Update `seller_warehouse_ids` and `convertedObject`
		  this.seller_warehouse_ids = [...new Set(this.selectedValues.map(value => value.split(':')[1]))].join(',');
		//   console.log('seller_warehouse_ids -----', this.seller_warehouse_ids);
		
		  this.convertedObject = this.selectedValues.reduce((acc, value) => {
			const [key, val] = value.split(':');
			acc[key] = val;
			return acc;
		  }, {});
		
		//   console.log('convertedObject -----', JSON.stringify(this.convertedObject));
		  
	  
		  return preselectedValue;
		}
	  
		return null;
	}
	  
	onWarehouseChange(event: Event, index: number): void {
		const selectElement = event.target as HTMLSelectElement;
		const selectedValue = selectElement.value;
	  
		if (selectElement && selectedValue) {
		  // Update the selected value for the specific province
		  this.filteredProvinces[index].selectedValue = selectedValue;
		}
	  
		console.log('Selected Value:', selectedValue);
	  
		// Extract province ID and wa_id
		const [provinceID, wa_id] = selectedValue.split(':');
	  
		// Remove any existing entry for the province ID in selectedValues
		const existingIndex = this.selectedValues.findIndex(value => value.startsWith(provinceID + ':'));
		if (existingIndex !== -1) {
		  this.selectedValues.splice(existingIndex, 1);
		}
	  
		// Add the new selection
		this.selectedValues.push(selectedValue);
	  
		// Update `seller_warehouse_ids` and `convertedObject`
		this.seller_warehouse_ids = [...new Set(this.selectedValues.map(value => value.split(':')[1]))].join(',');
		console.log('seller_warehouse_ids', this.seller_warehouse_ids);
	  
		this.convertedObject = this.selectedValues.reduce((acc, value) => {
		  const [key, val] = value.split(':');
		  acc[key] = val;
		  return acc;
		}, {});
	  
		console.log('convertedObject', JSON.stringify(this.convertedObject));
	}

	b64toBlob(b64Data, contentType, sliceSize) {
		contentType = contentType || '';
		sliceSize = sliceSize || 512;
	
		var byteCharacters = atob(b64Data);
		var byteArrays = [];
	
		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);
	
			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
	
			var byteArray = new Uint8Array(byteNumbers);
	
			byteArrays.push(byteArray);
		}
	
		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}
	  
}