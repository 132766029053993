import { Component, OnInit, HostListener, Inject, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { faPhoneAlt, faSearch, faShoppingCart, faUser, faBars, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '../../auth/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { ShopService } from '../../services/shop.service';
import { FirebaseService } from '../../services/firebase.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { element } from 'protractor';
import { AuthenticationService } from 'src/app/services/authentication.service';

declare const checkOnline: any;
declare const setValue: any;
declare const $: any;
declare const getTimeZone: any;

@Component({
	selector: 'app-header-buyer',
	templateUrl: './header-buyer.component.html',
	styleUrls: ['./header-buyer.component.css']
})
export class HeaderBuyerComponent implements OnInit {
	faPhoneAlt = faPhoneAlt;
	faSearch = faSearch;
	faHeartLine = faHeart;
	faShoppingCart = faShoppingCart;
	faUser = faUser;
	faBars = faBars;
	faCheck = faCheck;
	faTimes = faTimes;
	toggle: boolean = true;
	name: any = "";
	searchData: any = "";
	categoryList: any = [];
	cartCount: number = 0;
	wishcount: number = 0;
	notificationList: any = [];
	notificationCount: string;
	filterCategory: any = [];
	filterParentCategory: any = [];
	collectLastCategory: any = [];
	hideElement = false;
	showElement = true;
	provinceList: any = [];
	offset: any;
	provinceForm: FormGroup;
	referralForm: FormGroup;
	referralShowFlag: boolean = false;
	urlpath: any;
	adminnoId: any;
	municipalitiesDataList: any = [];
	BuyerMunicipalities : any = "";
	// For language 
	language: any = [
		{ img: '/assets/img/common/us.webp', language: 'English', code: 'en' },
		{ img: '/assets/img/common/spain.webp', language: 'Spanish', code: 'es' }
	]
	selectedLanguage: string = 'Spanish'; //Default language
	selectedLanguageImage: string = '/assets/img/common/spain.webp'; //Selected language image
	selectedLanguageCode: string = 'es'; //Selected language code
	BuyerProvince: any;
	pageRoute: any = "";

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private authService: AuthService,
		private router: Router,
		private commonService: CommonService,
		private shopService: ShopService,
		private firebaseService: FirebaseService,
		private translate: TranslateService,
		private fb: FormBuilder,
		private authenticationService: AuthenticationService,

	) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (event.url === '/' || event.url === '/login' || event.url === '/signup/buyer' || event.url === '/signup/seller') {
					this.hideElement = true;
					this.showElement = false;
				} else {
					this.hideElement = false;
					this.showElement = true;
				}

				//For get product details url
				var tokens = this.router.url.split('/');
				this.urlpath = tokens[1];
			}
		});

	}

	ngOnInit() {
        this.pageRoute = localStorage.getItem("pageRoute");

		//For Province form validations
		this.provinceForm = this.fb.group({
			"province": ['', [Validators.required]],
			"municipalities": ['', [Validators.required]],
		})
		// End

		//For Referel form validation
		this.referralForm = this.fb.group({
			"referral": ['', [Validators.required]],
		})
		// End

		if (this.referralShowFlag == true) {
			if (localStorage.getItem("is_signup") == '1') {
				$('#referralShow').modal({ backdrop: 'static', keyboard: false });

			}
		}

		this.getProvinceList(); //For provience list get
		this.offset = getTimeZone();

		// For dropdown
		$('.dropdown').on('show.bs.dropdown', function (e) {
			$(this).find('.dropdown-menu').first().stop(true, true).slideDown(300);
		});

		$('.dropdown').on('hide.bs.dropdown', function (e) {
			$(this).find('.dropdown-menu').first().stop(true, true).slideUp(200);
		});
		// End

		// For tooltip initialize
		$(function () {
			$('[data-toggle="tooltip"]').tooltip()
		})

		//open/close mega-navigation
		// $('.cd-dropdown-trigger').on('click', function(event) {
		//     event.preventDefault();
		//     toggleNav();
		// });

		//to open submenu on hover
		this.openSubMenu();

		//close meganavigation
		$('.cd-dropdown .cd-close').on('click', function (event) {
			event.preventDefault();
			toggleNav();
		});

		$(document).on("click", function (event) {
			var $trigger = $(".cd-dropdown-wrapper");
			if ($trigger !== event.target && !$trigger.has(event.target).length) {
				//event.preventDefault();
				var navIsVisible = (!$('.cd-dropdown').hasClass('dropdown-is-active')) ? true : false;
				$('.cd-dropdown').removeClass('dropdown-is-active', navIsVisible);
				$('.cd-dropdown-trigger').removeClass('dropdown-is-active', navIsVisible);
			}
		});

		$('.headerAct').on("click", function (event) {
			event.preventDefault();
			$('nav.cd-dropdown').removeClass('dropdown-is-active');
		});

		//to open submenu on hover
		$('.cd-dropdown-trigger').on('click', function (event) {

			event.preventDefault();
			var submenuDirection = (!$('.cd-dropdown-wrapper').hasClass('open-to-left')) ? 'right' : 'left';
			$('.cd-dropdown-content').menuAim({
				activate: function (row) {
					$(row).children().addClass('is-active').removeClass('fade-out');
					if ($('.cd-dropdown-content .fade-in').length == 0) $(row).children('ul').addClass('fade-in');
				},
				deactivate: function (row) {
					$(row).children().removeClass('is-active');
					if ($('li.has-children:hover').length == 0 || $('li.has-children:hover').is($(row))) {
						$('.cd-dropdown-content').find('.fade-in').removeClass('fade-in');
						$(row).children('ul').addClass('fade-out')
					}
				},
				exitMenu: function () {
					$('.cd-dropdown-content').find('.is-active').removeClass('is-active');
					return true;
				},
				submenuDirection: submenuDirection,
			});
		});

		function toggleNav() {
			var navIsVisible = (!$('.cd-dropdown').hasClass('dropdown-is-active')) ? true : false;
			$('.cd-dropdown').toggleClass('dropdown-is-active', navIsVisible);
			$('.cd-dropdown-trigger').toggleClass('dropdown-is-active', navIsVisible);
			if (!navIsVisible) {
				$('.cd-dropdown').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
					$('.has-children ul').addClass('is-hidden');
					$('.move-out').removeClass('move-out');
					$('.is-active').removeClass('is-active');
				});
			}
		}

		if (this.authService.isLoggedIn() && this.authService.getUserDetails().user_type == 'buyer') {
			if (this.authService.getUserDetails().province == "" || this.authService.getUserDetails().province == undefined) {
				$('#provienceShow').modal({ backdrop: 'static', keyboard: false });
			}

			this.getNotificationList(); //For notification list
			this.name = this.authService.getUserDetails().full_name;
			checkOnline(this.firebaseService.getDataBase(), this.authService.getCurrentUserId());
		}

		this.getCategoryList(); //For category list
		this.getCartCount(); //For cart count show
		this.getWishCount(); //For wishlist count

		const str = this.router.url;
		const regex = RegExp('/shop');
		const regex2 = RegExp('/shop/');
		if (regex.test(str) == false) {
			this.searchData = ""
			this.commonService.searchTextStore = "";
		} else if (regex2.test(str) == true) {
			this.searchData = ""
			this.commonService.searchTextStore = "";
		} else {
			this.searchData = this.commonService.searchTextStore;
		}


		if (this.authService.isLoggedIn()) {
			this.name = this.authService.getUserDetails().full_name;
			this.BuyerProvince = this.authService.getUserDetails().province_name
			this.BuyerMunicipalities = this.authService.getUserDetails().municipalities_name;
			var selectedProvinceID = this.authService.getUserDetails().province;
			this.getMunicipalities(selectedProvinceID);
		}

		//For language
		if (this.authService.isLoggedIn() == true) {
			if (this.authService.getLanguage() == 'es') {
				this.selectedLanguage = 'Spanish';
				this.selectedLanguageImage = '/assets/img/common/spain.webp';
				this.selectedLanguageCode = this.authService.getLanguage();

			} else if (this.authService.getLanguage() == 'en') {
				this.selectedLanguage = 'English';
				this.selectedLanguageImage = '/assets/img/common/us.webp';
				this.selectedLanguageCode = this.authService.getLanguage();
			}

			this.translate.use(this.authService.getLanguage());

		}

		else {


			if (this.commonService.getLanguage() == 'es') {
				this.selectedLanguage = 'Spanish';
				this.selectedLanguageImage = '/assets/img/common/spain.webp';
				this.selectedLanguageCode = this.commonService.getLanguage();

			} else if (this.commonService.getLanguage() == 'en') {
				this.selectedLanguage = 'English';
				this.selectedLanguageImage = '/assets/img/common/us.webp';
				this.selectedLanguageCode = this.commonService.getLanguage();
			}

			this.translate.use(this.commonService.getLanguage());

		}
		// End

	}
	// Form validation 
	get province(): any { return this.provinceForm.get('province'); }
	get municipalities(): any { return this.provinceForm.get('municipalities'); }
	get referral(): any { return this.referralForm.get('referral'); }
	// End


	getProvince() {
		this.BuyerProvince = this.authService.getUserDetails().province_name;
		this.BuyerMunicipalities = this.authService.getUserDetails().municipalities_name;
	}

	//Add province function 
	addProvince() {
		if (this.provinceForm.valid) {
			var auth_t = this.authService.getUserDetails().authtoken;
			this.commonService.showLoader();

			const formData = new FormData();

			formData.append('province', this.province.value);
			formData.append('municipality_id', this.municipalities.value);

			// formData.forEach((value,key) => {
			// 	console.log(key + " " + value)
			// });

			// return false;
			this.authService.addProvince(formData).subscribe(data => {
				if (data.status == "success") {
					data.data.user_detail.authtoken = auth_t;
					localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));
					this.BuyerProvince = this.authService.getUserDetails().province_name
					this.BuyerMunicipalities = this.authService.getUserDetails().municipalities_name
					this.commonService.hideLoader();
					$('#provienceShow').modal('hide');
					$('#referralShow').modal({ backdrop: 'static', keyboard: false });
					this.referralShowFlag = true;
				} else {
					this.commonService.toastError(data.message);
					this.commonService.hideLoader();
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
		}
		// localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));
	}
	// End

	//submit referral code
	submitCode() {
		if (this.referralForm.valid) {
			this.commonService.showLoader();
			var formData = new FormData();
			formData.append('referral', $("#referral").val());
			formData.append('user_id', this.authService.getCurrentUserId());
			this.authService.redeem_code(formData).subscribe((data) => {
				this.commonService.hideLoader();

				if (data.status == "fail") {
					this.commonService.toastError(data.message);
				} else {
					localStorage.removeItem("is_signup");
					this.commonService.toastSuccess(this.translate.instant('HEADER.cong') + " " + data.message);
					$('#referralShow').modal('hide');
					this.referralShowFlag = false;
					localStorage.removeItem("is_signup");
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
		}
	}
	// End

	//cancel reffrel code without enter
	cancelCode() {
		localStorage.removeItem("is_signup");
		$('#referralShow').modal('hide');
		this.referralShowFlag = false;
		localStorage.removeItem("is_signup");
	}
	// End

	//prevent space in referral code
	preventSpace(event) {
		if (event.keyCode == 32) {
			event.preventDefault();
			// event.preventDefault();
			return false;
		}
	}

	// For toggle
	change() {
		this.toggle = !this.toggle;
	}
	// End

	@Output() setSearchText = new EventEmitter<string>();

	@HostListener('window:scroll', ['$event'])

	// Add sticky class on window scroll for fixed header
	onWindowScroll(e) {
		if (window.pageYOffset > 50) {
			let element = document.getElementById('navbar');
			element.classList.add('sticky');
		} else {
			let element = document.getElementById('navbar');
			element.classList.remove('sticky');
		}

	}
	// End 

	// For check user auth
	get isLoggedIn() {
		return (this.authService.isLoggedIn()) ? 1 : 0;
	}//End

	//get notification list
	getNotificationList() {
		if (this.authService.isLoggedIn()) {
			this.authService.getNotifictionList().subscribe(notificationData => {



				if (notificationData.status == 'success' && notificationData.data.data_found) {
					this.notificationList = notificationData.data.notification;
					this.notificationCount = (notificationData.data.count < 10) ? notificationData.data.count : "9+";
				} else {
					this.notificationCount = "0";
				}
			});

		}
	}
	// End

	//For Read Notification and redirection 
	readNotification(notificationId, notifiy) {
		var noType = notifiy.notification_type;
		if (noType == 'Order Placed' || noType == 'Order Seller Received' || noType == 'Order Return In Process' || noType == 'Order Retun Request' || noType == 'Order Delivered' || noType == 'Order Inzone' || noType == 'Order Packed' || noType == 'Order Shipped' || noType == 'Order Accepted' || noType == 'Order Rejected' || noType == 'Order Refund Initiated' || noType == 'Order Picked up  Buyer' || noType == 'Order Refunded' || noType == 'Refund' || noType == 'Cancel Order' || noType == 'Order Ready to Pickup' || noType == 'Order Picked up') {
			this.router.navigate(['/my-order-detail/', notifiy.reference_id]);
		} else if (noType == 'Dispute Created' || noType == 'Dispute Closed') {
			this.router.navigate(['/my-order-detail/', notifiy.reference_id]);
		} else if (noType == 'Commision') {
			this.router.navigate(['/my-account']);
		} else if (noType == 'Offer accepted' || noType == 'Offer Counter' || noType == 'Make an Offer' || noType == 'Offer rejected') {
			// this.router.navigate(['/product-offers']);
			this.router.navigate(['/product-offers-details/1/1/', notifiy.reference_id]);

		} else if (noType == 'Admin') {
			this.adminnoId = notifiy.notificationID
			$('#adminNotification').modal('show');

		} else if (noType == '' || noType == null) {
			this.router.navigate(['/']);
		} else {
			this.router.navigate(['/seller/seller-review/', notifiy.reference_id]);
		}

		if (notifiy.is_read == 0) {
			const formData = new FormData();
			formData.append('notification_id', notificationId);
			this.authService.readNotification(formData).subscribe(notificationData => {
				if (notificationData.status == 'success') {
					this.getNotificationList();
				}

			});
		}
	}
	// End

	//For get category list
	getCategoryList() {
		//Category Subactegory list for filter
		this.shopService.getCategorySubacategoryList().subscribe(
			(catSubactData) => {

				if (catSubactData.status == 'success') {
					this.categoryList = catSubactData.data.category;
					this.setUnSelected();
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}
	// End

	// For filter reset
	setUnSelected() {
		this.categoryList.forEach(category => {
			category.selectedCount = 0;

			category.sub_category.forEach(subcat1 => {
				subcat1.sub_category.forEach(subcat2 => {
					subcat2.is_select = false;

					subcat2.sub_category.forEach(subcat3 => {
						subcat3.is_select = false;
					});

				});
			});
		});
	}
	// End

	// For clear filter
	clearAll() {
		this.filterCategory = [];
		this.filterParentCategory = [];
		this.setUnSelected();
		this.applyFilter();
		this.ngOnInit();
		$(".subCategryUl").addClass("subCategryUlnew");



		// window.location.reload();

	}
	// End

	// For select category
	selectCategory($event, number, cat, subcat1, subcat2 = "", subcat3 = "") {


		if ($event.target.checked) {

			if (number == 2) {
				// $("input").prop("checked", true);

				this.categoryList[cat].sub_category[subcat1].is_select = true;

				this.categoryList[cat].sub_category[subcat1].sub_category.forEach(element => {

					element.is_select = true;

					if (element.sub_category.length != 0) {
						element.sub_category.forEach(element1 => {
							element1.is_select = true;

						})

					}


				});
			}

			if (number == 3) {

				this.categoryList[cat].sub_category[subcat1].sub_category[subcat2].is_select = true;
				this.categoryList[cat].sub_category[subcat1].is_select = false;

			} else if (number == 4) {

				this.categoryList[cat].sub_category[subcat1].sub_category[subcat2].sub_category[subcat3].is_select = true;
				this.categoryList[cat].sub_category[subcat1].is_select = false;

			}

			this.categoryList[cat].selectedCount = Number(this.categoryList[cat].selectedCount) + Number(1);

		} else {

			if (number == 2) {
				this.categoryList[cat].sub_category[subcat1].is_select = false;


				this.categoryList[cat].sub_category[subcat1].sub_category.forEach(element => {
					element.is_select = false;

					if (element.sub_category.length != 0) {
						element.sub_category.forEach(element1 => {
							element1.is_select = false;

						})

					}


				});

			}

			if (number == 3) {
				this.categoryList[cat].sub_category[subcat1].is_select = false;

				this.categoryList[cat].sub_category[subcat1].sub_category[subcat2].is_select = false;
			} else if (number == 4) {
				this.categoryList[cat].sub_category[subcat1].is_select = false;

				this.categoryList[cat].sub_category[subcat1].sub_category[subcat2].sub_category[subcat3].is_select = false;
			}

			this.categoryList[cat].selectedCount = Number(this.categoryList[cat].selectedCount) - Number(1);

		}

	}
	// End


	@Output() setCategoryList = new EventEmitter<string>();
	// Function for filter apply
	applyFilter() {
		this.filterCategory = [];
		this.filterParentCategory = [];
		this.collectLastCategory = [];

		this.categoryList.forEach(category => {

			category.sub_category.forEach(subcat1 => {



				if (subcat1.is_select === true) {
					this.filterCategory.push(subcat1.categoryID);
					this.filterParentCategory.push(category.categoryID);

				}

				subcat1.sub_category.forEach(subcat2 => {






					if (subcat2.is_select === true) {
						this.filterCategory.push(subcat2.categoryID);
						this.filterParentCategory.push(category.categoryID);

					}

					subcat2.sub_category.forEach(subcat3 => {
						if (subcat3.is_select === true) {
							this.filterParentCategory.push(category.categoryID);

							if (subcat3.sub_category.length > 0) {
								this.collectLastCategory.push.apply(this.collectLastCategory, subcat3.sub_category);
							} else {
								this.filterCategory.push(subcat3.categoryID);
							}

						}
					});

				});
			});
		});



		let uniqueCategory = [...new Set(this.filterParentCategory)];

		let categoryData: any = {
			'filterCategory': this.filterCategory,
			'filterParentCategory': uniqueCategory,
			'collectLastCategory': this.collectLastCategory
		};

		this.setCategoryList.next(categoryData);
		$('.cd-dropdown').removeClass('dropdown-is-active');
		$('.categoryDrpNew').removeClass('categoryDrp');

		// $('#categoryOpen1').css('visibility','hidden')
		// $('#categoryOpen1').css('opacity','0')
	}
	// End

	searchTimer: any;
	doneSearchInterval: any = 400;
	// For search filer
	search() {
		if (this.searchData.length != 0) {
			if (this.searchData.trim()) {
				const str = this.router.url;
				const regex = RegExp('/shop');
				if (regex.test(str) == false) {
					clearTimeout(this.searchTimer);
					this.searchTimer = setTimeout(() => {
						this.router.navigate(['/shop']);
						this.commonService.searchTextStore = this.searchData;
					}, this.doneSearchInterval);

				}

				this.setSearchText.next(this.searchData);
			}

		} else {
			const str = this.router.url;
			const regex = RegExp('/shop');
			if (regex.test(str) == false) {

				clearTimeout(this.searchTimer);
				this.searchTimer = setTimeout(() => {
					this.router.navigate(['/shop']);
					this.commonService.searchTextStore = this.searchData;
				}, this.doneSearchInterval);

			}

			this.setSearchText.next(this.searchData);
		}

	}
	// End

	onFocusone() {
		// alert('hello');

		$('.categoryDrpNew').addClass('categoryDrp');

		// $('#categoryOpen1').css('visibility','visible')
		// $('#categoryOpen1').css('opacity','1')
	}


	onFocusOut() {
		// alert('hello');
		// $('#categoryOpen1').css('visibility','hidden')
		// $('#categoryOpen1').css('opacity','0')
	}



	//cart count
	getCartCount() {
		if (this.authService.isLoggedIn()) {
			this.shopService.getCartCount().subscribe(data => {
				if (data.status == 'success') {
					if (data.data.cart_count == null) {
						this.cartCount = 0
					} else {
						this.cartCount = (data.data.cart_count <= 99) ? data.data.cart_count : "99+";
					}
				}
			});
		}
	}
	// End

	// For wishlist count 
	getWishCount() {
		if (this.authService.isLoggedIn()) {
			this.shopService.getWishCount().subscribe(data => {
				if (data.status == 'success') {
					if (data.data.count == null) {
						this.wishcount = 0
					} else {
						this.wishcount = (data.data.count <= 99) ? data.data.count : "99+";
					}
				}
			});
		}
	}
	// End

	// For time get
	getTimeAgo(dateTime) {
		return this.commonService.chatGroupTimeNotification(dateTime, '1');
	}
	// End
	convertTZ(date, tzString) {
		return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
	}

	// For loged out 
	logout() {
		this.commonService.showLoader();
		this.authService.logoutWeb().subscribe();
		this.authenticationService.SignOut();
		this.firebaseService.setOffline(this.authService.getCurrentUserId());
		this.authService.logout();
		setValue('senderUserId', "");
		setValue('chatNode', "");
		this.router.navigate(['/login']);
	}
	// End

	// For switch profile 
	switchProfile(userType) {
		this.commonService.showLoader();
		this.authService.switchProfile(userType).subscribe(profileData => {
			this.commonService.hideLoader();

			if (profileData.data.is_profile_setup) {
				// redirect to shop page
				localStorage.setItem(environment.dataStore.userData, JSON.stringify(profileData.data));
				this.updateOnFirebase(profileData.data);
				this.router.navigate(['seller/new-orders']);
				window.location.reload();
			} else {
				// redirect to complete profile page
				this.router.navigate(['/complete-switch-profile']);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End

	// For Firebase 
	updateOnFirebase(userData) {
		let userDataStore = {
			'user_id': userData.user_detail.userID,
			'full_name': userData.user_detail.full_name,
			'email': userData.user_detail.email,
			'user_type': userData.user_detail.user_type
		}

		this.firebaseService.userUpdateFB(userData.user_detail.userID, userDataStore)
	}
	// End

	// For category model Open
	menuOpen() {
		let body = document.getElementsByTagName('body')[0];
		let withcalc = document.getElementById("menuMobile");
		let menuOverlay = document.getElementById("menuOverlay");
		body.classList.add("overflow");
		withcalc.classList.add("withcalc");
		menuOverlay.classList.add("overlay-show");
	}
	// End

	//For Category model close
	menuClose() {
		let body = document.getElementsByTagName('body')[0];
		let withcalc = document.getElementById("menuMobile");
		let menuOverlay = document.getElementById("menuOverlay");
		body.classList.remove("overflow");
		withcalc.classList.remove("withcalc");
		menuOverlay.classList.remove("overlay-show");
	}
	// End

	//on desktop - differentiate between a user trying to hover over a dropdown item vs trying to navigate into a submenu's contents
	openSubMenu() {
		var submenuDirection = (!$('.cd-dropdown-wrapper').hasClass('open-to-left')) ? 'right' : 'left';
		$('.cd-dropdown-content').menuAim({
			activate: function (row) {
				$(row).children().addClass('is-active').removeClass('fade-out');
				if ($('.cd-dropdown-content .fade-in').length == 0) $(row).children('ul').addClass('fade-in');
			},
			deactivate: function (row) {
				$(row).children().removeClass('is-active');
				if ($('li.has-children:hover').length == 0 || $('li.has-children:hover').is($(row))) {
					$('.cd-dropdown-content').find('.fade-in').removeClass('fade-in');
					$(row).children('ul').addClass('fade-out')
				}
			},
			exitMenu: function () {
				$('.cd-dropdown-content').find('.is-active').removeClass('is-active');
				return true;
			},
			submenuDirection: submenuDirection,
		});
	}
	// End

	//to open third level category on click of second level category
	thirdLevelMenuOpen(event) {
		var selected = $(event);
		selected.parent().parent('ul').addClass('move-out');
		selected.next('ul').removeClass('is-hidden');
	}
	// End

	//submenu items - go back link
	goBack(event) {
		var selected = $(event);
		selected.parent().parent('ul').addClass('is-hidden').parent('.has-children').parent('ul').removeClass('move-out');
	}
	// End

	//For Select language from header dropdown
	selectLanguage(languagecode, image, language) {
		this.selectedLanguage = language;
		this.selectedLanguageImage = image;
		this.selectedLanguageCode = languagecode;
		let JSONDatas =
		{
			"langauge": this.selectedLanguage,
			"image": this.selectedLanguageImage,
			"code": this.selectedLanguageCode
		};

		if (this.authService.isLoggedIn() == true) {
			const formData = new FormData();
			formData.append('language', this.selectedLanguageCode);
			this.authService.changeLanguage(formData).subscribe(data => {

				if (data.status == 'success') {

					let userDetail = this.authService.getFullUserDetails();
					userDetail.user_detail.device_language = data.data.device_language;
					userDetail.user_detail.user_language = data.data.device_language;


					localStorage.setItem(environment.dataStore.userData, JSON.stringify(userDetail));

					localStorage.setItem(environment.dataStore.language, JSON.stringify(JSONDatas));
					window.location.reload();
				}

			});
		} else {
			localStorage.setItem(environment.dataStore.language, JSON.stringify(JSONDatas));
			window.location.reload();
		}
	}
	// End

	// For category filter
	SelectPrtyListOpn() {
		// this.router.navigate(['/shop/category']);
		$('nav#categoryOpen').addClass('dropdown-is-active');
	}
	// End

	//For remove class (dropdown-is-active) from dropdown
	categoryDropdownClose() {
		$('nav#categoryOpen').removeClass('dropdown-is-active');
	}
	// End function

	// For get province list 
	getProvinceList() {
		this.authService.getProvinceList()
			.subscribe(provinceData => {
				this.commonService.hideLoader();

				if (provinceData.status == 'success') {
					this.provinceList = provinceData.data.province;
				} else {
					this.provinceList = [];
				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}
	// get provice id on change province 
	onProvinceChange(event: Event): void {
		this.provinceForm.get('municipalities').setValue('');
		const selectedProvinceID = (event.target as HTMLSelectElement).value;
		this.getMunicipalities(selectedProvinceID);

	}

	provinceModelReset(){
		
			this.BuyerProvince = this.authService.getUserDetails().province_name
			console.log('this.BuyerProvince', this.BuyerProvince);
			this.BuyerMunicipalities = this.authService.getUserDetails().municipalities_name;
			var selectedProvinceID = this.authService.getUserDetails().province;
			this.getProvinceList();
			this.getMunicipalities(selectedProvinceID);
			$('#locationSelect').modal('hide'); // Hide the modal by ID
		}
	// For get municipalities list 
	getMunicipalities(province_ids) {
		this.authService.getMunicipalities(province_ids).subscribe(municipalitiesData => {
			this.commonService.hideLoader();
			if (municipalitiesData.status == 'success') {
				this.municipalitiesDataList = municipalitiesData.data.municipalities;
				console.log('municipalitiesDataList', this.municipalitiesDataList);
			} else {
				this.municipalitiesDataList = [];
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}





	updateProvince() {
		var id = $('#getProvinceValue').val();
		var municipality_id = $('#getmunicipalitiesValue').val();

		if(municipality_id == null){
			return false;
		}

		const formData = new FormData();
		formData.append('province_id', id);
		formData.append('municipality_id', municipality_id);

		// formData.forEach((value,key) => {
		// 	console.log(key + " " + value)
		// });

		this.commonService.showLoader();

		this.authService.province_update(formData).subscribe(data => {
			$('#locationSelect').modal('hide');
			let userDetail = this.authService.getFullUserDetails();
			userDetail.user_detail.province = data.data.provinceID;
			userDetail.user_detail.province_name = data.data.name;
			userDetail.user_detail.municipalities = data.data.municipalityID;
			userDetail.user_detail.municipalities_name = data.data.municipalities;

			localStorage.setItem(environment.dataStore.userData, JSON.stringify(userDetail));
			let userDetails = this.authService.getFullUserDetails();
			$('#locationSelect').modal('hide');
			this.commonService.hideLoader();

			setTimeout(() => {
				window.location.reload();
			}, 250);



		})

	}
	// End

}