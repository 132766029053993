import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SellerService } from '../services/seller.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rejected-order-details',
  templateUrl: './rejected-order-details.component.html',
  styleUrls: ['./rejected-order-details.component.css']
})



export class RejectedOrderDetailsComponent implements OnInit {
  orderId: any;
  // orderDetails: any;
  orderDetails: any = {};
	orderAddress: any = {};
  variantslist: any;
  feature_image: any;
  feature_image_url: any;
  apply_coupon_amount: any;
  processing_fee: any;
  tax_detailPercent: any;
  influencer_code_percentage: any;
  influencer_code_amount: any;
  assign_coupon_amount: any;
  assign_coupon_percentage: any;
  apply_coupon_percentage: any;
  coupon: any;
  coupon2: any;
  trackingStatus: any = {};
  responseCheck : any = 0;
	addrestype: any;

	pickup = 0;
	house: any

  siteUrl = environment.siteUrl;

	trackImages: any = [
		{ icon: this.siteUrl + '/assets/img/common/ordered.png', orderStatus: this.translate.instant('SELLER_SIDE.order_placed') },
		{ icon: this.siteUrl + '/assets/img/common/placed.png', orderStatus: this.translate.instant('COMMON.approved_status') },
		{ icon: this.siteUrl + '/assets/img/common/packed.png', orderStatus: this.translate.instant('SELLER_SIDE.order_packed') },
		{ icon: this.siteUrl + '/assets/img/common/shipped.png', orderStatus: this.translate.instant('SELLER_SIDE.order_shipped') },
		{ icon: this.siteUrl + '/assets/img/common/zone_icon.png', orderStatus: this.translate.instant('SELLER_SIDE.order_zone') },
		{ icon: this.siteUrl + '/assets/img/common/delivered.png', orderStatus: this.translate.instant('SELLER_SIDE.order_delivered') }
	]

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private sellerService: SellerService,
    private translate: TranslateService
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.orderId = params.get("orderID"); //For order id
      this.getRejectedOrderDetail(this.orderId); //For rejected order detail
    });
  }



  

  // Function for rejected order detail
  getRejectedOrderDetail(orderId) {
    this.commonService.showLoader();

    this.sellerService.rejectedOrderDetail(orderId).subscribe(res => {
      console.log('res', res);
      this.responseCheck = 1;
      this.commonService.hideLoader();
      this.orderDetails = res.data.order_detail;
      this.orderAddress = res.data.order_detail.order_seller_address;

      // if (res.data.order_detail.order_address == null) {
      //   this.addrestype = 1;
      //   this.orderAddress = res.data.order_detail.order_seller_address;
      //   this.house = "";

      // } else {
      //   this.addrestype = 0;

      //   this.orderAddress = res.data.order_detail.order_address;
      //   this.house = this.orderAddress.house_number + ', ';

      // }


      if (res.data.order_detail.order_shipping_type == 1) {
        this.addrestype = 1;
        // this.orderAddress = myOrderData.data.order_detail.order_seller_address;
        // this.house = "";
        this.orderAddress = res.data.order_detail.order_address;
        this.house = this.orderAddress.house_number + ', ';

      } else {
        this.addrestype = 0;
        this.orderAddress = res.data.order_detail.order_address;
        this.house = this.orderAddress.house_number + ', ';

      }
      

      this.trackingStatus = this.orderDetails.tracking_status;

      if (this.orderDetails.assign_coupon_percentage != '0.00' || this.orderDetails.assign_coupon_percentage != '0') {
        this.coupon = this.orderDetails.assign_coupon_percentage.replace(/\.00$/, '');
      }

      if (this.orderDetails.apply_coupon_percentage != '0.00' || this.orderDetails.apply_coupon_percentage != '0') {

        this.coupon2 = this.orderDetails.apply_coupon_percentage.replace(/\.00$/, '');
      }
      this.apply_coupon_amount = this.orderDetails.apply_coupon_amount;
      this.apply_coupon_percentage = this.orderDetails.apply_coupon_percentage;

      this.assign_coupon_amount = this.orderDetails.assign_coupon_amount;
      this.assign_coupon_percentage = this.orderDetails.assign_coupon_percentage;

      this.influencer_code_amount = this.orderDetails.influencer_code_amount;
      this.influencer_code_percentage = this.orderDetails.influencer_code_percentage;

      this.influencer_code_percentage = this.influencer_code_percentage.replace(/\.00$/, '');

      this.tax_detailPercent = this.orderDetails.tax_percent;
      this.processing_fee = this.orderDetails.transaction_fee_percent_amount * 1 + this.orderDetails.transaction_fee_flat * 1;
      this.processing_fee = this.processing_fee.toFixed(2);
      this.orderDetails.products.forEach(element => {
        this.feature_image = element.feature_image;
        this.feature_image_url = element.feature_image_url;
      });

    }, err => {
      this.responseCheck = 0;
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }


  	// Function for order tracking status manage
	ngAfterViewChecked(): void {

		this.trackImages = [
			{ icon: this.siteUrl + '/assets/img/common/ordered.png', orderStatus: this.translate.instant('SELLER_SIDE.order_placed') },
			{ icon: this.siteUrl + '/assets/img/common/placed.png', orderStatus: this.translate.instant('COMMON.approved_status') },
			{ icon: this.siteUrl + '/assets/img/common/packed.png', orderStatus: this.translate.instant('SELLER_SIDE.order_packed') },
			{ icon: this.siteUrl + '/assets/img/common/shipped.png', orderStatus: this.translate.instant('SELLER_SIDE.order_shipped') },
			{ icon: this.siteUrl + '/assets/img/common/zone_icon.png', orderStatus: this.translate.instant('SELLER_SIDE.order_zone') },
			{ icon: this.siteUrl + '/assets/img/common/delivered.png', orderStatus: this.translate.instant('SELLER_SIDE.order_delivered') }

		]


		if ((this.orderDetails.current_status_number == '7' || this.orderDetails.current_status_number == '8' || this.orderDetails.current_status_number == '9' || this.orderDetails.current_status_number == '10' || this.orderDetails.current_status_number == '11')) {
			this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.return_initiated') });
			this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.return_inprocess') });
			this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.collect_by_store') });
			this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.received_by_seller') });
			this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/refund.png', orderStatus: this.translate.instant('SELLER_SIDE.return_and_refunded') });
		}
	}


  // For order time
	getOrderTimeShow(dateTime, string) {

		return this.commonService.getOrderTime(dateTime, string);
	}

	// For order time 
	getOrdeDate(dateTime, string) {

		return this.commonService.getOrderDate(dateTime, string);
	}
  // End

}
