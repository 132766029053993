//Preloader hide show
var showLoader = function () {
    $('.loaderBlock').show();
}//End

var hideLoader = function () {
    $('.loaderBlock').hide();
}//End

// Custom toaster message modal
var ShowPopup = function (id) {
    $('#popmodel').modal('show');
    $("#sucessPopup").html(id);
} //End

var errorShowPopup = function (id) {
    $('#errorpopmodel').modal('show');
    $("#errorPopup").html(id);

} //End

var selectionErrorShowPopup = function (id) {
    $('#selectionRequiredModel').modal('show');
    $("#slectionErrorPopup").html(id);

} //End

var warningShowPopup = function (id) {
    $('#warningpopmodel').modal('show');
    $("#warningPopup").html(id);

} //End


var alertShowPopup = function (id) {
    $('#alertPopup').modal('show');
    $("#alertPopupid").html(id);

} //End


var alerthidePopup = function (id) {
    $('#alertPopup').modal('hide');
    $("#alertPopupid").html('');

} //End

var hidePopup = function () {
    $('#popmodel').modal('hide');
    $("#sucessPopup").html('');

}
var errorHidePopup = function () {
    $('#errorpopmodel').modal('hide');
    $("#errorPopup").html('');
}

var selectonReqHidePopup = function () {
    $('#selectionRequiredModel').modal('hide');
    $("#slectionErrorPopup").html('');
}


var warningHidePopup = function () {
    $('#warningpopmodel').modal('hide');
    $("#warningPopup").html('');
}

// End 

var setValue = function (key, value) {

    if ($('#' + key).length == 0) {
        $('<input>').attr({ type: 'hidden', id: key, name: key, value: value }).appendTo('head');
    } else {
        $("#" + key).val(value);
    }
    return true;
}

var getValue = function (key) {
    return $("#" + key).val();
}

var getTimeZone = function () {
    return moment.tz.guess();
}

var getTime = function () {
    return moment().valueOf();
}

function checkImageSize(input, cb) {

    if (input.files && input.files[0]) {

        var reader = new FileReader();
        reader.onload = (e) => {

            var newImage = new Image();
            newImage.addEventListener("load", function () {
                if (this.naturalWidth >= 252 && this.naturalHeight >= 315) {
                    cb(true);
                } else {
                    cb(false);
                }
            });
            newImage.src = e.target.result;

        }
        reader.readAsDataURL(input.files[0]);
    }
}

function checkLicenseImageSize(input, cb) {
    if (input.files && input.files[0]) {

        var reader = new FileReader();
        reader.onload = (e) => {

            var newImage = new Image();
            newImage.addEventListener("load", function () {
                if (this.naturalWidth >= 252 && this.naturalHeight >= 252) {
                    cb(true);
                } else {
                    cb(false);
                }
            });
            newImage.src = e.target.result;

        }
        reader.readAsDataURL(input.files[0]);
    }
}

var scrollDown = function () {
    setTimeout(function () {
        $('.messageBox').animate({ scrollTop: $('.messageBox').prop("scrollHeight") }, 0);
    }, 1);
}

// to connect firebase & set user's online/ofline status 
function checkOnline(firebase, onlineId) {
    if (onlineId != '') {
        //create note		
        var userRef = firebase.ref('users/' + onlineId);
        //initialize connection string
        var connectedRef = firebase.ref('.info/connected');
        //call function
        connectedRef.on('value', function (snap) {
            if (snap.val()) {
                //set offline if user disconnect
                // userRef.onDisconnect().update({"online":""});
                //userRef.set('online');
                // userRef.update({"online":"Online"});
            }
        });
    }
}


function notifyMe(notifyData) {

    var url = notifyData.siteUrl + "/my-order-detail/" + notifyData.reference_id;
    // if(notifyData.type == "Order apporved" && url == notifyData.siteUrl +"/my-order-detail/" + notifyData.reference_id){
    //     return false;
    // }
    var options = {
        body: notifyData.body,
        icon: notifyData.icon,
    };

    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        var notification = new Notification(notifyData.title, options);
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                notification = new Notification(notifyData.title, options);
            }
        });
    }

    notification.onclick = async function (event) {
        var formData = new FormData();
        formData.append('notification_id', notifyData.notificationID);

        base_url = "https://api.mercadoba.com/api/v1/notification/read-notification";

        if (window.location.hostname == 'mercadoba.com') {
            base_url = "https://api.mercadoba.com/api/v1/notification/read-notification";
        } else {
            base_url = "https://api.mercadoba.com/api/v1/notification/read-notification";
        }


        await $.ajax({
            type: "POST",

            url: base_url,
            headers: {
                "authorization": `Bearer ${notifyData.token}`,
                "device-language": notifyData.lang,
                'device-id': notifyData.device_id,
                'device-type': '3',
                'device-timezone': getTimeZone(),
            },



            data: formData, //only input
            processData: false,
            contentType: false,
            dataType: "JSON",
            beforeSend: function () {
                // show_loader();
            },
            complete: function () {
                // hide_loader();
            },
            success: function (data, textStatus, jqXHR) {


            },
            error: function (jqXHR, textStatus, errorThrown) {
                return false

            }
        })
        // return false;
        event.preventDefault(); // prevent the browser from focusing the Notification's tab
        if (notifyData.userType == 'buyer') {
            window.location.href = notifyData.siteUrl + '/my-order-detail/' + notifyData.reference_id;
            if (notifyData.type == 'Offer accepted' || notifyData.type == 'Offer Counter' || notifyData.type == 'Make an Offer') {
                window.location.href = notifyData.siteUrl + '/product-offers-details/1/1/' + notifyData.reference_id;
            }

        } else {
            if (notifyData.type == 'Admin') {
                $('#adminNotification').modal('show');
            }

            if (notifyData.type == 'Order Placed') {
                window.location.href = notifyData.siteUrl + '/seller/new-order-detail/' + notifyData.reference_id;
            }

            else if (notifyData.type == 'Order Return Request') {
                window.location.href = notifyData.siteUrl + '/seller/my-order-detail/' + notifyData.reference_id;
            }

            else if (notifyData.type == 'Order Delivered') {
                window.location.href = notifyData.siteUrl + '/seller/my-order-detail/' + notifyData.reference_id;
            }
            else if (notifyData.type == 'Order Picked up Buyer') {
                window.location.href = notifyData.siteUrl + '/seller/my-order-detail/' + notifyData.reference_id;
            }
            else if (notifyData.type == 'Order Refunded' || notifyData.type == "Refund") {
                window.location.href = notifyData.siteUrl + '/seller/my-order-detail/' + notifyData.reference_id;
            }

            else if (notifyData.type == 'Order Ready to Pickup' || notifyData.type == "Order Picked up") {
                window.location.href = notifyData.siteUrl + '/seller/my-order-detail/' + notifyData.reference_id;
            }


            else if (notifyData.type == 'Dispute Created' || notifyData.type == 'Dispute Closed') {
                window.location.href = notifyData.siteUrl + '/seller/my-order-detail/' + notifyData.reference_id;
            } else if (notifyData.type == 'Offer accepted' || notifyData.type == 'Offer Counter' || notifyData.type == 'Make an Offer' || notifyData.type == 'offer rejected') {
                window.location.href = notifyData.siteUrl + '/seller/product-offers-details/1/1/' + notifyData.reference_id;


            }

            else if (notifyData.type == 'Commision') {
                window.location.href = notifyData.siteUrl + '/seller/seller-account';
            } else if (notifyData.type == 'Seller Review') {
                window.location.href = notifyData.siteUrl + '/seller/seller-review/' + notifyData.reference_id;
            }
            else if (notifyData.type == 'License Approved' || notifyData.type == 'License Disapproved') {
                window.location.href = notifyData.siteUrl + '/seller/seller-account';
            }

            else if (notifyData.type == 'product_approved') {
                window.location.href = notifyData.siteUrl + '/seller/my-product-detail/' + notifyData.reference_id;
            }

            else if (notifyData.type == 'withdraw_request_rejected' || notifyData.type == 'withdraw_request_accepted') {
                window.location.href = notifyData.siteUrl + '/seller/wallet-detail/' + notifyData.reference_id;

            }



        }


    }
    //Auto close notification after 30secs
    setTimeout(function () {
        notification.close();
    }, 30000);
}

// // to send notification for app side
// function sendNotification(key, to, notification, data) {

//     fetch('https://fcm.googleapis.com/fcm/send', {
//         'method': 'POST',
//         'headers': {
//             'Authorization': 'key=' + key,
//             'Content-Type': 'application/json'
//         },
//         'body': JSON.stringify({
//             to: to,                             // receiverId token
//             collapse_key: 'your_collapse_key',
//             delay_while_idle: false,
//             priority: "high",
//             content_available: true,
//             notification: notification,         // data dictionary      
//             data: data,                         // data dictionary   
//             badge: 1,
//             icon: 'icon',
//         })

//     }).then(function (response) {


//     }).catch(function (error) {

//     });
// }

function sendNotification(accessToken,deviceToken, notification, data) {
    const myJSON = JSON.stringify(notification);
    console.log('myJSON', myJSON);

      accessToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImNlMzcxNzMwZWY4NmViYTI5YTUyMTJkOWI5NmYzNjc1NTA0ZjYyYmMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vY3JhY2stYXVkaW8tNDMxMzAyLXE0IiwiYXVkIjoiY3JhY2stYXVkaW8tNDMxMzAyLXE0IiwiYXV0aF90aW1lIjoxNzIzNTU0MTUwLCJ1c2VyX2lkIjoiUmxwRW9JR0tFTWhvWm5jcTdncVRkQjRIa25qMiIsInN1YiI6IlJscEVvSUdLRU1ob1puY3E3Z3FUZEI0SGtuajIiLCJpYXQiOjE3MjM1NTQxNTAsImV4cCI6MTcyMzU1Nzc1MCwiZW1haWwiOiJuaWtoaWxAbWFpbGluYXRvci5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsibmlraGlsQG1haWxpbmF0b3IuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.WQr6gZb9k1ld5TKfqn8WMmM398PM1xJC-8tJzwjb7SBSns-fhHkqQrCzyIymTs-qrQ1d18LI9-ht-66AKtfrXjijGjfvFB_Yyc0R9uJykXFPipgJ9FdHEBlOn8Ji5dWZCqrvz02ofpFhB1KpYEThlr2ige6Q1nmsTcWK_Q1OjReWRFg4Afe-YWUzLh_fHC2gY9EPDYNNTXNKgP4JV03qUckqQvWi66gY7AARzEw7F4FaLvF2LESqSeY3R9ggywaaNtMhRi4TGYUid9dYtygR9-UwkB-pt49Om2IlLjJ18AiX_LvE9ZgWnd4EoF9UL5JjWajGAnnMFCt84sXKXu2G2A';


    fetch('https://fcm.googleapis.com/v1/projects/crack-audio-431302-q4/messages:send', {

        method: 'POST',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },




        body: JSON.stringify({
            message: {
                token: deviceToken, 
                notification: {
                    title: notification.title,
                    body: notification.title,
                    
                },

                data: {
                    title: notification.title,
                    body: notification.title,
                    buyerId: notification.buyerId,
                    click_action:notification.click_action,
                    message:notification.message,
                    reference_id :  notification.reference_id,
                    sellerId : notification.sellerId,
                    sender_name  :   notification.sender_name,
                    sound:   notification.sound,
                    type  : notification.chat,
                    // badge : ""
                }
            }
        })

    })

        .then(response => response.json())
        .then(data => console.log('Success:', data))
        .catch(error => console.error('Error:', error));
}


// function sendNotificationForMulti(accessToken, tokens, notification, data) {
//     var tokens = ['emSZz63wwEa7bdxW2uJv0u:APA91bGSoAlmg_ol3jd37TJOk3nEysQh87HrgvRUo0-TeGMm3WyuKShIXJvimd25ZudTMCb0u2tpPVTM6zLy44MFs0LfItixwDaocfjAXCvKCKfsqbT2TeTSSGmJrqpczrvYz0h3iHmd','cKnMR0sfSTmqZ0YsE1BzCH:APA91bHww3Xom--u8K-S5th4GW9cPvThvnyZIe5u9ll7lW32XER8csIAp4wsgvwfmAazFdavu6_oXZSiO9qZsAnxN__aLJwJVx1vpVjRLUKwTMkS3A0gc6IGgmx7Bn23fs0UbcnAz99Q','dEXZXldQAE2Gsea3_7R4X3:APA91bGW2tGbIVfLhfNQCtsKu5BOIsF1P8R5zGU8nkcfLLBHYuivVhzyHYPxNj9VY5qgrU-gN0TPrCBNnht5UW3adWTjj3P8_zzqCSI1dBFnRc4ypSMwdm8XQu3bpKGaJb9EcI6J3Ln0','cHaNCkk-TiexjK6HYIJ7mc:APA91bExZalGEvxgQtA06UnxefeU4JeMpiySfoPFUdaZCFPvCJBwgdzw0NgcoOXV4lI-73jM58CGl1aowLO4BpYsWHL26K3HbXoVrpYy3v2RIK30IupQfQKNU9RXAuOr1ngEFaizyUmX'];
//     // Convert the object to a JSON string and then parse it back
//     let jsonString = JSON.stringify(data);
//     var parsedData = JSON.parse(jsonString);
//     accessToken = 'ya29.c.c0ASRK0Gb4wuy3I29o5GuLbhCbwXGhPDPNUe-oTKRbfnZ3Ck5q7HyRd0vI2Ns23oOi-U313UCfz9vNCoSzZz7TrD43xrGxlvP_4VuDntWgOg0FuzQ3K53SfzVXVa1Ahb-FZzhUNnPwJvZVDut9A7nSLxeVf7TjNuxc91t77ZssxdCEdqmC1MlHrJx_AkxnTmWOT61ijPmr9iz3AW7d__n7qdzTvSlJ6DZ1cWpTiqqcDH-o-_rBsXoKJ5df9B0vnb089Q9LyDHyJEaI01uwQnanegjVP5T0-j71OwJuQvD66-h-0tWZNMnDeBHyO7LavuMdjoHkXxYT4Im7T-ieKg1PY2RHZfxmShKHU5L1R5ijUWl_Ad9o95czE54c7wH387Anw1r6a6Qds-3xdJBUmRxwo3VmvMqZVVJF3cItgJ_jQXb6sXbgwMikkOajsqzI_IrR9wuZB-qF_BUmyWyjcI7s8sqvncyhcv-ROVuB8ipF8nhBviz9lF5sVswuodfem7dURmZ_WlOdcF1wfVZ0kuU9jXo2yeOV6leedouBqF7Ro6SM0e5fF0--zfkr8QcOtmcqBiI7XgB5VrebchBnVRi_UnbMz6XmqF265Rw6i3St3oFmeMsO8lBcW2cuFWlMaJX5OY6wu_FmvScremWMbrXQm1kk3QUJaMj48Inc5tU0paIhtlmXSBf4nwbzz59wiF18bJVgzIIm_ipFUs2a8z3fBZ6VSqU0_s2gqdml9o7a-2r19lFuoUcr9ail-kuiI_Z0ZlaX0uBbngMydreIX4XuMvcs8yrj2Qr9b_u5vg8i9Ihx3_1k8bMm-xZF-RXjaFBjIWwgoYZbq7kZU4_4ihhchux4szh4Xj6QdwpvmtUZfgeQBZcmIxepW0I6lVlYQzmWxnng_Oad30oaUnMe_XYb5kpbJusxin8WuRe0OxOJxq5v97g8JfIrp56Vgx5qcpdYRFVM0MU34ddntS9YlapY0ZVdli3ejswUMiB0qyB99u6Zjhgjd_qqU3s';
//     console.log('Parsed Data:', parsedData);

//     var title = notification.title;
//     var body = notification.body;

//     // Create an array of fetch requests
//     let requests = tokens.map(token => {
//         return fetch('https://fcm.googleapis.com/v1/projects/crack-audio-431302-q4/messages:send', {
//             method: 'POST',
//             headers: {
//                 'Authorization': `Bearer ${accessToken}`,
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({
//                 message: {
//                     token: token, // Single token for each request
//                     notification: {
//                         title: title,
//                         body: body
//                     },
//                     data: {
//                         title: notification.title,
//                         body: notification.title,
//                         buyerId: notification.buyerId,
//                         click_action:notification.click_action,
//                         message:notification.message,
//                         reference_id :  notification.reference_id,
//                         sellerId : notification.sellerId,
//                         sender_name  :   notification.sender_name,
//                         sound:   notification.sound,
//                         type  : notification.chat,
//                         // badge : ""
//                     }
//                 }
//             })
//         });
//     });

//     // Execute all fetch requests in parallel
//     Promise.all(requests)
//         .then(responses => Promise.all(responses.map(response => response.json())))
//         .then(data => {
//             console.log('Success:', data);
//             // Handle success
//         })
//         .catch(error => {
//             console.error('Error:', error);
//             // Handle errors
//         });
// }



function getWebNotification(firebase, notifiyData) {
    let senderUserId = getValue('senderUserId');
    firebase.ref("web_notification").child(senderUserId).on('value', function (snapshot) {
        var value = snapshot.val();
        $.each(value, function (key, value) {
            let chatNode = getValue('chatNode');
            let chatType = (value.click_action == 'product') ? 'P' : 'O';
            let notificationNode = `${value.sellerId}-${value.buyerId}-${value.reference_id}-${chatType}`;

            if (chatNode == notificationNode) {
                firebase.ref("web_notification").child(senderUserId).child(key).set(null);
            } else {
                showNotification(value, notifiyData);
                firebase.ref("web_notification").child(senderUserId).child(key).set(null);
            }

        });
    });
}

Notification.requestPermission();

function showNotification(data, notifiyData) {
    var options = {
        icon: notifiyData.icon,
        body: data.body,
    }



    if (notifiyData.siteUrl == undefined && data.click_action == 'order') {
        return false;
    }


    if (notifiyData.siteUrl == undefined) {
        notifiyData.siteUrl = "";
    }




    var notification = new Notification(data.title, options);
    notification.onclick = function (event) {

        console.log('hello');

        event.preventDefault(); // prevent the browser from focusing the Notification's tab
        if (data.click_action == 'Admin') {
            $('#adminNotification').modal('show');
        }

        if (notifiyData.userType == 'buyer') {

            if (data.click_action == 'product') {
                window.location.href = notifiyData.siteUrl + '/chat/product/' + data.reference_id;
            }


            else if (data.click_action == 'order') {
                window.location.href = notifiyData.siteUrl + '/chat/order/' + data.reference_id;
            }

            else if (data.click_action == 'Dispute') {
                // return false;
                localStorage.setItem("showDisputeModal", '1');

                window.location.href = notifiyData.siteUrl + '/my-order-detail/' + data.reference_id;
            }

        } else {

            if (data.click_action == 'product') {
                window.location.href = notifiyData.siteUrl + '/seller/chat/product/' + data.reference_id + '/' + data.buyerId;
            }

            else if (data.click_action == 'Dispute') {
                // return false;
                localStorage.setItem("showDisputeModal", '1');

                window.location.href = notifiyData.siteUrl + '/seller/my-order-detail/' + data.reference_id;

            }

            else if (data.click_action == 'order') {

                window.location.href = notifiyData.siteUrl + '/seller/chat/order/' + data.reference_id + '/' + data.buyerId;
            }


        }
    }
    setTimeout(notification.close.bind(notification), 8000);
}//End push notification

function setBadgeCount(firebase, myId) {
    firebase.ref("badge_count").child(myId).child('count').set(0);
}

// Google pay payment save intent success code
var saveIntent = async function (token, lang, coupon_code_id, intent, address, count, device_id, type, pay_mode, shipping_charges, is_pickup, is_offer, grand_total, total_amount) {
    var formData = new FormData();
    formData.append('shipping_address_id', address);
    formData.append('coupon_code', coupon_code_id);
    formData.append('payment_intent', intent);
    formData.append('payment_mode', pay_mode);
    formData.append('payment_type', type);
    formData.append('shipping_charges', shipping_charges);
    formData.append('total_amount', total_amount);
    formData.append('subtotal_amount', grand_total);
    formData.append('is_pickup', is_pickup);
    formData.append('is_offer', is_offer);

    var base_url = "https://api.mercadoba.com/api/v3/payment_new";
    if (window.location.hostname == 'mercadoba.com') {
        base_url = "https://api.mercadoba.com/api/v3/payment_new";
    } else {
        base_url = "https://api.mercadoba.com/api/v3/payment_new";
    }

    await $.ajax({
        type: "POST",

        url: base_url,
        headers: {
            "authorization": `Bearer ${token}`,
            "device-language": lang,
            'device-id': device_id,
            'device-type': '3',
            'device-timezone': getTimeZone(),
        },


        data: formData, //only input
        processData: false,
        contentType: false,
        dataType: "JSON",
        beforeSend: function () {
            // show_loader();
        },
        complete: function () {
            // hide_loader();
        },
        success: function (data, textStatus, jqXHR) {

            if (data.status == "success") {
                // window.location.href = `https://${window.location.hostname}/confirmation`;
                window.location.href = `https://${window.location.hostname}/confirmation/${JSON.stringify(data.data.order_detail)}`;

            } else {
                errorShowPopup(data.message);
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            return false

        }
    })
}

// End Google pay intent code