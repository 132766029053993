import { Component, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { faStar, faAngleDoubleRight, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { faCommentDots, faStar as faStarBlank } from '@fortawesome/free-regular-svg-icons';
import { BuyerService } from '../services/buyer.service';
import { CommonService } from '../../services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import { FirebaseService } from '../services/firebase.service';
import { AuthService } from '../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { AngularFireStorage } from '@angular/fire/storage';
import Swal from 'sweetalert2';
import { SellerService } from 'src/app/seller/services/seller.service';


declare const $: any;
declare const scrollDown: any;


@Component({
	selector: 'app-my-order-detail',
	templateUrl: './my-order-detail.component.html',
	styleUrls: ['./my-order-detail.component.css']
})
export class MyOrderDetailComponent implements OnInit, AfterViewChecked {
	faAngleDoubleRight = faAngleDoubleRight;
	faStar = faStar;
	faStarBlank = faStarBlank;
	faStarHalfAlt = faStarHalfAlt;
	faCommentDots = faCommentDots;
	orderId: any;
	orderDetail: any = {};
	orderAddress: any = {};
	trackingStatus: any = {};
	currentRate: any = 0;
	sellerRateByBuyer: any;
	description: any = '';
	ctrl = new FormControl(null, Validators.required);
	disputeList: any = [];
	disputeData: any = {
		title: "",
		description: "",
		mediaType: '0',
		image: "",
		video: "",
		videoThumb: "",
		closed: 0,
	};
	siteUrl = environment.siteUrl;
	pricing_detail: any = {};
	tax_detail: any = {};
	shippingAddress: any = {};
	isOffer: boolean;
	addressList: any = [];
	addresslistCount: any;
	addressSelected: string = '';
	dial_code: string = '+53';
	dial_code_address: string = '+53';
	country: string = 'Cuba';
	country_code: string = 'cu';
	phone_num: any;
	referralDetail: any;
	rdbValue = "delivery";
	coupon: any;
	couponList: any = [];
	shopService: any;
	coupon_code_id: string;
	coupon_disabled: boolean = true;
	apply_coupon_percentage: any;
	pickup = 0;
	addrestype: any;
	Total: any = '';
	itemCount: string = '';
	subtotal: any = '';
	total_shipping_price: any = '';
	tax_percent: any = '';
	tax_amount: any = '';
	discounted_amount: any = '';
	discount_percent: any = '';
	subtotal_before_discount: any = '';
	free_shipping: string;
	currentUserId: any;
	cartlistCount: any;
	influencer_code_percentage: any;
	influencer_code_amount: any;
	apply_coupon_amount: any;
	assign_coupon_amount: any;
	assign_coupon_percentage: any;
	show_ref_pop: any;
	processing_fee: any;
	coupon2: any;
	is_used: any;
	influencer_code: any;
	tax_detailPercent: any;
	// For order tracking status
	
	trackImages: any = [
		{ icon: this.siteUrl + '/assets/img/common/ordered.png', orderStatus: this.translate.instant('SELLER_SIDE.order_placed') },
		{ icon: this.siteUrl + '/assets/img/common/placed.png', orderStatus: this.translate.instant('COMMON.approved_status') },
		{ icon: this.siteUrl + '/assets/img/common/packed.png', orderStatus: this.translate.instant('SELLER_SIDE.order_packed') },
		{ icon: this.siteUrl + '/assets/img/common/shipped.png', orderStatus: this.translate.instant('SELLER_SIDE.order_shipped') },
		{ icon: this.siteUrl + '/assets/img/common/zone_icon.png', orderStatus: this.translate.instant('SELLER_SIDE.order_zone') },
		{ icon: this.siteUrl + '/assets/img/common/delivered.png', orderStatus: this.translate.instant('SELLER_SIDE.order_delivered') }
	]
	// End
	house: string;
	signatureImage: any;

	@ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;

	public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
		'minWidth': 2,
		'canvasWidth': 350,
		'canvasHeight': 200,
		'backgroundColor': '#00000000'
	};
	disputeNode: any;
	disputeSubscription: any;
	disputeSubscription1: any;
	updateFirebase: any
	// DisputeFBData: any;
	DisputeFBData: any = {
		video_url: "",
		closed: 0,
		dispute_by: 0,
	};
	disputeChatSubscription: any;
	chatList: any = [];
	imagePreview: any = "";
	message: any = "";
	showDisputeModal: string;
	responseCheck: any = 0;
	deliverdImage: any;

	constructor(
		private activatedRoute: ActivatedRoute,
		private commonService: CommonService,
		private buyerService: BuyerService,
		private router: Router,
		private config: NgbRatingConfig,
		private firebaseService: FirebaseService,
		private authService: AuthService,
		private translate: TranslateService,
		private storage: AngularFireStorage,
		private sellerService: SellerService,

	) {
		config.max = 5;
		translate.use(this.commonService.getLanguage());
	}

	ngOnInit() {
		this.commonService.scrollToTop();
		this.currentUserId = this.authService.getCurrentUserId(); //For current user id

		this.commonService.setTitle('Mercadoba | Order Details'); //For set title

		// For get order data from url param 
		this.activatedRoute.paramMap.subscribe(params => {
			this.orderId = params.get("id");
			this.getMyOrderDetail(this.orderId);
			// this.getDisputeChat();
			this.getDisputeList();
		});
		// End

		this.showDisputeModal = localStorage.getItem("showDisputeModal");

		localStorage.setItem("showDisputeModal", '0');


		if(this.showDisputeModal == '1'){
			// scrollDown();

			$('#dispute').modal('show');

		}else{
			$('#dispute').modal('hide');

		}

		// this.orderId = this.activatedRoute.snapshot.paramMap.get("id");
		// this.getMyOrderDetail(this.orderId);
	}

	// For order tracking status manage
	ngAfterViewChecked(): void {
		this.trackImages = [
			{ icon: this.siteUrl + '/assets/img/common/ordered.png', orderStatus: this.translate.instant('SELLER_SIDE.order_placed') },
			{ icon: this.siteUrl + '/assets/img/common/placed.png', orderStatus: this.translate.instant('COMMON.approved_status') },
			{ icon: this.siteUrl + '/assets/img/common/packed.png', orderStatus: this.translate.instant('SELLER_SIDE.order_packed') },
			{ icon: this.siteUrl + '/assets/img/common/shipped.png', orderStatus: this.translate.instant('SELLER_SIDE.order_shipped') },
			{ icon: this.siteUrl + '/assets/img/common/zone_icon.png', orderStatus: this.translate.instant('SELLER_SIDE.order_zone') },
			{ icon: this.siteUrl + '/assets/img/common/delivered.png', orderStatus: this.translate.instant('SELLER_SIDE.order_delivered') }
		]

		if ((this.orderDetail.current_status_number == '7' || this.orderDetail.current_status_number == '8' || this.orderDetail.current_status_number == '9' || this.orderDetail.current_status_number == '10' || this.orderDetail.current_status_number == '11')) {
			this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.return_initiated') });
			this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.return_inprocess') });
			this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.collect_by_store') });
			this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.received_by_seller') });
			this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/refund.png', orderStatus: this.translate.instant('SELLER_SIDE.return_and_refunded') });
		}
	}
	// End



	// For dispute notification count for admin 
	disputeCountForAdmin() {
		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('order_id', this.orderDetail.orderID);

		this.buyerService.adminDisputeisRead(formData).subscribe((data) => {
			this.commonService.hideLoader();
		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End


	// Function for dispute chat msg
	sendMsg() {
		const el = $('#myDIV-3');

		// ✅ Using optional chaining
		// const result1 = el?.offset()?.top;
		
		if (this.message.trim()) {

			let disputeArray = this.disputeNode.split('-');

			let chatMessage = {
				buyer_id: disputeArray[2],
				image: 0,
				image_url: "",
				last_sender_id: disputeArray[2],
				message: this.message.trim(),
				msg_read_tick: 0,
				order_id: disputeArray[0],
				seller_id: disputeArray[1],
				timestamp: Date.now()
			}
			

			this.firebaseService.sendDisputeMessage(this.disputeNode, chatMessage, this.orderDetail);



			$('#myDIV-3').animate({
				scrollTop: $("#myDIV-3").offset().top + $('#myDIV-3').prop("scrollHeight")
			});

			this.disputeCountForAdmin();

		}

		this.message = "";
	}
	// End

	// For image upload 
	onSelectedFile1(event) {
		if (event.target.files.length > 0) {
			this.commonService.showLoader();

			const file = event.target.files[0];
			var ext = file.name.split('.').pop().toLowerCase();

			if ($.inArray(ext, ['gif', 'png', 'jpg', 'jpeg']) == -1) {
				this.commonService.hideLoader();
				Swal.fire(this.translate.instant('MYORDER.ops_txt'), this.translate.instant('MYORDER.invalid_extension'), this.translate.instant('MYORDER.error_txt'));
				return false;
			}
			const filePath = environment.storageBucketFile + Date.now();
			const fileRef = this.storage.ref(filePath);

			const task = this.storage.upload(filePath, file).then(() => {
				const ref = this.storage.ref(filePath);
				const downloadURL = ref.getDownloadURL().subscribe(url => {
					this.commonService.hideLoader();

					let disputeArray = this.disputeNode.split('-');

					let chatMessage = {
						buyer_id: disputeArray[2],
						image: 1,
						image_url: url,
						last_sender_id: disputeArray[2],
						message: "",
						msg_read_tick: 0,
						order_id: disputeArray[0],
						seller_id: disputeArray[1],
						timestamp: Date.now()
					}

					this.firebaseService.sendDisputeMessage(this.disputeNode, chatMessage, this.orderDetail, url);

					$('#myDIV-3').animate({
						scrollTop: $("#myDIV-3").offset().top + $('#myDIV-3').prop("scrollHeight")
					});
					this.disputeCountForAdmin();


				});
			});
		}
	}
	// End

	ngOnDestroy(): void {
		this.disputeSubscription.unsubscribe();

		$('#trackModal').modal('hide');
		$('#giveReview').modal('hide');
		$('#imgFullViewModal').modal('hide');
		$('#disputeModal').modal('hide');



		Swal.close();
			

		// this.disputeSubscription1.unsubscribe();
	}

	// Function for give rating
	createRatingRange(number) {
		return this.commonService.createRatingRange(number);
	}
	// End

	// Function for rating reset 
	createRatingBlank(number) {
		return this.commonService.createRatingBlank(number);
	}
	// End

	isFloat(number) {
		return this.commonService.isFloat(number);
	}

	// For get seller profile 
	getSellerProfile(seller_id) {
		if (this.orderDetail.is_deleted_seller == 0) {
			this.router.navigate(['seller-review', seller_id]);
		}
	}
	// End

	// Function for get dispute list 
	getDisputeList() {

		this.buyerService.getDisputeList().subscribe(data => {

			if (data.status == 'success') {
				this.disputeList = data.data.dispute_title;
				this.disputeData.title = this.disputeList[0].title;
			}

		}, err => {
			this.commonService.errorhandler(err);
		});

	}
	// End


		// Function for order status change
		changePickupStatus(nextOrderStatusNumber) {

			if (nextOrderStatusNumber == 7) {
				nextOrderStatusNumber = 8;
			}
	
			if (nextOrderStatusNumber == 9) {
				nextOrderStatusNumber = 10;
			}
	
			let statusData = `status=${escape(nextOrderStatusNumber)}`;
	
			this.commonService.showLoader();
	
			this.sellerService.changeOrderStatus(this.orderId, statusData).subscribe(orderData => {
				this.commonService.hideLoader();
	
				if (orderData.status == 'success') {
	
					$('#trackModal').modal('hide');
	
					const removeValFromIndex = [6, 7, 8, 9, 10];
					const indexSet = new Set(removeValFromIndex);
					this.trackImages = this.trackImages.filter((value, i) => !indexSet.has(i));
					this.getMyOrderDetail(this.orderId);
	
				}
			}, err => {
				this.commonService.hideLoader(); //Hide loader
				this.commonService.errorhandler(err); // Error show 
			});
	
		}
		// End

	// Function for change order status button
	changeOrderStatus(nextOrderStatusNumber) {


		if (nextOrderStatusNumber == '5' || nextOrderStatusNumber == '9') {

			if(this.signatureImage == undefined){
				this.commonService.toastError(this.translate.instant('MYORDER.please_add_buyer_signature'));
				return false;
			}

			if (this.signatureImage != "" ) {

				// nextOrderStatusNumber = (this.orderDetail.current_status_number == '7') ? Number(nextOrderStatusNumber) + Number(1) : nextOrderStatusNumber;

				const formData = new FormData();
				formData.append('order_id', this.orderId);
				formData.append('status', escape(nextOrderStatusNumber));
				formData.append('signature', this.signatureImage, 'signature.png');

				this.commonService.showLoader();

				this.buyerService.addSignature(formData).subscribe(orderData => {

					this.commonService.hideLoader();

					if (orderData.status == 'success') {
						$('#trackModal').modal('hide');
						setTimeout(() => {
							window.location.reload();
						}, 500);

					}

				}, err => {
					this.commonService.hideLoader();
					this.commonService.errorhandler(err);
				});

			} else {
				this.commonService.toastError(this.translate.instant('MYORDER.please_add_buyer_signature'));
			}

		} else {

			nextOrderStatusNumber = (this.orderDetail.current_status_number == '5') ? Number(nextOrderStatusNumber) + Number(1) : nextOrderStatusNumber;

			let statusData = `status=${escape(nextOrderStatusNumber)}`;

			this.commonService.showLoader();


		}

	}
	// End

	// Function for clear signature canvas
	clearSignaturePad() {
		this.signaturePad.clear();
		this.signatureImage = "";
	}
	// End

	// Function for signature draw
	drawComplete() {
		// will be notified of szimek/signature_pad's onEnd event
		var block = this.signaturePad.toDataURL().split(";");
		// Get the content type of the image
		var contentTypes = block[0].split(":")[1];// In this case "image/png"
		// get the real base64 content of the file
		var realData = block[1].split(",")[1];
		var blobImg = b64toBlob(realData, contentTypes, '');

		this.signatureImage = blobImg;

	}
	// END


	// Function for get firebase details 
	getDetailsFirebase() {
		let node = `${this.orderId}-${this.orderDetail.seller_id}-${this.authService.getCurrentUserId()}`;
		if (node != null) {
			this.disputeSubscription = this.firebaseService.getDisputeDetail(node).subscribe(data => {

				if (data.key == "detail") {
					this.DisputeFBData = data.payload.val();
					this.commonService.hideLoader();

				}

				this.ngOnDestroy();

			});

		}
	}
	// End

	// For get dispute chat
	getDisputeChat() {

		this.disputeNode = `${this.orderId}-${this.orderDetail.seller_id}-${this.authService.getCurrentUserId()}`;

		this.disputeChatSubscription = this.firebaseService.getDisputeChat(this.disputeNode).subscribe(data => {
			scrollDown();


			this.chatList = data;

			this.chatList.forEach(element => {
				element.centerTime = this.commonService.chatGroupTime(element.timestamp);
			});

		});
	}
	// End

	// For get dispute details 
	getDisputeDetail() {

		this.disputeNode = `${this.orderId}-${this.orderDetail.seller_id}-${this.authService.getCurrentUserId()}`;


		if (this.disputeNode != null) {
			this.disputeSubscription = this.firebaseService.getDisputeDetail(this.disputeNode).subscribe(data => {
				if (data.key == "detail") {
					this.DisputeFBData = data.payload.val();


				} else {
					// this.router.navigate(['../my-orders']);
				}
			});

		} else {
			// this.router.navigate(['../my-orders']);
		}
	}
	// End

	// For get order details 
	getMyOrderDetail(orderId) {
		this.commonService.showLoader();

		this.buyerService.getMyOrderDetail(orderId).subscribe(myOrderData => {

			this.commonService.hideLoader();

			if (myOrderData.status == 'success') {

				this.responseCheck = 1;

				this.orderDetail = myOrderData.data.order_detail;

				this.getDetailsFirebase();
				this.getDisputeDetail();
				this.getDisputeChat();


				// this.pricing_detail = cartlistData.data.pricing_detail;

				this.coupon = this.orderDetail.assign_coupon_percentage.replace(/\.00$/, '');
				this.coupon2 = this.orderDetail.apply_coupon_percentage.replace(/\.00$/, '');

				// this.coupon2 = cartlistData.data.amount_details.coupon2_percentage.replace(/\.00$/,'');

				this.apply_coupon_amount = this.orderDetail.apply_coupon_amount;
				this.apply_coupon_percentage = this.orderDetail.apply_coupon_percentage;

				this.assign_coupon_amount = this.orderDetail.assign_coupon_amount;
				this.assign_coupon_percentage = this.orderDetail.assign_coupon_percentage;

				this.influencer_code_amount = this.orderDetail.influencer_code_amount;
				this.influencer_code_percentage = this.orderDetail.influencer_code_percentage;

				this.influencer_code_percentage = this.influencer_code_percentage.replace(/\.00$/, '');

				this.tax_detailPercent = this.orderDetail.tax_percent;
				this.processing_fee = this.orderDetail.transaction_fee_percent_amount * 1 + this.orderDetail.transaction_fee_flat * 1;
				this.processing_fee = this.processing_fee.toFixed(2);

				//this.orderDetail.products = JSON.parse(myOrderData.data.order_detail.product);



				if (myOrderData.data.order_detail.order_shipping_type == 1) {
					this.addrestype = 1;
					// this.orderAddress = myOrderData.data.order_detail.order_seller_address;
					// this.house = "";
					this.orderAddress = myOrderData.data.order_detail.order_address;
					this.house = this.orderAddress.house_number + ', ';

				} else {
					this.addrestype = 0;
					this.orderAddress = myOrderData.data.order_detail.order_address;
					this.house = this.orderAddress.house_number + ', ';

				}


				this.trackingStatus = this.orderDetail.tracking_status;


				if ((this.orderDetail.current_status_number == '7' || this.orderDetail.current_status_number == '8' || this.orderDetail.current_status_number == '9' || this.orderDetail.current_status_number == '10')) {
					this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.return_initiated') });
					this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.return_inprocess') });
					this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.collect_by_store') });
					this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.received_by_seller') });
					this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/refund.png', orderStatus: this.translate.instant('SELLER_SIDE.return_and_refunded') });
				}

				if (Object.keys(this.orderDetail.rating).length !== 0) {
					this.currentRate = this.orderDetail.rating.rating;
					this.description = this.orderDetail.rating.review;
					this.sellerRateByBuyer = this.orderDetail.rating.rating;
				} else {
					this.sellerRateByBuyer = '0';
				}
			}
		}, err => {
			this.responseCheck = 0;

			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}
	// End

	// For order time
	getOrderTimeShow(dateTime, string) {

		return this.commonService.getOrderTime(dateTime, string);
	}

	// For order time 
	getOrdeDate(dateTime, string) {

		return this.commonService.getOrderDate(dateTime, string);
	}


	getDisputeTimeShow(dateTime) {

		return this.commonService.getDisputeTimeShow(dateTime);
	}
	// End

	// For rating 
	setRatingReview() {
		if (this.currentRate == 0) {
			this.commonService.toastError(this.translate.instant('MYORDER.please_tap_star_to_rate_it'));
			return false;
		} else {

			this.commonService.showLoader();

			const formData = new FormData();
			formData.append('rating', this.currentRate);
			formData.append('review', this.description);
			formData.append('rating_for', this.orderDetail.seller_id);
			formData.append('order_id', this.orderDetail.orderID);

			this.buyerService.setRatingReview(formData).subscribe((data) => {
				this.commonService.hideLoader();

				if (data.status == 'fail') {
					this.commonService.toastError(data.message);
				} else {
					this.commonService.toastSuccess(data.message);
					$('#giveReview').modal('hide');
					window.location.reload();
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
		}
	}
	// End

	getTimeAgo(timestamp) {
		return this.commonService.chatHistoryTimeAgo(timestamp);
	}

	// For view image in full size 
	imagePreviewModal(imageSrc) {
		$('#imgFullViewModal').modal('show');
		this.imagePreview = imageSrc;
	}
	// End

	// Function used for order return 
	returnRequest() {
		const formData = new FormData();

		formData.append('order_id', this.orderDetail.orderID);
		this.commonService.showLoader();

		this.buyerService.ReturnOrder(formData).subscribe((data) => {
			this.commonService.hideLoader();

			if (data.status == 'fail') {
				this.commonService.toastError(data.message);
			} else {
				this.getMyOrderDetail(this.orderDetail.orderID)
				this.commonService.toastSuccess(data.message);
				$('#returmModel').modal('hide');
				// window.location.reload();
			}
		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}
	// END

	// For reset review
	resetReview() {
		$('.reviewDesc').val('');
		this.currentRate = 0;
	}
	// End


	fileSectionReset() {
		this.disputeData.mediaType = '0';
		this.disputeData.image = '';
		this.disputeData.video = '';
		this.disputeData.videoThumb = '';
	}

	// For image upload
	onSelectedFile(event) {

		this.fileSectionReset();

		if (event.target.files.length > 0) {
			const file = event.target.files[0];

			const name = event.target.files[0].name;
			const lastDot = name.lastIndexOf('.');

			const fileName = name.substring(0, lastDot);
			const ext = name.substring(lastDot + 1);

			if (ext == "gif" || ext == "png" || ext == "jpeg" || ext == "jpg" || ext == "mp4" || ext == "MP4" || ext == "mov" || ext == "MOV") {

				if (ext == "mp4" || ext == "MP4" || ext == "mov" || ext == "MOV") { // If video uploaded

					this.commonService.generateThumbnail(file).then(thumbnailData => {

						let block = thumbnailData.split(";");
						// Get the content type of the image
						let contentTypes = block[0].split(":")[1];// In this case "image/png"
						// get the real base64 content of the file
						let realData = block[1].split(",")[1];
						let videoThumb = b64toBlob(realData, contentTypes, '');
						this.disputeData.mediaType = '2';
						this.disputeData.video = file;
						this.disputeData.videoThumb = videoThumb;

					}).catch(error => {
					});

				} else { // if image uploaded
					this.disputeData.image = file;
					this.disputeData.mediaType = '1';
				}

			} else {
				event.target.value = '';
				this.commonService.toastError(this.translate.instant('MYORDER.please_select_right_format_for_this_file'));
			}

		}

	}
	// End

	//Reset Dispute 
	resetDispute() {
		$('.descrip').val('');
	}

	// For create dispute
	disputeCreate() {


		if ($('.descrip').val().trim() == '') {
			this.commonService.toastError(this.translate.instant('REPORTMODEL.description_required')); return false;
		}
		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('dispute_for', this.orderId);
		formData.append('title', this.disputeData.title);
		formData.append('description', this.disputeData.description);
		formData.append('media_type', this.disputeData.mediaType);

		if (this.disputeData.mediaType == '1') {
			formData.append('dispute_media', this.disputeData.image);
		} else if (this.disputeData.mediaType == '2') {
			formData.append('dispute_media', this.disputeData.video);
			formData.append('video_thumb', this.disputeData.videoThumb, 'videoThumb.png');
		}


		// return false;

		this.buyerService.disputeCreate(formData).subscribe(disputeData => {
			this.commonService.hideLoader();

			if (disputeData.status == 'success') {
				this.commonService.toastSuccess(this.translate.instant('MYORDER.dispute_has_been_created_successfully'));
				this.disputeAddOnFb(disputeData.data.dispute_detail);


				$('#disputeModal').modal('hide');


				this.trackImages = [];
				this.trackImages = [
					{ icon: this.siteUrl + '/assets/img/common/ordered.png', orderStatus: this.translate.instant('SELLER_SIDE.order_placed') },
					{ icon: this.siteUrl + '/assets/img/common/placed.png', orderStatus: this.translate.instant('COMMON.approved_status') },
					{ icon: this.siteUrl + '/assets/img/common/packed.png', orderStatus: this.translate.instant('SELLER_SIDE.order_packed') },
					{ icon: this.siteUrl + '/assets/img/common/shipped.png', orderStatus: this.translate.instant('SELLER_SIDE.order_shipped') },
					{ icon: this.siteUrl + '/assets/img/common/zone_icon.png', orderStatus: this.translate.instant('SELLER_SIDE.order_zone') },
					{ icon: this.siteUrl + '/assets/img/common/delivered.png', orderStatus: this.translate.instant('SELLER_SIDE.order_delivered') }
				]
				this.ngOnInit();
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End


	resloved_dispute_confirm(){

		Swal.fire({
			// title: this.translate.instant('MYORDER.are_you_sure'),
			text: this.translate.instant('MYORDER.You_want_to_reslove_this_order') ,
			icon: 'warning',
			// position: 'center-end',
			showCancelButton: true,
			confirmButtonText: this.translate.instant('FOOTER.ok_button'),
			cancelButtonText: this.translate.instant('COMMON.cancel'),
			reverseButtons: true
		  }).then((result) => {
			if (result.value) {
				this.resloved_dispute()
			//   this.acceptReject(status, orderId, licenseStatus);
			} 
		  });
	}


	// For resolve dispute
	resloved_dispute() {

		const formData = new FormData();
		formData.append('order_id', this.orderId);
		formData.append('favour_of', this.authService.getCurrentUserId());
		formData.append('user_type', "buyer");

		this.commonService.showLoader();

		this.buyerService.reSlovedDispute(formData).subscribe(Resloveddata => {

			if (Resloveddata.status == "success") {

				let node = `${this.orderId}-${this.orderDetail.seller_id}-${this.authService.getCurrentUserId()}`;
				if (node != null) {
					this.disputeSubscription = this.firebaseService.getDisputeDetail(node).subscribe(data => {

						if (data.key == "detail") {
							this.DisputeFBData = data.payload.val();
							this.commonService.hideLoader();

							this.commonService.toastSuccess(Resloveddata.message);
							this.disputeSubscription1 = this.firebaseService.disputeUpdateFb(this.orderDetail.seller_id, this.DisputeFBData)
							this.getMyOrderDetail(this.orderId);

						} else {
							this.router.navigate(['../my-orders']);
						}

						// this.ngOnDestroy();

					});

				} else {
					this.router.navigate(['../my-orders']);
				}

			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		})


	}
	// End

	// Dispute for firebase
	disputeAddOnFb(data) {

		let chatData = {
			admin_id: data.adminID,
			closed: 0,
			closed_timestamp: 0,
			description: data.description,
			dispute_by: data.dispute_by,
			dispute_by_user_type: data.dispute_by_user_type,
			dispute_order_id: this.orderId,
			favour_of: "",
			favour_of_user_type: "",
			resloved_by: "",
			resloved_of_user_type: "",
			image_url: data.image,
			reason: data.dispute_title,
			timestamp: Date.now(),
			video_thumb_url: data.video_thumb,
			video_url: data.video
		}
		let senderId = this.orderDetail.seller_id;


		this.firebaseService.disputeAddOnFb(senderId, chatData)
	}
	// End

	confirmationOrder(orderId, orderNumber) {
		Swal.fire({
		  title: this.translate.instant('MYORDER.are_you_sure'),
		  text: this.translate.instant('MYORDER.You_want_to_cancel_this_order'),
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonText: this.translate.instant('FOOTER.ok_button'),
		  cancelButtonText: this.translate.instant('COMMON.cancel'),
		  reverseButtons: true
		}).then((result) => {
		  if (result.value) {
			  var id = parseFloat(orderId)
			  this.cancelOrder(orderId)
		  //   this.acceptReject(status, orderId, licenseStatus);
		  } 
		});
	 
  
	}


	cancelOrder(id){
		// alert('hello')

		const formData = new FormData();
		formData.append('order_id', id);
		formData.append('cancel_by', "buyer");
		formData.append('note', "");
		this.commonService.showLoader();  // show loader
		this.buyerService.cancelOrder(formData).subscribe(data => {


			if (data.status == 'success') {
				this.commonService.hideLoader();
				this.commonService.toastSuccess(data.message);
				this.getMyOrderDetail(this.orderId);

				// this.getMyOrderDetail(this.orderId); // get order details 
			}else{
				this.commonService.hideLoader();
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		})

	}

	// Dispute details 
	disputeDetail() {
		let node = `${this.orderId}-${this.orderDetail.seller_id}-${this.authService.getCurrentUserId()}`;
		this.router.navigate(['dispute/', node]);
		return false;
	}



	showImage(image){
		this.deliverdImage = image;
		$('#orderStatusImageShow').modal('show');

	}
	// End

}



function b64toBlob(b64Data, contentType, sliceSize) {
	contentType = contentType || '';
	sliceSize = sliceSize || 512;

	var byteCharacters = atob(b64Data);
	var byteArrays = [];

	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);

		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	var blob = new Blob(byteArrays, { type: contentType });
	return blob;
}


