import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { faAngleDoubleRight, faCheck, faStar, faCircle } from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../services/common.service';
import { ShopService } from '../services/shop.service';
import { AuthService } from '../auth/auth.service';
import { HeaderBuyerComponent } from '../common/header-buyer/header-buyer.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SellerService } from '../seller/services/seller.service';
import { BuyerService } from '../buyer/services/buyer.service';

declare const $: any;

@Component({
    selector: 'app-stores',
    templateUrl: './stores.component.html',
    styleUrls: ['./stores.component.css']
})
export class StoresComponent implements OnInit {
    // Owl carousel options for featured products
    featuredProduct: OwlOptions = {
        loop: false,
        margin: 30,
        items: 3,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: false,
        nav: true,
        dots: false,
        navSpeed: 500,
        navText: ['<i class="las la-angle-left"></i>', '<i class="las la-angle-right"></i>'],
        responsive: {
            0: { items: 1 },
            480: { items: 2 },
            767: { items: 3 },
        },
    };



    // Font Awesome Icons
    faAngleDoubleRight = faAngleDoubleRight;
    deliveryOption: string = ''; // Holds the selected value
    categoriesOption: string = ''; // Holds the selected value

    faCheck = faCheck;
    faStar = faStar;
    faCircle = faCircle;
    currentUserId: number;
    productList: any[] = [];
    categoryList: any[] = [];
    provinceList: any[] = [];
    selectedCategory: any = [];
    filter: object;
    search_term: any = "";
    limitOfProduct: number = 12;
    offsetOfProduct: number = 0;
    isShown: boolean = false; // For toggle show/hide
    is_shop_from: string = "";

    @ViewChild('header', { static: true }) header: HeaderBuyerComponent;
    stroreList: any = [];
    productloader: boolean;
    productCount: any = 0;
    price_to: string;
    maxPrice: number;
    minPrice: number;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private commonService: CommonService,
        private shopService: ShopService,
        private authService: AuthService,
        private sellerService: SellerService,
        private router: Router,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private buyerService: BuyerService,
    ) {
        translate.use(this.commonService.getLanguage());
    }

    ngOnInit() {
        this.commonService.scrollToTop();
        this.initializeMetaTags();
        this.currentUserId = this.authService.getCurrentUserId();
        this.getCategoryList();
        this.getStoreList();
        localStorage.removeItem("pageRoute");
    }

    toggleShow() {
        this.isShown = !this.isShown;
        if (this.isShown) {
            this.document.body.classList.add('filter-open');
        } else {
            this.document.body.classList.remove('filter-open');
        }
    }

    initializeMetaTags() {
        const str = this.router.url;
        const regex = /\/shop\/category/;
        if (regex.test(str)) {
            this.commonService.setTitle('Explora Nuestras Categorías de Productos con Mercadoba | Compra en Línea en Cuba');
            this.commonService.getMetaTages(
                'Explora Nuestras Categorías de Productos con Mercadoba | Compra en Línea en Cuba',
                'Encuentra exactamente lo que necesitas en Cuba a través de nuestras diversas categorías de productos en línea con Qvazon. Compra ahora y recibe rápidamente. Explora nuestras categorías y elige entre una amplia gama de opciones.'
            );
        } else {
            this.commonService.setTitle('Compra en Línea en Cuba con Mercadoba | Entrega Rápida y Variedad de Productos');
            this.commonService.getMetaTages(
                'Compra en Línea en Cuba con Mercadoba | Entrega Rápida y Variedad de Productos',
                'Compra en línea en Cuba de manera conveniente y segura con Qvazon. Amplia variedad de productos y envío rápido. Haz tu pedido ahora y recíbelo pronto. Explora nuestras categorías y encuentra justo lo que necesitas.'
            );
        }
    }

    getCategoryList() {
        this.sellerService.getCategoryList(1).subscribe(
            (catSubactData) => {
                if (catSubactData.status === 'success') {
                    this.categoryList = catSubactData.data.category;
                }
            },
            (err) => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            }
        );
    }

    getStoreList() {
        this.commonService.showLoader();

        this.shopService.getStoreList(8, 0, this.getFilterData(2)).subscribe(
            (stroreData) => {
                this.commonService.hideLoader();
                if (stroreData.status === 'success') {
                    this.stroreList = stroreData.data.sellerdata;
                    if(this.stroreList.length != 0){
                        this.productCount =  1;
                    }else{
                        this.productCount = 0;
                    }
                    
                }
            },
            (err) => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            }
        );
    }


    SearchInterval: any = 800;
  
    // For search filter
    search() {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.getStoreList()
      }, this.SearchInterval);
    }
    // End

    resetLoadMore() {
        this.stroreList = [];
        this.limitOfProduct = 12;
        this.offsetOfProduct = 0;
    }

    searchTimer: any;
    doneSearchInterval: any = 500;

    getFilterData(init: number) {
        var filter = {

            'search_term': "",
            'delivery_option': "",
            'category_option' : "",


        }
        if (init != 1) { // for starting
            filter.search_term = this.search_term;
            filter.delivery_option = this.deliveryOption;
            filter.category_option = this.categoriesOption;


        }
        return filter;
    }

    // function for search order in order list 
    applyFilter() {
        this.getStoreList()
    }


    // Function for reset filter
    resetFilterStore() {
        this.search_term = "";
        this.deliveryOption = "";
        this.categoriesOption = "";
        this.getStoreList()
    }
    redirectFunction(detail,id){
        // var name1 = (name.split(' ').join(''));
    
        // var url = encodeURIComponent(name1).replace(/[!'()*]/g, function (c) {
        //   return '-' + c.charCodeAt(0).toString(16);
        // });
    
        // var removeSpace = (url.split('%').join('-'));
        // var validUrl = detail+'/'+removeSpace+'/'+id1;
        var validUrl = detail+'/'+id;
        this.router.navigate([validUrl]);
      
        }


            // End
    redirectProductFunction(detail, name, id1) {
        
        localStorage.setItem("pageRoute",'stores');
        var name1 = (name.split(' ').join(''));
        var url = encodeURIComponent(name1).replace(/[!'()*]/g, function (c) {
            return '-';
        });
        var removeSpace = (url.split('%').join('-'));
        var validUrl = detail + '/' + removeSpace + '/' + id1;
       
        this.router.navigate([validUrl]);

    }


    // For add item to cart
	addToCart(product, index,type="",sellerIndex) {
		var user_province = localStorage.getItem("user_province");
		// For check user is loggedin or not
		if (this.authService.isLoggedIn()) {
			const formData = new FormData();
			formData.append('product_varint_id',product.productVariantID );
			formData.append('product_quantity', '1');
			formData.append('user_province', user_province);
			// this.commonService.showLoader();

			this.shopService.addToCart(formData).subscribe((data) => {
				this.commonService.hideLoader();
                    this.stroreList[sellerIndex].sellerProductlist[index].is_cart = 1;
                    this.stroreList[sellerIndex].sellerProductlist[index].cartItemID = data.data.cartID;
                    this.header.getCartCount();// for decrease card count

					// this.resetForm();
				
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		}

		// } 
		else {
			this.router.navigate(['/login']);
		}

		// }

	}

	//Remove cart item
	removeCartItem(cartItemData, index,type="",sellerIndex) {
        console.log('cartItemId',cartItemData.cartItemID);
		this.buyerService.removeCartItem(cartItemData.cartItemID).subscribe(
			(cartlistDataRemove) => {
				this.commonService.hideLoader();
                this.stroreList[sellerIndex].sellerProductlist[index].is_cart = 0;
                this.header.getCartCount();// for decrease card count
				
			}, err => {
				// this.commonService.hideLoader();
				// this.commonService.errorhandler(err);
			});
	}
    
}
