import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { faAngleDoubleRight, faCamera, faTrashAlt, faEdit, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { CommonService } from '../../services/common.service';
import { AuthService } from '../../auth/auth.service';
import { BuyerService } from '../services/buyer.service';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { HeaderBuyerComponent } from '../../common/header-buyer/header-buyer.component';

import Swal from 'sweetalert2';

declare const $: any;
declare var Stripe: any;

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.css']
})


export class MyAccountComponent implements OnInit {

    faAngleDoubleRight = faAngleDoubleRight;
    faCircle = faCircle;
    faCamera = faCamera;
    faTrashAlt = faTrashAlt;
    faEdit = faEdit;
    editprofileForm: FormGroup;
    addressForm: FormGroup;
    editAddressForm: FormGroup;
    changePasswordForm: FormGroup;
    feedbackForm: FormGroup;
    addressList: any = [];
    addresslistCount: any;
    userProfile: any = {};
    avatar: any;
    countryCode: any;
    countryName: any;
    profileAddress: any;
    showAddCard: boolean = true;
    isPassword: any;
    cardList: any = [];
    cardListCount
    stripe;
    card;
    cardErrors;
    activeTab: any;
    provinceList: any = [];
    verifyPrice: any = "";
    cardID: any;
    profilePhone: any;
    initialCountrySet2: string = 'cu';


    dial_code: string = '+53';
    dial_code_address: string = '+53';
    country: string = 'Cuba';
    country_code: string = 'cu';
    initialCountrySet: string = 'cu';
    profile_country_code: string = 'CU';


    deductedAmount: string = "";
    otpVerificationForm: FormGroup;
    profileResponse: any;
    getEmailData: any;
    verifyOt: any;
    profileEmail: any;
    dialCode: any;
    emailPro: any;
    // telInputObject(obj) {
    //   obj.setCountry('cu');
    // }

    municipalitiesDataList: any = [];
    municipalitiesAddDataList: any = [];

    @ViewChild('cardElement', { static: false }) cardElement: ElementRef;
    @ViewChild("placesRef", { static: false }) placesRef: GooglePlaceDirective;
    cartVarification: any;
    @ViewChild('header', { static: true }) header: HeaderBuyerComponent;
    provinceChanged: any = 0;

    constructor(
        private authService: AuthService,
        private commonService: CommonService,
        private formBuilder: FormBuilder,
        private buyerService: BuyerService,
        private firebaseService: FirebaseService,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) { translate.use(this.commonService.getLanguage()); }

    ngOnInit() {

        this.commonService.scrollToTop();
        this.commonService.setTitle('Mercadoba | My Account');
        this.translate.get('CUSTOMER.please_enter_deducted_amount').subscribe((text: string) => { this.deductedAmount = text; });
        this.getCardList();
        let emaildata = JSON.parse(localStorage.getItem(environment.dataStore.userData));
        this.emailPro = emaildata.user_detail.email;
        this.initialCountrySet = this.authService.getUserDetails().profile_country_code;

        const str1 = this.router.url;
        const regex1 = RegExp('/my-account/address');
        if (regex1.test(str1) == true) {
            $('a[href="#csTab3"]').trigger('click');
        }
        // for edit profile
        // Validators.pattern("^[a-zA-Z]+[\-'\s]?[a-zA-Z ]+$")
        this.editprofileForm = this.formBuilder.group({
            profileImage: [''],
            full_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]+')]],
            email: [''],
            // address: ['', Validators.required],
            profile_country: ['', Validators.required],
            province: ['', Validators.required],
            municipalities: ['', Validators.required],
            mobile: ['', [Validators.required, Validators.maxLength(15), Validators.minLength(5), Validators.pattern('[0-9]+')]],
        });
        // End

        // Add address form validations
        this.addressForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]+')]],
            phone_number: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(15), Validators.pattern('[0-9]+')]],
            house_number: ['', [Validators.maxLength(100), Validators.required]],
            add_province: ['', [Validators.required]],
            add_municipalities: ['', [Validators.required]],
            city: ['', [Validators.required, Validators.minLength(2)]],
            // pin_code: ['', [Validators.minLength(3), Validators.maxLength(20), Validators.required, Validators.pattern('[0-9]+')]],
        });
        // End

        this.isPassword = this.authService.getUserDetails().password;

        //For change Password validations
        if (this.isPassword != "" && this.isPassword != null) {
            this.changePasswordForm = this.formBuilder.group({
                oldPass: ['', [Validators.required, Validators.maxLength(50)]],
                newPass: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
                cnfPass: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
            });
        } else {
            this.changePasswordForm = this.formBuilder.group({
                oldPass: [''],
                newPass: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
                cnfPass: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
            });
        }
        // End

        //Write Feedback form validation
        this.feedbackForm = this.formBuilder.group({
            feedback: ['', [Validators.required]],
        });
        // End


        // OTP Verify form validation
        this.otpVerificationForm = this.formBuilder.group({
            otp: ['', [Validators.required, Validators.minLength(6)]],
            otpVerificationForm: ['']
        });
        // End Code


        this.getUserProfile();  //For user profile data
        this.getUserAddress();  //For get user address
        this.getProvinceList(); //For get province list 

        let stripeCustomerID = this.authService.getStripeCustomerID();
        if (stripeCustomerID == false) {
            // this.addStripeCustomer();
        }

        // manage redirect
        this.activeTab = this.route.snapshot.paramMap.get("tab");
        switch (this.activeTab) {
            case 'address':
                $('.profileSideMenu .nav-tabs a').eq(2).trigger('click');
                break;
        }

    }



    ngOnDestroy(): void {
        $('#cardVerifyModal').modal('hide');
        $("#numberChangePopUp").modal("hide");
        $("#otpVerify").modal("hide");
        $("#numberChangePopUp").modal("hide");
        $("#leaveFeedback").modal("hide");
        $('#addCard').modal('hide');
        $('#addAddress').modal('hide');
        $("#profileDelete").modal("hide");
        $("#changePassword").modal("hide");
        Swal.close();


        // this.disputeSubscription1.unsubscribe();
    }

    // For add new address form validation
    get name() { return this.addressForm.get('name'); }
    get phone_number() { return this.addressForm.get('phone_number'); }
    get house_number() { return this.addressForm.get('house_number'); }
    // get street() { return this.addressForm.get('street'); }
    get city() { return this.addressForm.get('city'); }
    // get pin_code() { return this.addressForm.get('pin_code'); }
    get add_province(): any { return this.addressForm.get('add_province'); }
    get add_municipalities(): any { return this.addressForm.get('add_municipalities'); }

    // End 

    // For change password form validation
    get oldPass() { return this.changePasswordForm.get('oldPass'); }
    get newPass() { return this.changePasswordForm.get('newPass'); }
    get cnfPass() { return this.changePasswordForm.get('cnfPass'); }
    // End

    //For Write Feedback form validation 
    get feedback() { return this.feedbackForm.get('feedback'); }
    get otp() { return this.otpVerificationForm.get('otp'); }
    // End

    ngAfterViewInit() {
        // stripe payment
        this.stripe = Stripe(environment.striePkKey); //For stripe key
        const elements = this.stripe.elements();

        this.card = elements.create('card');
        this.card.mount(this.cardElement.nativeElement);

        this.card.addEventListener('change', ({ error }) => {
            this.cardErrors = error && error.message;
        });
    }

    trimValue(formControl) {
        ((typeof formControl.value) && formControl.value.trim() == "") ?
            formControl.setValue("") : formControl.setValue(formControl.value);
    }

    // For get province list
    getProvinceList() {
        this.authService.getProvinceList()
            .subscribe(provinceData => {
                this.commonService.hideLoader();

                if (provinceData.status == 'success') {
                    this.provinceList = provinceData.data.province;
                } else {
                    this.provinceList = [];
                }

            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });
    }
    // End

    //add customer on stripe 
    addStripeCustomer() {
        this.buyerService.createStripeCustomer().subscribe(customerID => { // call api for create customer on stripe 
            if (customerID.status == 'success') { // check api response  if sucess than 
                let userData = this.authService.getFullUserDetails(); // get user detail from local storage 
                userData.user_detail.stripe_customer_id = customerID.data.stripe_customer_id; // get stripe customer id 
                localStorage.setItem(environment.dataStore.userData, JSON.stringify(userData)); //  set data  in local storage 
            }
        }, err => {
            this.commonService.errorhandler(err); //  err    handle 
        });
    }

    //For get user address list
    getUserAddress() {
        this.commonService.showLoader(); // show loader

        this.buyerService.getUserAddress().subscribe( // call api for get user address 
            (useraddressList) => {
                this.commonService.hideLoader(); // hide loader 

                if (useraddressList.status == 'success') {
                    var keys = Object.keys(useraddressList.data); //Check data is empty object
                    var len = keys.length;  //get length of empty object
                    if (len === 0) {
                        this.addresslistCount = 0; // assign address count is 0 when list is empty 
                    }

                    this.addressList = useraddressList.data.address_list; // assign user address 
                }
            }, err => {
                this.commonService.hideLoader(); // hide loader 
                this.commonService.errorhandler(err); // handle error 
            });
    }
    // End

    // For delete address 
    deleteAddress(addressId) {

        Swal.fire({
            title: this.translate.instant('MYORDER.are_you_sure'),
            text: this.translate.instant('COMMON.delete_addres'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('FOOTER.ok_button'),
            cancelButtonText: this.translate.instant('COMMON.cancel'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.commonService.showLoader();

                this.buyerService.deleteAddress(addressId).subscribe((data) => {
                    this.commonService.hideLoader();

                    if (data.status == 'fail') {
                        this.commonService.toastError(data.message);
                    } else {
                        this.commonService.toastSuccess(data.message);
                        // get all address list
                        this.getUserAddress();
                    }

                }, err => {
                    this.commonService.hideLoader();
                    this.commonService.errorhandler(err);
                });
            }
        });


    }
    // End

    // For profile country change 
    onCountryChangeProfile($event) {
        this.editprofileForm.patchValue({ profile_country: $event.name.split('(')[0] });
        this.profile_country_code = $event.iso2.toUpperCase(); //For profile country code
        this.dial_code = `+${$event.dialCode}`; //For country dial code
        this.country = $event.name.split('(')[0]; //For country name 
    }
    // End

    onKeyUp(event) {
        return false;
    }


    // //Set auto detect country
    // telInputObject(obj) {

    //     // $.getJSON('https://ipapi.co/json/', function (data) {

    //     //     this.initialCountrySet2 = data.country_code.toLowerCase();
    //     //     this.country_code = data.country_code;
    //     //     // this.dial_code = data.country_calling_code;
    //     //     this.dial_code_address = data.country_calling_code;
    //     //     obj.setCountry(this.initialCountrySet2);
    //     // });
    // }
    // // End


    //set auto detect country
    telInputObject(obj) {

        // var initialCountrySet  = this.authService.getUserDetails().profile_country_code;

        // if(initialCountrySet == "" || initialCountrySet == null){
        // 	initialCountrySet  = localStorage.getItem('countryCode');	

        // }else{
        // 	initialCountrySet  = this.authService.getUserDetails().profile_country_code;

        // }

        var initialCountrySet = "cu"
        $.getJSON('https://ipapi.co/json/', function (data) {

            this.initialCountrySet2 = initialCountrySet;

            // this.initialCountrySet = data.country_code.toLowerCase();
            // this.country_code = data.country_code;
            // this.dial_code = data.country_calling_code;
            obj.setCountry(this.initialCountrySet2);
        });
    }


    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }

    hasError($event) {
        if ($event) {
            this.addressForm.patchValue({ phone_number: "1" });
        }

        // else {
        //   this.addressForm.patchValue({ phone_number: "" });
        // }
    }

    // For mobile number dial code 
    onCountryChange($event) {
        this.dial_code_address = `+${$event.dialCode}`;
        this.country = $event.name.split('(')[0];
        this.country_code = $event.iso2.toUpperCase();
    }
    // End

    // For get mobile number and set dail code
    getNumber($event) {
        // this.addressForm.patchValue({phone_number: $event.split(this.dial_code)[1]});
        this.addressForm.patchValue({ phone_number: $event.split(this.dial_code_address)[1] });
    }
    // End

    // For address form reset 
    resetForm() {
        this.addressForm.reset();
    }
    // End

    //For Add Address
    addAddress() {
        // return false;
        if (this.addressForm.valid) {
            this.commonService.showLoader();

            var formData = new FormData();
            formData.append('name', this.addressForm.get('name').value);
            formData.append('phone_number', this.addressForm.get('phone_number').value);
            formData.append('house_number', this.addressForm.get('house_number').value);
            formData.append('add_province', this.addressForm.get('add_province').value);
            formData.append('municipalities', this.addressForm.get('add_municipalities').value);
            // formData.append('street', this.addressForm.get('street').value);
            formData.append('city', this.addressForm.get('city').value);
            // formData.append('pincode', this.addressForm.get('pin_code').value);
            formData.append('dial_code', this.dial_code_address);
            formData.append('country', this.country.trim());
            formData.append('country_code', this.country_code);

            this.buyerService.addUserAddress(formData).subscribe((data) => {
                this.commonService.hideLoader();

                if (data.status == "fail") {
                    this.commonService.toastError(data.message);
                } else {
                    this.commonService.toastSuccess(data.message);
                    this.getUserAddress();
                    this.addresslistCount = 1;
                    this.resetForm();
                    this.getProvinceList();
                    this.municipalitiesAddDataList = [];
                    $('#addAddress').modal('hide');
                }

            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });

        } else {
            // validate all form fields
            this.validateAllFormFields(this.addressForm);
        }
    }
    // End

    // For edit address data
    editAddData(addData) {
        this.buyerService.userAddress = addData;
        this.router.navigate(['edit-address']);
    }
    // End

    //For get cart list
    getCardList() {
        this.buyerService.getCardList().subscribe(cardData => {
            this.cartVarification = cardData.data.varification_status;
            this.commonService.hideLoader();
            if (cardData.status == 'success') {
                this.cardList = cardData.data.card_list;
                var keys = Object.keys(cardData.data); //Check data is empty object
                this.cardListCount = keys.length;  //get length of empty object
            }
        }, err => {
            this.commonService.hideLoader();
            this.commonService.errorhandler(err);
        });
    }
    // End

    // For add new card
    async handleForm(e) {
        this.commonService.showLoader();
        e.preventDefault();

        const { token, error } = await this.stripe.createToken(this.card);

        if (error) {
            this.commonService.hideLoader();
            // Inform the customer that there was an error.
            const cardErrors = error.message;
        } else {

            // Send the token to your server.
            const formData = new FormData();
            formData.append('sourceId', token.id);

            this.buyerService.addCard(formData).subscribe(cardData => {
                this.commonService.hideLoader();

                if (cardData.status == 'fail') {
                    this.commonService.toastError(cardData.message);
                } else {
                    this.saveCard(cardData.data.card_detail);
                    this.resetCard();
                }
            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });

        }
    }
    // End

    // For stripe card 
    saveCard(cardData) {
        this.commonService.showLoader();

        // Send the token to your server.
        const formData = new FormData();
        formData.append('stripe_card_id', cardData.id);
        formData.append('card_holder_name', this.authService.getUserDetails().full_name);
        formData.append('card_last_4_digits', cardData.last4);
        formData.append('card_expiry_month', cardData.exp_month);
        formData.append('card_expiry_year', cardData.exp_year);
        formData.append('card_brand_type', cardData.brand);

        this.buyerService.saveCard(formData).subscribe((data) => {
            this.commonService.hideLoader();

            if (data.status == 'fail') {
                this.commonService.toastError(data.message);
            } else {
                this.commonService.toastSuccess(data.message);
                this.getCardList();
            }

        }, err => {
            this.commonService.hideLoader();
            this.commonService.errorhandler(err);
        });
    }
    // End

    //For delete card
    deleteCard(cardId) {

        Swal.fire({
            title: this.translate.instant('MYORDER.are_you_sure'),
            text: this.translate.instant('COMMON.delete_cart'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('FOOTER.ok_button'),
            cancelButtonText: this.translate.instant('COMMON.cancel'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.commonService.showLoader();

               
        this.buyerService.deleteCard(cardId).subscribe((data) => {
            this.commonService.hideLoader();

            if (data.status == 'fail') {
                this.commonService.toastError(data.message);
            } else {
                this.commonService.toastSuccess(data.message);
                //after deletion of card remove from local storage
                if (this.authService.getUserCard()) {
                    if (this.authService.getUserCard() == cardId) {
                        localStorage.removeItem(environment.dataStore.userCard);
                    }
                }
                // get all card list
                this.getCardList();
            }

        }, err => {
            this.commonService.hideLoader();
            this.commonService.errorhandler(err);
        });
            }
        });

    }
    // End

    // For reset card
    resetCard() {
        this.card.clear();
        $('#addCard').modal('hide');
    }
    // End

    // Edit profile form submit
    changePassFormSubmit() {

        if (this.changePasswordForm.valid) {
            this.commonService.showLoader();
            const formData = new FormData();
            if (this.isPassword != "" && this.isPassword != null) {
                formData.append('old_password', this.changePasswordForm.get('oldPass').value);
            }
            formData.append('new_password', this.changePasswordForm.get('newPass').value);
            formData.append('confirm_password', this.changePasswordForm.get('cnfPass').value);

            this.buyerService.changePassword(formData).subscribe((data) => {
                this.commonService.hideLoader();

                if (data.status == 'fail') {
                    this.commonService.toastError(data.message);
                } else {
                    this.commonService.toastSuccess(data.message);
                    this.authService.logout();
                    this.router.navigate(['/login']);
                }
            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });
        } else {
            // validate all form fields
            this.validateAllFormFields(this.changePasswordForm); //{7}
        }

    }
    // End

    // For change password form reset
    cancelChangePassword() {
        this.changePasswordForm.reset();
    }
    // End

    //for edit profile
    get full_name(): any { return this.editprofileForm.get('full_name'); }
    get profile_country(): any { return this.editprofileForm.get('profile_country'); }
    get province(): any { return this.editprofileForm.get('province'); }
    get municipalities(): any { return this.editprofileForm.get('municipalities'); }
    get mobile(): any { return this.editprofileForm.get('mobile'); }
    // End

    //For write feedback
    writeFeedback() {

        if (this.feedbackForm.valid) {
            this.commonService.showLoader();

            const formData = new FormData();
            formData.append('feedback', this.feedbackForm.get('feedback').value);
            this.buyerService.writefeedback(formData).subscribe(
                (feedbackData) => {
                    this.commonService.hideLoader();

                    if (feedbackData.status == 'success') {
                        this.commonService.toastSuccess(feedbackData.message);
                        this.feedbackForm.reset();
                        $("#leaveFeedback").modal("hide");
                    } else {
                        this.commonService.toastError(feedbackData.message);
                        this.feedbackForm.reset();
                    }
                }, err => {
                    this.commonService.hideLoader();
                    this.commonService.errorhandler(err);
                });

        } else {
            // validate all form fields
            this.validateAllFormFields(this.feedbackForm); //{7}
        }
    }
    // End

    //For reset Feedback form
    cancelfeedback() {
        this.feedbackForm.reset();
    }
    // End


    // get provice id on change province 
    onProvinceChange(event: Event): void {
        const selectedProvinceID = (event.target as HTMLSelectElement).value;
        this.getMunicipalities(selectedProvinceID);
        this.provinceChanged = 1;
        this.editprofileForm.get('municipalities').setValue('');
    }

    // get provice id on change province 
    onAddProvinceChange(event: Event): void {
        const selectedAddProvinceID = (event.target as HTMLSelectElement).value;
        this.getAddMunicipalities(selectedAddProvinceID);
        this.addressForm.get('add_municipalities').setValue('');
    }

    // For get municipalities list 
    getMunicipalities(province_ids) {

        this.authService.getMunicipalities(province_ids).subscribe(municipalitiesData => {
            this.commonService.hideLoader();
            if (municipalitiesData.status == 'success') {
                this.municipalitiesDataList = municipalitiesData.data.municipalities;
            } else {
                this.municipalitiesDataList = [];
            }

        }, err => {
            this.commonService.hideLoader();
            this.commonService.errorhandler(err);
        });
    }

    // For get municipalities list 
    getAddMunicipalities(province_ids) {

        this.authService.getMunicipalities(province_ids).subscribe(municipalitiesData => {
            this.commonService.hideLoader();
            if (municipalitiesData.status == 'success') {
                this.municipalitiesAddDataList = municipalitiesData.data.municipalities;
            } else {
                this.municipalitiesAddDataList = [];
            }

        }, err => {
            this.commonService.hideLoader();
            this.commonService.errorhandler(err);
        });
    }


    // For user profile data get
    getUserProfile() {
        // this.userProfile = {};
        this.buyerService.getUserProfile().subscribe(data => {

            this.userProfile = data.data.user_detail;
            console.log('userProfile', this.userProfile);
            this.avatar = data.data.user_detail.avatar;
            this.getMunicipalities(this.userProfile.province);
            this.profile_country_code = this.userProfile.profile_country_code;
            this.profileAddress = this.userProfile.profile_address;
            this.dial_code = this.userProfile.phone_dial_code.trim();
            this.editprofileForm.patchValue({
                'full_name': this.userProfile.full_name,
                'email': this.userProfile.email,
                'address': this.userProfile.profile_address,
                'profile_country': this.userProfile.country,
                'province': this.userProfile.province,
                'municipalities': this.userProfile.municipalities,
                'mobile': this.userProfile.mobile_number,
            });




        }, err => {
            this.commonService.hideLoader();
            this.commonService.errorhandler(err);
        });
    }
    // End

    handleAddressChange(address): void {
        this.profileAddress = address.formatted_address;

        address.address_components.forEach(element => {

            if (element.types[0] == "country") {
                this.countryCode = element.short_name;
                this.countryName = element.long_name;
                return true;
            }

        });
    }

    // For check form is valid
    validateAllFormFields(formGroup: FormGroup) {         //{1}
        Object.keys(formGroup.controls).forEach(field => {  //{2}
            const control = formGroup.get(field);             //{3}
            if (control instanceof FormControl) {             //{4}
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        //{5}
                this.validateAllFormFields(control);            //{6}
            }
        });
    }
    // End

    // For update profile 
    editFormSubmit() {

        if (this.editprofileForm.valid) {
            this.commonService.showLoader();
            const formData = `full_name=${this.editprofileForm.get('full_name').value}&profile_address=${escape(this.profileAddress)}&profile_country_code=${escape(this.profile_country_code)}&country=${this.editprofileForm.get('profile_country').value}&province=${this.editprofileForm.get('province').value}&municipalities=${this.editprofileForm.get('municipalities').value}&phone_number=${this.editprofileForm.get('mobile').value}&dial_code=${this.dial_code}&country_code=${escape(this.profile_country_code)}`;

            this.buyerService.editProfileNew(formData).subscribe((data) => {

                this.profileResponse = data.data.user_detail;
                this.verifyOt = data.data;
                this.profileResponse = data.data.user_detail;
                this.profileEmail = this.profileResponse.email;
                this.profilePhone = this.profileResponse.phone_number;
                this.dialCode = this.profileResponse.dial_code;

                this.commonService.hideLoader();

                if (data.data.buyer_phone_number_change == 1) {
                    $("#numberChangePopUp").modal("show");
                    return false;
                }

                if (data.status == 'fail') {
                    this.commonService.toastError(data.message);
                } else {

                    localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));
                    this.header.getProvince();
                    this.header.getWishCount();// for decrease card count
                    this.header.getCartCount();// for decrease card count
                    this.commonService.toastSuccess(data.message);
                    this.userProfile = data.data.user_detail;
                    $("#numberChangePopUp").modal("hide");
                    // update on firebase
                    this.addOnFirebase(data.data.user_detail);


                    this.router.navigate(['/my-account']);

                }
                // this.ngOnInit()
                this.router.navigate(['/my-account']);
            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });
        } else {
            // validate all form fields
            this.validateAllFormFields(this.editprofileForm); //{7}
        }

    }
    // End

    // For otp varification model show & changeNumber modal hide
    openVarifyModelShow() {
        $("#numberChangePopUp").modal("hide");
        $("#otpVerify").modal("show");
    }
    // End


    // For Resend OTP
    reSendOtp() {
        const formData = new FormData();
        formData.append('email', this.emailPro);

        this.authService.reSendOtp(formData).subscribe(data => {
            this.commonService.toastSuccess(data.message);
        }, err => {
            this.commonService.errorhandler(err);
        });
    }
    // End

    // resend otp with new Phone number 
    sendMobileOtp() {
        this.commonService.showLoader();
        const formData = new FormData();
        formData.append('phone_number', this.profileResponse.phone_number);
        formData.append('dial_code', this.profileResponse.dial_code);
        formData.append('is_phone_show', this.profileResponse.is_phone_show);

        this.authService.otpSendMobile(formData).subscribe(data => {
            this.otpVerificationForm.reset();
            this.commonService.hideLoader();
            this.commonService.toastSuccess(data.message);
        }, err => {
            this.commonService.hideLoader();
            this.commonService.errorhandler(err);
        });

    }

    // For Otp verification 
    otpVerify() {
        if (this.otpVerificationForm.valid) {
            const formData = new FormData();
            formData.append('phone_number', this.profileResponse.phone_number);
            formData.append('dial_code', this.profileResponse.dial_code);
            formData.append('country', this.editprofileForm.get('profile_country').value);
            formData.append('country_code', this.profile_country_code);
            formData.append('otp', this.otpVerificationForm.get('otp').value);
            formData.append('is_phone_show', this.profileResponse.is_phone_show);


            this.commonService.showLoader();

            this.authService.verifySendOtp(formData).subscribe(res => {
                this.getEmailData = JSON.parse(localStorage.getItem(environment.dataStore.userData));

                this.commonService.hideLoader();

                // this.commonService.toastSuccess(res.message);
                $("#numberChangePopUp").modal("hide");
                $("#otpVerify").modal("hide");
                this.otpVerificationForm.reset();
                this.editFormSubmit();
                let userdatasetLocal = localStorage.setItem(environment.dataStore.userData, JSON.stringify(this.getEmailData));
            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
                // this.otpVerificationForm.reset();


            });
        }
    }
    // End


    checkChar(e) {
        e.preventDefault();
    }


    //For update user profile image
    onSelectImage(event) {

        if (event.target.files.length > 0) {
            this.commonService.showLoader();

            const file = event.target.files[0];

            const formData = new FormData();
            formData.append('profile_picture', file);

            this.buyerService.updateAvatar(formData).subscribe((data) => {
                this.commonService.hideLoader();

                if (data.status == 'fail') {


                    this.commonService.toastError(data.message);
                    this.ngOnInit();
                } else {
                    this.commonService.toastSuccess(data.message);
                    this.userProfile = data.data;
                    this.addOnFirebase(data.data.user_detail)
                    localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));
                    this.ngOnInit();
                }

            }, err => {
                $('#pImg').removeAttr('src')
                $("#pImg").attr("src", this.avatar);

                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });

        }
    }
    // End


    onChangeCard(cardID, card) {
        this.commonService.showLoader();

        let statusData = `status='1'`;
        this.buyerService.makeAnDefault(cardID, statusData).subscribe((data) => {
            this.commonService.hideLoader();

            if (data.status == 'fail') {
                this.commonService.toastError(data.message);
            } else {
                this.commonService.toastSuccess(data.message);
            }

        }, err => {
            this.commonService.hideLoader();
            this.commonService.errorhandler(err);
        });
    }
    // End

    // For card varify modal show
    verifyModal(cardID) {
        this.cardID = cardID;
        $('#cardVerifyModal').modal('show');
    }
    // End

    // For reset card verification data
    cardVerifyReset() {
        this.verifyPrice = '';
    }
    // End

    // For card varify form data submit
    cardVerifySubmit() {

        if (this.verifyPrice.trim() != "") {
            this.commonService.showLoader();

            const formData = new FormData();
            formData.append('card_id', this.cardID);
            formData.append('verify_amount', this.verifyPrice.trim());

            this.buyerService.cardVerify(formData).subscribe((data) => {
                this.commonService.hideLoader();

                if (data.status == 'fail') {
                    this.commonService.toastError(data.message);
                } else {
                    this.commonService.toastSuccess(data.message);
                    $('#cardVerifyModal').modal('hide');
                    // get all card list
                    this.getCardList();
                }

            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });

        } else {
            this.commonService.toastError(this.deductedAmount);
        }

    }
    // End

    // For add on firebase
    addOnFirebase(userData) {

        let userDataStore = {
            'user_id': userData.userID,
            'full_name': userData.full_name,
            'email': userData.email,
            'user_type': userData.user_type,
            'avatar': userData.avatar,
            'notification_status': userData.push_alert_status,
            'firebase_token': "",
            'buyer_name': userData.full_name
        }

        this.firebaseService.userUpdateFB(userData.userID, userDataStore)
    }
    // End

    // For switch profile buyer to seller
    switchProfile(userType) {
        this.commonService.showLoader();

        this.authService.switchProfile(userType).subscribe(profileData => {
            this.commonService.hideLoader();

            if (profileData.data.user_detail.switch_profile == 1) {

                // redirect to shop page
                localStorage.setItem(environment.dataStore.userData, JSON.stringify(profileData.data));
                this.updateOnFirebase(profileData.data);

                if (profileData.data.user_detail.is_email_verified == 0 && profileData.data.user_detail.is_phone_verified == 0 && Object.keys(profileData.data.business_info).length === 0) {

                    this.router.navigate(['seller/complete-switch-profile']); return false;

                }

                if (profileData.data.user_detail.is_email_verified == 0) {

                    this.router.navigate(['/seller/email-varification']);
                    return false;

                }

                else if (profileData.data.user_detail.is_email_verified == 1 && profileData.data.user_detail.is_phone_verified == 0) {
                    this.router.navigate(['/seller/number-verify']);
                    return false;

                }

                else if (profileData.business_info == "" && profileData.data.user_detail.is_email_verified == 1 && profileData.data.user_detail.is_phone_verified == 1) {
                    this.router.navigate(['/seller/add-business']); return false;
                }

                else {

                    this.router.navigate(['/seller/new-orders']); return false;
                }

            } else {
                // redirect to complete profile page
                this.router.navigate(['/complete-switch-profile']);
            }

        }, err => {
            this.commonService.hideLoader();
            this.commonService.errorhandler(err);
        });
    }
    // End

    // For update on firebase 
    updateOnFirebase(userData) {
        let userDataStore = {
            'user_id': userData.user_detail.userID,
            'full_name': userData.user_detail.full_name,
            'email': userData.user_detail.email,
            'user_type': userData.user_detail.user_type,
            'seller_name': userData.user_detail.full_name,
            'buyer_name': ""
        }

        this.firebaseService.userUpdateFB(userData.user_detail.userID, userDataStore)
    }
    // End

    // Delete user
    deleteProfile() {
        this.commonService.showLoader();

        this.buyerService.deleteUser().subscribe((data) => {
            this.commonService.toastSuccess(data.message);
            this.commonService.hideLoader();
            $("#profileDelete").modal("hide");
            this.authService.logout();
            this.router.navigate(['/login']);

        }, err => {
            this.commonService.hideLoader();
            // this.commonService.errorhandler(err);
        });
    }
    // End


    // function for reset verification
    resetVerificationForm() {
        $("#otpVerify").modal("hide");
        this.ngOnInit();
    }


}
