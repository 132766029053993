import { Component, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { faStar, faAngleDoubleRight, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { SellerService } from '../services/seller.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { FirebaseService } from '../services/firebase.service';
import { AuthService } from '../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { BuyerService } from 'src/app/buyer/services/buyer.service';
import { faImages, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';


declare const $: any;
declare const scrollDown: any;


@Component({
	selector: 'app-my-order-detail',
	templateUrl: './my-order-detail.component.html',
	styleUrls: ['./my-order-detail.component.css']
})
export class MyOrderDetailComponent implements OnInit, AfterViewChecked {
	faAngleDoubleRight = faAngleDoubleRight;
	faStar = faStar;
	faCommentDots = faCommentDots;
	orderId: any;
	orderDetail: any = {};
	orderAddress: any = {};
	trackingStatus: any = {};
	nextOrderStatusNumber: any;
	signatureImage: any = "";
	disputeList: any = [];

	coupon: any;
	couponList: any = [];
	shopService: any;
	coupon_code_id: string;
	coupon_disabled: boolean = true;
	apply_coupon_percentage: any;
	pickup = 0;
	house: any

	Total: any = '';
	itemCount: string = '';
	subtotal: any = '';
	total_shipping_price: any = '';
	tax_percent: any = '';
	tax_amount: any = '';
	discounted_amount: any = '';
	discount_percent: any = '';
	subtotal_before_discount: any = '';
	free_shipping: string;
	currentUserId: any;
	cartlistCount: any;
	influencer_code_percentage: any;
	influencer_code_amount: any;
	apply_coupon_amount: any;
	assign_coupon_amount: any;
	assign_coupon_percentage: any;
	show_ref_pop: any;
	processing_fee: any;
	coupon2: any;
	is_used: any;
	influencer_code: any;
	tax_detailPercent: any;
	responseCheck: any = 0;
	disputeData: any = {
		title: "",
		description: "",
		mediaType: '0',
		image: "",
		video: "",
		videoThumb: "",
	};
	deliverd: any = 0
	siteUrl = environment.siteUrl;
	myFile: any;
	trackImages: any = [
		{ icon: this.siteUrl + '/assets/img/common/ordered.png', orderStatus: this.translate.instant('SELLER_SIDE.order_placed') },
		{ icon: this.siteUrl + '/assets/img/common/placed.png', orderStatus: this.translate.instant('COMMON.approved_status') },
		{ icon: this.siteUrl + '/assets/img/common/packed.png', orderStatus: this.translate.instant('SELLER_SIDE.order_packed') },
		{ icon: this.siteUrl + '/assets/img/common/shipped.png', orderStatus: this.translate.instant('SELLER_SIDE.order_shipped') },
		{ icon: this.siteUrl + '/assets/img/common/zone_icon.png', orderStatus: this.translate.instant('SELLER_SIDE.order_zone') },
		{ icon: this.siteUrl + '/assets/img/common/delivered.png', orderStatus: this.translate.instant('SELLER_SIDE.order_delivered') }
	]

	@ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;

	public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
		'minWidth': 2,
		'canvasWidth': 350,
		'canvasHeight': 200,
		'backgroundColor': '#00000000'
	};
	addrestype: any;
	disputeSubscription: any;
	disputeSubscription1: any;
	DisputeFBData: any = {
		video_url: "",
		closed: 0,
	};
	faImages = faImages;
	faPaperPlane = faPaperPlane;
	disputeNode: any;
	chatList: any = [];
	message: any = "";
	imagePreview: any = "";
	disputeChatSubscription: any;
	image_url: any;
	type: any;
	showDisputeModal: string;
	deliverdImage: any;
	delivery_person_name: any = "";


	constructor(
		private activatedRoute: ActivatedRoute,
		private commonService: CommonService,
		private sellerService: SellerService,
		private router: Router,
		private firebaseService: FirebaseService,
		private authService: AuthService,
		private translate: TranslateService,
		private buyerService: BuyerService,
		private storage: AngularFireStorage,


	) { translate.use(this.commonService.getLanguage()); }




	ngOnInit() {
		this.commonService.scrollToTop();
		this.commonService.setTitle('Mercadoba | My Orders Detail');

		this.orderId = this.activatedRoute.snapshot.paramMap.get("id"); //For order id
		this.showDisputeModal = localStorage.getItem("showDisputeModal");
		localStorage.setItem("showDisputeModal", '0');


		if (this.showDisputeModal == '1') {
			// scrollDown();

			$('#dispute').modal('show');

		} else {
			$('#dispute').modal('hide');

		}


		this.currentUserId = this.authService.getCurrentUserId(); // get current user id 

		// MyOrderDetailComponent


		const str = this.router.url;
		const regexOrder = RegExp('/seller/my-order-details/');  // check url for page 

		if (regexOrder.test(str) == true) {
			$('#disputeModal').modal('show');
			// alert('hello');

		}

		this.getMyOrderDetail(this.orderId); // get order details 
		this.getDisputeList();  // get dispute info 
		this.getDisputeDetail();

	}

	// Function for order tracking status manage
	ngAfterViewChecked(): void {

		this.trackImages = [
			{ icon: this.siteUrl + '/assets/img/common/ordered.png', orderStatus: this.translate.instant('SELLER_SIDE.order_placed') },
			{ icon: this.siteUrl + '/assets/img/common/placed.png', orderStatus: this.translate.instant('COMMON.approved_status') },
			{ icon: this.siteUrl + '/assets/img/common/packed.png', orderStatus: this.translate.instant('SELLER_SIDE.order_packed') },
			{ icon: this.siteUrl + '/assets/img/common/shipped.png', orderStatus: this.translate.instant('SELLER_SIDE.order_shipped') },
			{ icon: this.siteUrl + '/assets/img/common/zone_icon.png', orderStatus: this.translate.instant('SELLER_SIDE.order_zone') },
			{ icon: this.siteUrl + '/assets/img/common/delivered.png', orderStatus: this.translate.instant('SELLER_SIDE.order_delivered') }

		]


		if ((this.orderDetail.current_status_number == '7' || this.orderDetail.current_status_number == '8' || this.orderDetail.current_status_number == '9' || this.orderDetail.current_status_number == '10' || this.orderDetail.current_status_number == '11')) {
			this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.return_initiated') });
			this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.return_inprocess') });
			this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.collect_by_store') });
			this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.received_by_seller') });
			this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/refund.png', orderStatus: this.translate.instant('SELLER_SIDE.return_and_refunded') });
		}
	}
	// End


	// get Dispute chat from firebase 
	getDisputeChat() {
		this.disputeNode = `${this.orderId}-${this.authService.getCurrentUserId()}-${this.orderDetail.ordered_by_user_id}`;

		this.disputeChatSubscription = this.firebaseService.getDisputeChat(this.disputeNode).subscribe(data => {
			// scrollDown();
			this.chatList = data;
			this.chatList.forEach(element => {
				element.centerTime = this.commonService.chatGroupTime(element.timestamp);
			});

		});
	}
	// End
	confirmationOrderCancel(orderId, orderNumber) {
		Swal.fire({
			title: this.translate.instant('MYORDER.are_you_sure'),
			text: this.translate.instant('MYORDER.You_want_to_cancel_this_order') + orderNumber,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: this.translate.instant('FOOTER.ok_button'),
			cancelButtonText: this.translate.instant('COMMON.cancel'),
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				var id = parseFloat(orderId)
				this.cancelOrder()
				//   this.acceptReject(status, orderId, licenseStatus);
			}
		});


	}

	cancelOrder() {
		// alert('hello')

		const formData = new FormData();
		formData.append('order_id', this.orderId);
		formData.append('note', "");
		formData.append('cancel_by', "seller");


		this.commonService.showLoader();  // show loader

		this.sellerService.cancelOrder(formData).subscribe(data => {

			if (data.status == 'success') {
				this.commonService.hideLoader();
				this.commonService.toastSuccess(data.message);
				this.getMyOrderDetail(this.orderId); // get order details 
			} else {
				this.commonService.hideLoader();
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		})

	}
	//  draw Signature 
	drawComplete() {
		// will be notified of szimek/signature_pad's onEnd event
		var block = this.signaturePad.toDataURL().split(";");
		// Get the content type of the image
		var contentTypes = block[0].split(":")[1];// In this case "image/png"
		// get the real base64 content of the file
		var realData = block[1].split(",")[1];
		var blobImg = b64toBlob(realData, contentTypes, '');

		this.signatureImage = blobImg;
	}
	// End

	//  clear signature 
	clearSignaturePad() {
		this.signaturePad.clear();
		this.signatureImage = "";
	}
	// End


	// get dispute list  for titile
	getDisputeList() {
		this.sellerService.getDisputeList().subscribe(data => {

			if (data.status == 'success') {
				this.disputeList = data.data.dispute_title;
				this.disputeData.title = this.disputeList[0].title;
			}

		}, err => {
			this.commonService.errorhandler(err);
		});

	}
	// End

	//  get dispute details from firebse 
	getDetailsFirebase() {
		//  node 
		let node = `${this.orderId}-${this.authService.getCurrentUserId()}-${this.orderDetail.ordered_by_user_id}`;

		if (node != null) {
			this.disputeSubscription = this.firebaseService.getDisputeDetail(node).subscribe(data => { // get detail

				if (data.key == "detail") {
					this.DisputeFBData = data.payload.val();
					this.commonService.hideLoader();  // hide loader
				}

				this.ngOnDestroy();  // ng distroy hook life cycle 

			});

		}
	}
	// End

	ngOnDestroy(): void {
		this.disputeSubscription.unsubscribe();
		$('#trackModal').modal('hide');
		$('#orderStatusImageShow').modal('hide');

		Swal.close();
	}


	// For get dispute details 
	getDisputeDetail() {

		this.disputeNode = `${this.orderId}-${this.authService.getCurrentUserId()}-${this.orderDetail.ordered_by_user_id}`;


		if (this.disputeNode != null) {
			this.disputeSubscription = this.firebaseService.getDisputeDetail(this.disputeNode).subscribe(data => {
				if (data.key == "detail") {
					this.DisputeFBData = data.payload.val();


				} else {
					// this.router.navigate(['../my-orders']);
				}
			});

		} else {
			// this.router.navigate(['../my-orders']);
		}
	}
	// End

	// get my order detail : ---
	getMyOrderDetail(orderId) {
		this.commonService.showLoader();
		this.sellerService.getNewOrderDetail(orderId).subscribe(myOrderData => {
			this.commonService.hideLoader();

			if (myOrderData.status == 'success') {
				this.responseCheck = 1;
				this.orderDetail = myOrderData.data.order_detail;
				this.getDetailsFirebase();  // method  for  get dispute detail 
				this.getDisputeChat(); // get dispute chat 
				this.getDisputeDetail();

				// if (myOrderData.data.order_detail.order_address == null) {
				// 	this.addrestype = 1;
				// 	this.orderAddress = myOrderData.data.order_detail.order_seller_address;
				// 	this.house = "";

				// } else {
				// 	this.addrestype = 0;

				// 	this.orderAddress = myOrderData.data.order_detail.order_address;
				// 	this.house = this.orderAddress.house_number + ', ';

				// }



				if (myOrderData.data.order_detail.order_shipping_type == 1) { // delivery
					this.addrestype = 1;
					// this.orderAddress = myOrderData.data.order_detail.order_seller_address;
					// this.house = "";
					this.orderAddress = myOrderData.data.order_detail.order_address;
					this.house = this.orderAddress.house_number + ', ';

				} else {
					this.addrestype = 0; //pickup
					this.orderAddress = myOrderData.data.order_detail.order_address;
					this.house = this.orderAddress.house_number + ', ';

				}

				this.trackingStatus = this.orderDetail.tracking_status;
				this.coupon = this.orderDetail.assign_coupon_percentage.replace(/\.00$/, '');
				this.coupon2 = this.orderDetail.apply_coupon_percentage.replace(/\.00$/, '');
				this.apply_coupon_amount = this.orderDetail.apply_coupon_amount;
				this.apply_coupon_percentage = this.orderDetail.apply_coupon_percentage;
				this.assign_coupon_amount = this.orderDetail.assign_coupon_amount;
				this.assign_coupon_percentage = this.orderDetail.assign_coupon_percentage;
				this.influencer_code_amount = this.orderDetail.influencer_code_amount;
				this.influencer_code_percentage = this.orderDetail.influencer_code_percentage;
				this.influencer_code_percentage = this.influencer_code_percentage.replace(/\.00$/, '');
				this.tax_detailPercent = this.orderDetail.tax_percent;
				this.processing_fee = this.orderDetail.transaction_fee_percent_amount * 1 + this.orderDetail.transaction_fee_flat * 1;
				this.processing_fee = this.processing_fee.toFixed(2);  // get  2 digit after decimal 

				//  check order status 
				if (this.orderDetail.current_status_number == '4') {
					this.nextOrderStatusNumber = 19
					this.deliverd = 1;
				}
				else {

					this.deliverd = 0;

					if (this.orderDetail.current_status_number == '7') {
						this.nextOrderStatusNumber = 7
					}


					else if (this.orderDetail.current_status_number == '8') {
						this.nextOrderStatusNumber = 19
					}


					else if (this.orderDetail.current_status_number == '9') {
						this.nextOrderStatusNumber = 9
					}

					else {


						this.nextOrderStatusNumber = (this.orderDetail.current_status_number == '11') ? Number(this.orderDetail.current_status_number) : Number(this.orderDetail.current_status_number) + Number(1);

					}
				}

				if ((this.orderDetail.current_status_number == '7' || this.orderDetail.current_status_number == '8' || this.orderDetail.current_status_number == '9' || this.orderDetail.current_status_number == '10' || this.orderDetail.current_status_number == '11')) {
					this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.return_initiated') });
					this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.return_inprocess') });
					this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.collect_by_store') });
					this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: this.translate.instant('SELLER_SIDE.received_by_seller') });
					this.trackImages.push({ icon: this.siteUrl + '/assets/img/common/refund.png', orderStatus: this.translate.instant('SELLER_SIDE.return_and_refunded') });
				}
			}
		}, err => {
			this.responseCheck = 0;

			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}
	// End
	resloved_dispute_confirm() {

		Swal.fire({
			// title: this.translate.instant('MYORDER.are_you_sure'),
			text: this.translate.instant('MYORDER.You_want_to_reslove_this_order'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: this.translate.instant('FOOTER.ok_button'),
			cancelButtonText: this.translate.instant('COMMON.cancel'),
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.resloved_dispute()
				//   this.acceptReject(status, orderId, licenseStatus);
			}
		});
	}

	//  function for resloved dispute api 
	// @param order_id , favour_of , user_type 
	resloved_dispute() {

		const formData = new FormData();
		formData.append('order_id', this.orderId);
		formData.append('favour_of', this.orderDetail.ordered_by_user_id);
		formData.append('user_type', "buyer");

		this.commonService.showLoader();  // show loader

		this.buyerService.reSlovedDispute(formData).subscribe(Resloveddata => {

			if (Resloveddata.status == "success") {

				let node = `${this.orderId}-${this.authService.getCurrentUserId()}-${this.orderDetail.ordered_by_user_id}`;
				if (node != null) {
					this.disputeSubscription = this.firebaseService.getDisputeDetail(node).subscribe(data => {

						if (data.key == "detail") {
							this.DisputeFBData = data.payload.val();
							this.commonService.hideLoader();
							let buyerId = this.orderDetail.ordered_by_user_id;

							this.commonService.toastSuccess(Resloveddata.message);
							this.disputeSubscription1 = this.firebaseService.disputeUpdateFb(this.orderDetail.ordered_by_user_id, this.DisputeFBData)
							this.getMyOrderDetail(this.orderId);

						} else {
							this.router.navigate(['../my-orders']);
						}

						this.ngOnDestroy();

					});

				} else {
					this.router.navigate(['../my-orders']);
				}


			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		})




	}
	// End

	// For order time 
	getOrderTimeShow(dateTime, string) {

		return this.commonService.getOrderTime(dateTime, string);
	}


	// For order time 
	getOrdeDate(dateTime, string) {

		return this.commonService.getOrderDate(dateTime, string);
	}

	getDisputeTimeShow(dateTime) {

		return this.commonService.getDisputeTimeShow(dateTime);
	}

	closeTrackModel() {
		$('#file-2').val('');
		$('#pImg').attr("src", "/assets/img/delivery-icon.png");
		$('#trackModal').modal('hide');

	}
	// // Function for change order status button
	changeOrderStatusDelivery(nextOrderStatusNumber) {


		if (this.delivery_person_name == undefined || this.delivery_person_name == "") {
			// this.delivery_person_name = "";
			this.commonService.toastError(this.translate.instant('MYORDER.please_enter_delivery_person_name'));
			return false;
		}
		var image = $('#pImg').attr('src');
		console.log('image', image);

		if (image == '/assets/img/delivery-icon.png') {
			this.commonService.toastError(this.translate.instant('MYORDER.please_add_proof'));
			return false;
		}

		// fetch(image)
		// 	.then((res) => res.blob())
		// 	.then((myBlob) => {
		// 		var myFile = new File([myBlob], 'signature.png', { type: myBlob.type });
		// 		console.log(myFile);
		// 		this.myFile = myFile;
		// 	});
		const imageUrl = 'https://example.com/path/to/image.jpg'; // Your image URL

		fetch(image)
			.then((res) => res.blob())
			.then((myBlob) => {
				// Extract the MIME type from the blob
				const mimeType = myBlob.type;
				console.log('mimeType:', mimeType);

				// Get the extension based on MIME type
				const extension = mimeType.split('/')[1]; // For example, 'image/png' -> 'png'

				// Extract the file name and extension from the image URL
				const urlParts = image.split('/');
				const originalFileName = urlParts[urlParts.length - 1]; // Get the last part of the URL
				const nameWithoutExtension = originalFileName.split('.').slice(0, -1).join('.');
				const originalExtension = originalFileName.split('.').pop(); // Get the extension from the file name

				console.log('Original File Name:', originalFileName);
				console.log('Original Extension:', originalExtension);

				// Use the original file name or create a new one dynamically
				const fileName = `${nameWithoutExtension}.${extension}`;

				// Create the file object
				const myFile = new File([myBlob], fileName, { type: mimeType });

				console.log('Created File:', myFile);
				this.myFile = myFile;
			})
			.catch((error) => {
				console.error('Error during fetch or file creation:', error);
			});



		this.commonService.showLoader();


		setTimeout(() => {
			this.commonService.hideLoader();

			if (nextOrderStatusNumber == '5' || nextOrderStatusNumber == '9') {


				// if (this.myFile == undefined) {
				// 	this.commonService.toastError(this.translate.instant('MYORDER.please_add_buyer_signature'));
				// 	return false;
				// }

				if (this.myFile != "") {
					this.commonService.hideLoader();




					// nextOrderStatusNumber = (this.orderDetail.current_status_number == '7') ? Number(nextOrderStatusNumber) + Number(1) : nextOrderStatusNumber;
					const formData = new FormData();
					formData.append('order_id', this.orderId);
					formData.append('status', escape(nextOrderStatusNumber));
					formData.append('signature', this.myFile);
					formData.append('delivery_person_name', this.delivery_person_name);

					this.commonService.showLoader();

					this.buyerService.addSignature(formData).subscribe(orderData => {

						this.commonService.hideLoader();

						if (orderData.status == 'success') {
							$('#trackModal').modal('hide');
							setTimeout(() => {
								window.location.reload();
							}, 500);

						}

					}, err => {
						this.commonService.hideLoader();
						this.commonService.errorhandler(err);
					});

				} else {
					this.commonService.toastError(this.translate.instant('MYORDER.please_add_buyer_signature'));
				}

			} else {

				nextOrderStatusNumber = (this.orderDetail.current_status_number == '5') ? Number(nextOrderStatusNumber) + Number(1) : nextOrderStatusNumber;

				let statusData = `status=${escape(nextOrderStatusNumber)}`;

				this.commonService.showLoader();


			}
		}, 1000);





	}
	// End


	// Function for order status change
	changeOrderStatus(nextOrderStatusNumber) {

		if (nextOrderStatusNumber == 7) {
			nextOrderStatusNumber = 8;
		}

		if (nextOrderStatusNumber == 9) {
			nextOrderStatusNumber = 10;
		}

		let statusData = `status=${escape(nextOrderStatusNumber)}`;

		this.commonService.showLoader();

		this.sellerService.changeOrderStatus(this.orderId, statusData).subscribe(orderData => {
			this.commonService.hideLoader();

			if (orderData.status == 'success') {

				$('#trackModal').modal('hide');

				const removeValFromIndex = [6, 7, 8, 9, 10];
				const indexSet = new Set(removeValFromIndex);
				this.trackImages = this.trackImages.filter((value, i) => !indexSet.has(i));
				this.getMyOrderDetail(this.orderId);

			}
		}, err => {
			this.commonService.hideLoader(); //Hide loader
			this.commonService.errorhandler(err); // Error show 
		});

	}
	// End

	fileSectionReset() {
		this.disputeData.mediaType = '0';
		this.disputeData.image = '';
		this.disputeData.video = '';
		this.disputeData.videoThumb = '';
	}

	// For dispute image upload 
	onSelectedFile(event) {
		if (event.target.files.length > 0) {
			this.commonService.showLoader();

			const file = event.target.files[0];
			var ext = file.name.split('.').pop().toLowerCase();

			if ($.inArray(ext, ['gif', 'png', 'jpg', 'jpeg']) == -1) {
				this.commonService.hideLoader();
				Swal.fire(this.translate.instant('MYORDER.ops_txt'), this.translate.instant('MYORDER.invalid_extension'), this.translate.instant('MYORDER.error_txt'));
				return false;
			}



			const filePath = environment.storageBucketFile + Date.now();
			const fileRef = this.storage.ref(filePath);

			const task = this.storage.upload(filePath, file).then(() => {
				const ref = this.storage.ref(filePath);
				const downloadURL = ref.getDownloadURL().subscribe(url => {

					this.image_url = url;
					this.commonService.hideLoader();

					let disputeArray = this.disputeNode.split('-');

					let chatMessage = {
						buyer_id: disputeArray[2],
						image: 1,
						image_url: url,
						last_sender_id: disputeArray[1],
						message: "",
						msg_read_tick: 0,
						order_id: disputeArray[0],
						seller_id: disputeArray[1],
						timestamp: Date.now()
					}

					this.firebaseService.sendDisputeMessage(this.disputeNode, chatMessage, this.orderDetail, url);
					$('#myDIV-2').animate({
						scrollTop: $("#myDIV-2").offset().top + $('#myDIV-2').prop("scrollHeight")
					});
					this.disputeCountForAdmin();



				});
			});
		}
	}
	// End

	// function used for upload image for make dispute
	onSelectedFileMakeDispute(event) {
		this.fileSectionReset();

		if (event.target.files.length > 0) {
			const file = event.target.files[0];

			const name = event.target.files[0].name;
			const lastDot = name.lastIndexOf('.');

			const fileName = name.substring(0, lastDot);
			const ext = name.substring(lastDot + 1);

			if (ext == "gif" || ext == "png" || ext == "jpeg" || ext == "jpg" || ext == "mp4" || ext == "MP4" || ext == "mov" || ext == "MOV") {

				if (ext == "mp4" || ext == "MP4" || ext == "mov" || ext == "MOV") { // If video uploaded

					this.commonService.generateThumbnail(file).then(thumbnailData => {

						let block = thumbnailData.split(";");
						// Get the content type of the image
						let contentTypes = block[0].split(":")[1];// In this case "image/png"
						// get the real base64 content of the file
						let realData = block[1].split(",")[1];
						let videoThumb = b64toBlob(realData, contentTypes, '');

						this.disputeData.mediaType = '2';
						this.disputeData.video = file;
						this.disputeData.videoThumb = videoThumb;

					}).catch(error => {
						console.log(error);
					});

				} else { // if image uploaded
					this.disputeData.image = file;
					this.disputeData.mediaType = '1';
				}

			} else {
				event.target.value = '';
				this.commonService.toastError(this.translate.instant('MYORDER.please_select_right_format_for_this_file'));
			}

		}

	}
	// End 

	//reset Dispute
	resetDispute() {
		$('.descrip').val('');
	}
	// End

	// Function used for dispute create
	disputeCreate() {

		if ($('.descrip').val().trim() == '') {
			this.commonService.toastError(this.translate.instant('REPORTMODEL.description_required')); return false;
		}
		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('dispute_for', this.orderId);
		formData.append('title', this.disputeData.title);
		formData.append('description', this.disputeData.description);
		formData.append('media_type', this.disputeData.mediaType);

		if (this.disputeData.mediaType == '1') {
			formData.append('dispute_media', this.disputeData.image);
		} else if (this.disputeData.mediaType == '2') {
			formData.append('dispute_media', this.disputeData.video);
			formData.append('video_thumb', this.disputeData.videoThumb, 'videoThumb.png');
		}

		this.sellerService.disputeCreate(formData).subscribe(disputeData => {
			this.commonService.hideLoader();

			if (disputeData.status == 'success') {
				this.commonService.toastSuccess(this.translate.instant('MYORDER.dispute_has_been_created_successfully'));
				this.disputeAddOnFb(disputeData.data.dispute_detail);
				$('#disputeModal').modal('hide');
				this.trackImages = [];
				this.trackImages = [
					{ icon: this.siteUrl + '/assets/img/common/ordered.png', orderStatus: this.translate.instant('SELLER_SIDE.order_placed') },
					{ icon: this.siteUrl + '/assets/img/common/placed.png', orderStatus: 'Approved' },
					{ icon: this.siteUrl + '/assets/img/common/packed.png', orderStatus: 'Packed' },
					{ icon: this.siteUrl + '/assets/img/common/shipped.png', orderStatus: 'Shipped' },
					{ icon: this.siteUrl + '/assets/img/common/zone_icon.png', orderStatus: 'Zone' },
					{ icon: this.siteUrl + '/assets/img/common/delivered.png', orderStatus: 'Delivered' }
				]
				this.ngOnInit();
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End

	// Function used for dispute add on firebase 
	disputeAddOnFb(data) {
		let chatData = {
			admin_id: data.adminID,
			closed: 0,
			closed_timestamp: 0,
			description: data.description,
			dispute_by: data.dispute_by,
			dispute_by_user_type: data.dispute_by_user_type,
			dispute_order_id: this.orderId,
			favour_of: "",
			favour_of_user_type: "",
			resloved_by: "",
			resloved_of_user_type: "",
			image_url: data.image,
			reason: data.dispute_title,
			timestamp: Date.now(),
			video_thumb_url: data.video_thumb,
			video_url: data.video
		}
		let buyerId = this.orderDetail.ordered_by_user_id;

		this.firebaseService.disputeAddOnFb(buyerId, chatData)
	}
	// End


	// Function used for dispute details
	disputeDetail() {
		let node = `${this.orderId}-${this.authService.getCurrentUserId()}-${this.orderDetail.ordered_by_user_id}`;
		this.router.navigate(['seller/dispute/', node]);
	}
	// End


	// code for chat work :: -----
	getTimeAgo(timestamp) {
		return this.commonService.chatHistoryTimeAgo(timestamp);
	}

	// Function for image view in full size
	imagePreviewModal(imageSrc) {
		$('#imgFullViewModal').modal('show');
		this.imagePreview = imageSrc;
	}
	// End

	//Function for dispute notification count for admin 
	disputeCountForAdmin() {
		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('order_id', this.orderDetail.orderID);

		this.buyerService.adminDisputeisRead(formData).subscribe((data) => {
			this.commonService.hideLoader();
		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End

	//For send dispute notification 
	sendMsg() {

		const el = $('#myDIV-2');

		// ✅ Using optional chaining
		// const result1 = el?.offset()?.top;
		if (this.message.trim()) {
			let disputeArray = this.disputeNode.split('-');

			let chatMessage = {
				buyer_id: disputeArray[2],
				image: 0,
				image_url: "",
				last_sender_id: disputeArray[1],
				message: this.message.trim(),
				msg_read_tick: 0,
				order_id: disputeArray[0],
				seller_id: disputeArray[1],
				timestamp: Date.now()
			}

			// add data on firebase 
			this.firebaseService.sendDisputeMessage(this.disputeNode, chatMessage, this.orderDetail);
			$('#myDIV-2').animate({
				scrollTop: $("#myDIV-2").offset().top + $('#myDIV-2').prop("scrollHeight")
			});

			this.disputeCountForAdmin();
		}

		this.message = "";
	}
	// End


	// show image preview

	showImage(image) {
		this.deliverdImage = image;
		$('#orderStatusImageShow').modal('show');

	}

	validateChar(input) {
		if (/^\s/.test(input.target.value))
			input.target.value = '';
		this.delivery_person_name = input.target.value
	}

}

function b64toBlob(b64Data, contentType, sliceSize) {
	contentType = contentType || '';
	sliceSize = sliceSize || 512;

	var byteCharacters = atob(b64Data);
	var byteArrays = [];

	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);

		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	var blob = new Blob(byteArrays, { type: contentType });
	return blob;
}


