import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { faAngleDoubleRight, faStar, faStarHalfAlt, faShoppingCart, faCheck } from '@fortawesome/free-solid-svg-icons';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { faCommentDots, faStar as faStarBlank, faHeart } from '@fortawesome/free-regular-svg-icons';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { CommonService } from '../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { ShopService } from '../services/shop.service';
import { HeaderBuyerComponent } from '../common/header-buyer/header-buyer.component';
import { FirebaseService } from '../services/firebase.service';
import { FirebaseService as BuyerFbService } from '../buyer/services/firebase.service';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { BuyerService } from '../buyer/services/buyer.service';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { Console } from 'console';
import { exit } from 'process';


declare const $: any;

@Component({
	selector: 'app-product-detail',
	templateUrl: './product-detail.component.html',
	styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {

	faAngleDoubleRight = faAngleDoubleRight;
	faStar = faStar;
	faStarBlank = faStarBlank;
	faStarHalfAlt = faStarHalfAlt;
	faCommentDots = faCommentDots;
	faShoppingCart = faShoppingCart;
	faCheck = faCheck;
	faHearts = faHeart;
	faHeartr = faHeart;
	productId: string = '';
	variantId: string = '';
	productDetail: any = {};
	currentUserId: any;
	similarProduct: any = [];
	galleryOptions: NgxGalleryOptions[];
	galleryImages: any = [];
	galleryImage: any = [];
	variants: any = [];
	variantListData: any = [];
	variant: string = '';
	offerPrice: any;
	firebaseToken: any = '';
	keyFeatureList: any = [];
	productPrice: any = 0;
	productPriceTemp: any = 0;
	discount_percent: any = 0;
	productVariantID: any = 0;
	is_wishlist: any = 0;
	is_qvazon_warranty_applied: any = 0;
	qvazon_warranty_percent: any = 0;
	isCorrectVariantCombination: boolean = false;
	qvazon_warranty_use: any = 0;
	seller_province: any = [];
	productRegularPrice: any = 0;
	previewHide: boolean = true;
	imagesPreview: any;
	questionForm: FormGroup;
	loginForm: FormGroup;
	reportForm: FormGroup;
	questionAnswer: any;
	variantIdGet: any;
	variant_price: any;
	varQuantity: any;
	makeOfferForm: FormGroup;
	variant_id;
	variantDataForCard;
	key = "0";
	variantDAtaList: any = [];
	duplicateids: any = [];
	flag = 0;
	reportTitle: any = [];
	make_an_offerkey: any;
	quantityShow: any = 0;
	showQuestionAnsButton: any;
	remaingQuestion: any;
	productVariantQuantity: any =1;
	getOfferAmountValue: any;
	checkClass : any = 0;

	ProductSliderOptions: OwlOptions = {
		loop: false,
		margin: 30,
		items: 1,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		nav: true,
		dots: false,
		navSpeed: 500,
		//autoWidth:true,
		navText: ['<i class="las la-angle-left"></i>', '<i class="las la-angle-right"></i>'],
	
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 2
			},
			767: {
				items: 3
			},
			992: {
				items: 4
			}
		}
	}

	ProductSliderSameOptions: OwlOptions = {
		loop: false,
		margin: 30,
		items: 1,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		nav: true,
		dots: false,
		navSpeed: 500,
		//autoWidth:true,
		navText: ['<i class="las la-angle-left"></i>', '<i class="las la-angle-right"></i>'],
	
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 2
			},
			767: {
				items: 3
			},
			992: {
				items: 4
			}
		}
	}

	@ViewChild('header', { static: true }) header: HeaderBuyerComponent;
	@ViewChild('myForm', { static: true }) form: any;
	@ViewChild('offerForm', { static: true }) offerForm: any;

	packages: any;
	third: any[];
	isPackage: boolean = false;
	axz: any;
	fruits: any = [];
	fruits_new: any = [];
	variantsnew: any = [];
	ids: any = [];
	product_variant_id: any;
	checked: false;
	productVariantIDWithVAriant: any;
	productOfferPrice: any;
	productSellingPrice: any;
	discountPercent: any;
	priceeeeeee: any;
	quantity: any;
	quantityValue: number;
	makeAnOfferImage: any;
	offer_status: any = 0;
	newIdsVarinatsComma: any =[];
	NewVarnn: any;
	newVariants: any;
	questionSectionShow: number;
	categoriesArray: any;
	noVariant: any;
	variantsId: any;
	storeProduct: any=[];
	constructor(
		private activatedRoute: ActivatedRoute,
		private commonService: CommonService,
		private authService: AuthService,
		private shopService: ShopService,
		private firebaseService: FirebaseService,
		private buyerFbService: BuyerFbService,
		private formBuilder: FormBuilder,
		private router: Router,
		private translate: TranslateService,
		private buyerService: BuyerService
	) { translate.use(this.commonService.getLanguage()); }

	ngOnInit() {

		
		this.quantityShow =0;
		this.commonService.scrollToTop();
		this.commonService.setTitle('Mercadoba | Product Detail');
		this.currentUserId = this.authService.getCurrentUserId(); //current user ID
		this.setConfigImageGallery();

		// Get router parameter 
		this.activatedRoute.paramMap.subscribe(params => {
			this.productId = params.get("productId");
			this.galleryImages = [];
			// this.productId = atob(params.get("productId"));
			// const decrypted = CryptoJS.AES.decrypt(this.productId, 'productId');
			// var ids =  decrypted.toString(CryptoJS.enc.Utf8);	
			// var a = ids.trim();

			this.getProductDetail(this.productId,this.currentUserId);

			if (this.authService.isLoggedIn()) {
				this.reportTitleget();
			}
		});
		// End

		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required]],
		})

		// Add questions form data
		this.questionForm = this.formBuilder.group({
			description: ['', [Validators.required]],
			// description: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(200)]],

		});
		// End

		// For make an offer form validation 
		this.makeOfferForm = this.formBuilder.group({
			offer_price: ['', [Validators.required]],
		})
		// End

		// For report form validation
		this.reportForm = this.formBuilder.group({
			report_title: ['', [Validators.required]],
			report_description: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(200)]],
		})
		// End

	}
	

	get description() { return this.questionForm.get('description'); }
	get offer_price(): any  { return this.makeOfferForm.get('offer_price'); }



	get email(): any { return this.loginForm.get('email'); }

	// report form 
	get report_title(): any { return this.reportForm.get('report_title'); }
	get report_description(): any { return this.reportForm.get('report_description'); }


	setConfigImageGallery() {
		this.galleryOptions = [
			{
				width: '350px',
				height: '550px',
				thumbnailsColumns: 4,
				imageAnimation: NgxGalleryAnimation.Slide,
				previewZoom: true,
			},
			// max-width 800
			{
				breakpoint: 800,
				width: '100%',
				height: '600px',
				imagePercent: 80,
				thumbnailsPercent: 20,
				thumbnailsMargin: 20,
				thumbnailMargin: 20
			},
			// max-width 400
			{
				breakpoint: 300,
				preview: false
			}
		];
	}

	

	// For navigare router
	questionAnswerLoad() {
		this.router.navigate(['/question-answer', this.productId]);
	}


	transform(encrypted: string) {
		if (encrypted) {
		  const decrypted = CryptoJS.AES.decrypt(encrypted, 'productId');
		  console.log('decrypted.toString(CryptoJS.enc.Utf8)', decrypted.toString(CryptoJS.enc.Utf8))
		//   return decrypted.toString(CryptoJS.enc.Utf8);
		}
	}
	// End

	// For report title get
	reportTitleget() {
		this.shopService.reportTitle().subscribe((data) => {
			this.commonService.showLoader();
			this.commonService.hideLoader();
			if (data.status == "fail") {
				this.commonService.toastError(data.message);
			} else {
				this.reportTitle = data.data;
				// this.commonService.toastSuccess(data.message);
			}
		}, err => {
			this.commonService.errorhandler(err);

		});

	}

	OpenReportModel(){
		if (this.authService.isLoggedIn()) {

			$('#reportmodel').modal('show');
		}else{
			// this.commonService.toastWarning(this.translate.instant('SHOP.please_login_before_report'));
			this.router.navigate(['/login']);
		}
		

	}
	// End

	// For report form data submit
	reportFormSubmit() {
		// alert('hello Report');
		if (this.reportForm.valid) {
			const formData = new FormData();

			formData.append('product_id', this.productId);
			formData.append('description', this.reportForm.get('report_description').value);
			formData.append('title_id', this.reportForm.get('report_title').value);

			// return false;
			this.commonService.showLoader();

			this.shopService.reportProduct(formData).subscribe((data) => {
				this.commonService.showLoader();

				this.commonService.hideLoader();
				if (data.status == "fail") {
					this.commonService.toastError(data.message);
				} else {
					$('#reportmodel').modal('hide');
					this.reportForm.reset();


					this.commonService.toastSuccess(data.message);

				}
			}, err => {
				this.commonService.errorhandler(err);

			});
		} else {
			this.validateAllFormFields(this.reportForm); //{7}

		}
	}
	// End

	// For close and reset report model
	closeReportModel() {
		$('#reportmodel').modal('hide');
		this.reportForm.reset();
	}
	// End

	// Function for rating
	createRatingRange(number) {
		return this.commonService.createRatingRange(number);
	}

	createRatingBlank(number) {
		return this.commonService.createRatingBlank(number);
	}

	isFloat(number) {
		return this.commonService.isFloat(number);
	}
	// End


	// empty value validation 
	trimValue(formControl) {
		((typeof formControl.value) && formControl.value.trim() == "") ?
			formControl.setValue("") : formControl.setValue(formControl.value);
	}

	// For variant data get
	variantDataaa(index) {
		this.variantDAtaList = [];
		this.galleryImages = [];
		this.variant_id = this.productDetail.variant_list[index].productVariantID;
		this.variantDataForCard = this.productDetail.variant_list[index];
		this.galleryImage = this.productDetail.variant_list[index].variant_image;

		var image;
		this.productDetail.variant_list.forEach(element => {
			var image = JSON.parse(element.image_json);
			element.image1 = image[0];
			this.variantDAtaList.push(element);
		});




		this.galleryImage.forEach(element => {
			let galleryImagesData = {
				small: `${element.variant_image_url}${element.variant_image}`,
				medium: `${element.variant_image_url}${element.variant_image}`,
				big: `${element.variant_image_url}${element.variant_image}`
			};
			this.galleryImages.push(galleryImagesData);


		});
	}
	// End

	//Get product Detail
	getProductDetail(productId, currentUserId) {
		this.commonService.showLoader();
		this.shopService.getProductDetail(productId, currentUserId).subscribe((productDetailData) => {
			console.log('productDetailData', productDetailData.data.product_detail.variant_list[0]);
			
			// if(productDetailData.data.product_detail.variants_options.length >0){
				this.noVariant = productDetailData.data.product_detail.is_no_variants;


				if(this.noVariant == 1){
					this.quantityShow =1;
					this.quantityValue = 1;
					this.productVariantQuantity = productDetailData.data.product_detail.variant_list[0].quantity;

					// this.makeAnOfferImage = productDetailData.data.product_detail.variant_list[0].variant_image[0];

				}

			

			this.commonService.hideLoader();
			if (productDetailData.status == 'success') {
				this.commonService.scrollToTop();
				this.productDetail = productDetailData.data.product_detail;
				this.productPrice = productDetailData.data.product_detail.regular_price;
				this.productRegularPrice = productDetailData.data.product_detail.regular_price;
				var questionAnswer = this.productDetail.question;
				this.categoriesArray  = this.productDetail.categoriesArray 

				var productNameForMeta = this.productDetail.name + "Product name  - Entregas en Cuba en 72horas. and in Meta add in end. - Entrega en toda Cuba."

				this.commonService.getMetaTages('Explora Nuestras Categorías de Productos con Mercadoba | Compra en Línea en Cuba',productNameForMeta);


				if(questionAnswer.length == 0){
					this.questionSectionShow = 0;
				}else{
					this.questionSectionShow = 1;

				}

				if (questionAnswer.length <= 4) {
					this.showQuestionAnsButton = 0
				} else {
					this.showQuestionAnsButton = 1
					this.remaingQuestion = questionAnswer.length - 4;
				}


				var filtered = questionAnswer.filter(function (value, index, arr) {
					// return index !=0 && index <=8;
					return index <= 3;
				});

				this.questionAnswer = filtered;
				this.variants = this.productDetail.variants_options;
				this.variantsnew = this.productDetail.variants_options;
				this.productOfferPrice = this.productDetail.offer_selling_price;
				this.productSellingPrice = this.productDetail.selling_price;
				this.discountPercent = Math.round(this.productDetail.discount_percent);
				if(this.noVariant == 1){
					this.make_an_offerkey = 0
					// this.productVariantQuantity = 1;

				}else{
					// this.productVariantQuantity = this.productDetail.quantity;

				}
				// this.similarProduct = this.productDetail.similar_products;
				this.similarProduct =[];

				this.productDetail.similar_products.forEach(element => {
					element.discount_percent = Math.round(element.discount_percent);
					this.similarProduct.push(element);
				});

				// same store related product
				this.storeProduct =[];

				this.productDetail.same_seller_product.forEach(element => {
					element.discount_percent = Math.round(element.discount_percent);
					this.storeProduct.push(element);
				});
				
				// this.promotedProduct = this.productDetail.similar_products;
				this.keyFeatureList = (this.productDetail.key_features) ? JSON.parse(this.productDetail.key_features) : [];
				this.packages = (this.productDetail.packages) ? JSON.parse(this.productDetail.packages) : [];
				if (this.packages.length == 0) {
					this.isPackage = false;
				} else {
					this.isPackage = true;
				}

				this.seller_province = this.productDetail.seller_province.split(',');
				this.productDetail.variant_list.forEach(element1 => {
					let newKey = "";
					this.variantIdGet = element1.productVariantID;
					this.variant_price = element1.variant_product_price;
					this.varQuantity = element1.quantity;

					element1.variant_value.forEach(element2 => {
						newKey += element2.variantValueID;
					});

					element1.variantValuesString = newKey;
				});

				// if (this.similarProduct.length > 4) {
				// 	this.ProductSliderOptions.loop = true;
				// }


				// if (this.similarProduct.length > 4) {
				// 	this.ProductSliderOptions.loop = true;
				// }

				this.galleryImages =[];

				this.galleryImage = this.productDetail.gallery_images;
				// this.galleryImage.unshift({ 'gallery_image': this.productDetail.feature_image, 'gallery_image_url': this.productDetail.feature_image_url });

				this.galleryImage.forEach(element => {
					let galleryImagesData = {
						small: `${element.gallery_image_url}${element.gallery_image}`,
						medium: `${element.gallery_image_url}${element.gallery_image}`,
						big: `${element.gallery_image_url}${element.gallery_image}`
					};
					this.galleryImages.push(galleryImagesData);
				});

				this.getFirebaseToken();
				// this.setVariantData();

				this.setViewCount();
			} else {

				this.productDetail = [];
			}
		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}
	// End



  // function for navigate to category detail page 
  categoryDetail(id,supermarket){

	localStorage.removeItem('categoryName');
	localStorage.setItem('categoryName', supermarket);

	this.router.navigate(["/shop"], {
		state: { is_featured: 1 }
	});

    var name1 = (supermarket.split(' ').join(''));
  
    var url = encodeURIComponent(name1).replace(/[!'()*]/g, function (c) {
      return '-';
    });

    var removeSpace = (url.split('%').join('-'));
    var validUrl = '/supermarket'+'/'+removeSpace+'/'+id;
    this.router.navigate([validUrl]);
	// this.router.navigate([validUrl], {
	// 	state: { name: supermarket }
	// });

  }

	relaredProduct(name, id) {
		this.variantDAtaList = [];
		this.galleryImages = [];
		let navigate = "/product-detail/" + name + "/" + id;;
		this.router.navigate([navigate]);
	}

	setViewCount() {
		if (this.authService.isLoggedIn()) {
			const formData = new FormData();
			formData.append('product_id', this.productId);
			formData.append('user_type', 'buyer');

			this.shopService.viewCount(formData).subscribe((data) => {
				this.commonService.hideLoader();
				if (data.status == "fail") {
					this.commonService.toastError(data.message);
				}
			}, err => {
				this.commonService.errorhandler(err);
			});

		}
	}

	getDeliveryDuration(key) {
		var login = this.authService.isLoggedIn();
		if(login == false){
			var lang = this.commonService.getLanguage();	

		}else{
			var lang = this.authService.getLanguage();	

		}
		if(lang == 'en'){
		  switch (key) {
			case '1':
			  return "1 Day";
			  break;
			case '2':
			  return "2 Days";
			  break;
			case '3':
			  return "3 Days";
			  break;
			case '4':
			  return "7 Days";
			  break;
			case '5':
			  return "More than 7 days";
			  break;
		  }
		}else{
		  switch (key) {
			case '1':
			  return "1 Día";
			  break;
			case '2':
			  return "2 Días";
			  break;
			case '3':
			  return "3 Días";
			  break;
			case '4':
			  return "7 Días";
			  break;
			case '5':
			  return "More than 7 días";
			  break;
		  }
		}
	
	   
	  }
	// End

	// For variant data 
	setVariantData() {
		let data = this.productDetail.variant_list.find(object => { return object.productVariantID == this.variantId });
		if (this.variantId != null) {
			this.productDetail.variants_options.forEach(element1 => {
				element1.selected = false;
				element1.variant_value.forEach(element2 => {
					// element2.selected = false;
					data.variant_value.forEach(element3 => {
						if (element3.variantValueID == element2.variantValueID) {
							// element2.selected = element2.variantValueID;
							element1.selected = element2.variantValueID;
							this.productPrice = data.price;
							this.productPriceTemp = data.price;
							this.discount_percent = data.discount_percent;
							this.isCorrectVariantCombination = true;
							this.productVariantID = this.variantId;
							this.is_wishlist = data.is_wishlist;
							this.is_qvazon_warranty_applied = data.is_qvazon_warranty_applied;
							this.qvazon_warranty_percent = data.qvazon_warranty_percent;
						}
					});
				});
			});

		}

	}
	// End

	// For variants
	selectVariant(form: NgForm) {
		if (form.valid) {
			let selectVariantValuesArray = Object.values(form.value);
			let selectVariantValues = selectVariantValuesArray.join('');
			let variantMatch = this.productDetail.variant_list.some(element => {
				if (element.variantValuesString == selectVariantValues) {
					this.productPrice = element.price;
					this.productPriceTemp = element.price;
					this.productVariantID = element.productVariantID;
					this.is_wishlist = element.is_wishlist;
					this.is_qvazon_warranty_applied = element.is_qvazon_warranty_applied;
					this.qvazon_warranty_percent = element.qvazon_warranty_percent;
					this.isCorrectVariantCombination = true;
					return true;
				}
			});

			if (variantMatch === false) {
				this.isCorrectVariantCombination = false;
				this.commonService.toastError(this.translate.instant('SHOP.product_variant_combination_not'));
				this.productPrice = this.productDetail.regular_price;
				this.productVariantID = 0;
				this.is_wishlist = 0;
				this.is_qvazon_warranty_applied = 0;
				this.qvazon_warranty_percent = 0;
				this.productPriceTemp = 0;
			}

		}
	}
	// End

	// For form reset
	resetForm() {
		this.form.reset();
		this.qvazon_warranty_use = 0;
		this.is_qvazon_warranty_applied = 0;
		this.is_wishlist = 0;
	}

	onCheckboxChange(e) {
		if (e.target.checked) {
			this.qvazon_warranty_use = 1;
			let prAmount = Number(Number(this.productPrice) * Number(this.qvazon_warranty_percent) / 100);
			this.productPrice = Number(Number(this.productPrice) + Number(prAmount)).toFixed(2);
		} else {
			this.qvazon_warranty_use = 0;
			this.productPrice = this.productPriceTemp;
		}
	}

	// For add item to cart
	addToCart(form: NgForm) {

		var user_province = localStorage.getItem("user_province");

		if (this.quantity == undefined) {
			this.quantity = 1;
		}


		if(this.noVariant != 1){
			if (this.flag == 0) {
				this.commonService.selectionError(this.translate.instant('SHOP.please_select_variants_first'));
				return false;
			}else{
				this.variantsId = this.productVariantIDWithVAriant.productVariantID;

			}
		}else{
			this.variantsId = this.productDetail.variant_list[0].productVariantID;
		}
		

		// this.productOfferPrice = this.productVariantIDWithVAriant.offer_price_with_admin_commission;
		// this.productSellingPrice = this.productVariantIDWithVAriant.selling_price;


		// For check user is loggedin or not
		if (this.authService.isLoggedIn()) {
			const formData = new FormData();
			// formData.append('product_varint_id', this.productVariantID);
			formData.append('product_varint_id',this.variantsId );
			formData.append('product_quantity', this.quantity);
			formData.append('user_province', user_province);
			this.commonService.showLoader();

			this.shopService.addToCart(formData).subscribe((data) => {
				this.commonService.hideLoader();
				if (data.status == "fail") {
					this.commonService.toastError(data.message);
					this.resetForm();
					// this.ngOnInit();

				} else {
					this.commonService.toastSuccess(data.message);

					this.header.getCartCount();// for increse card count
					this.resetForm();
				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		}

		// } 
		else {
			// this.commonService.toastWarning(this.translate.instant('SHOP.please_login_before_product_add_in_cart'));
			//localStorage.setItem(environment.dataStore.redirectUri, this.router.url);
			this.router.navigate(['/login']);
		}

		// }

	}

	//add wishlist
	addWishlist(form: NgForm) {
		// For check user is login or not
		if (this.authService.isLoggedIn()) {
			// this.commonService.showLoader();

			const formData = new FormData();
			formData.append('product_id', this.productId);

			this.shopService.addUserWishlist(formData).subscribe((data) => {
				this.commonService.hideLoader();
				this.header.getWishCount();// for decrease card count

				if (data.status == "fail") {
					// this.commonService.toastError(data.message);
				} else {
					// this.commonService.toastSuccess(data.message);
					this.resetForm();
					// this.ngOnInit();
					this.is_wishlist = (this.variantId != null) ? data.data.is_wishlist : 0;
					this.productDetail.is_wishlist = data.data.is_wishlist;

				}

				this.isCorrectVariantCombination = false;

				//Redirect to solve selected varinat got empty after action
				if (this.activatedRoute.snapshot.url.length == 3) {  //get url array and length of array
					let urlSuffix = '/' + this.productVariantID;
					let navigate = this.router.url + urlSuffix;
					this.router.navigate([navigate]);
				} else if (this.activatedRoute.snapshot.url.length == 4) {
					let lastIndexOfUrl = this.activatedRoute.snapshot.url[3].path;
					let popIndex = '/' + lastIndexOfUrl.toString();
					let pushIndex = '/' + this.productVariantID;
					let toStringArr = this.activatedRoute.snapshot.url.toString()
					let stringToArr = toStringArr.replace(popIndex, pushIndex);
					let finalArr = stringToArr.split(',');
					//let urlSuffix = '/'+finalArr[3];
					let urlSuffix = '/' + lastIndexOfUrl;
					let redirectTo = this.router.url.replace(urlSuffix, pushIndex);
					this.router.navigate([redirectTo]);

				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		} else {
			// this.commonService.toastWarning(this.translate.instant('SHOP.please_login_before_product_add_in_wishlist'));
			//localStorage.setItem(environment.dataStore.redirectUri, this.router.url);
			this.router.navigate(['/login']);
		}
		// }
	}

	
	//  make an offer 

	makeAnOfferModal(form: NgForm) {

		if (this.authService.isLoggedIn()) {

			if(this.noVariant != 1){


			if (this.flag == 0) {
				this.commonService.toastError(this.translate.instant('SHOP.please_select_variants_first'));
				return false;
			}else{
				$('#makeOffer').modal('show');

			}
		}else{
			$('#makeOffer').modal('show');

		}


		} else {
			// this.commonService.toastWarning(this.translate.instant('SHOP.please_login_before_make_an_offer'));
			this.router.navigate(['/login']);
		}

	}

	deleteExistOffer(data) {
		Swal.fire({
			title: this.translate.instant('MYORDER.are_you_sure'),
			text: this.translate.instant('CHAT.you_want_to_delete_existing_offer'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: this.translate.instant('SELLER_SIDE.yes_delete_it'),
			cancelButtonText: this.translate.instant('SELLER_SIDE.no_keep_it'),
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				// $('#makeOffer').modal('show');
				this.deleteExistingOffer(data);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					this.translate.instant('SELLER_SIDE.cancelled'),
					this.translate.instant('CHAT.your_offer_is_safe'),
					this.translate.instant('MYORDER.error_txt')
				)
			}
		});
	}

	resetOfferForm() {
		this.makeOfferForm.reset();
		this.variantListData = [];
		this.variant = '';
		//this.resetForm();
		//this.isCorrectVariantCombination = false;
	}

	deleteExistingOffer(chatdata) {

		this.shopService.offerDelete(this.productId, this.productVariantID).subscribe((data) => {
			this.commonService.hideLoader();
			if (data.status == "fail") {
				this.commonService.toastError(data.message);
			} else {
				this.firebaseService.deleteOfferExist(chatdata)
				$('#makeOffer').modal('show');
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}


	// For offer price
	checkNumberLimit(event) {
		if (event.target.value.includes('.') && event.keyCode == 46) {

			event.preventDefault();
			return false
		}

		var valid = /^\d{0,7}(\.\d{0,2})?$/.test(event.target.value),
			val = event.target.value;

		if (!valid) {
			event.target.value = val.substring(0, val.length - 1);
		}


	}
	




	//Change Quantity of cart 
	changeqty(value: any): void {
		this.quantity = value;
		this.quantityValue = value;
		// let productData = this.cartList[index];
		// if(productData.quantity > value){
		//   this.decreaseCount(productData,value,index);   

		// }else{
		//   this.increaseCount(productData,value,index);

		// }
	}



	getFirebaseToken() {
		this.buyerFbService.getFirebaseToken(this.productDetail.seller_id).subscribe(userData => {
			let token;
			if (userData.payload.val()) {
				token = userData.payload.val();
				this.firebaseToken = token.firebase_token;
			}
		});
	}

	firstMessageSend() {
		let chatData = {
			sellerId: this.productDetail.seller_id,
			buyerId: this.currentUserId,
			buyerName: this.authService.getUserDetails().full_name,
			productId: this.productDetail.productID,
			productName: this.productDetail.name,
			firebaseToken: this.firebaseToken,
			isOffer: 1,
			message: this.translate.instant('SHOP.you_have_new_offer_request')
		}

		this.buyerFbService.sendProductMsg(chatData);
	}

	goToChatDashborad(productId) {
		// For check user is login or not
		if (this.authService.isLoggedIn()) {
			this.router.navigate(['/chat/product/', productId]);
		} else {
			this.commonService.toastWarning(this.translate.instant('SHOP.please_login_before_chat_with_seller'));
			this.router.navigate(['/login']);
		}

	}

	getSellerProfile(seller_id) {
		this.router.navigate(['seller-review', seller_id]);
	}


	onlyNumber ($event) {
		let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
		if ((keyCode < 48 || keyCode > 57) && keyCode !== 190) { // 46 is dot
			$event.preventDefault();
		}
	}

	// Add questions form
	addQuestions() {
		if (this.authService.isLoggedIn()) {

			if (this.questionForm.valid) {
				this.commonService.showLoader();

				const formData = new FormData();
				formData.append('product_id', this.productDetail.productID);
				formData.append('description', this.questionForm.get('description').value);

				this.shopService.askQuestions(formData).subscribe((res) => {
					this.commonService.hideLoader();
					this.getProductDetail(this.productId, this.currentUserId)
					this.commonService.toastSuccess(res.message);
					this.questionForm.reset();

					// window.location.reload();


				}, err => {
					this.commonService.hideLoader();

					this.commonService.errorhandler(err);
					this.questionForm.reset();
				});
			} else {
				this.validateAllFormFields(this.questionForm); //{7}

			}
		} else {
			// this.commonService.toastWarning(this.translate.instant('SHOP.please_login_before_chat_with_seller'));
			this.router.navigate(['/login']);

		}

	}

	// For check form valid
	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});
	}

	getVAriantssssss(value) {
	}

	getValueColor(variantsss, index, e, varnm) {

		this.productVariantQuantity = 1;

		this.newVariants = this.variants;
		var clsname = e.target.className;
		var indecNew = index - 1;

		if(clsname == "mb-0 sp0"){
			$('.mb-0').removeClass('specialClass');
			this.newIdsVarinatsComma = [] ;

		}

		if(index != 0){

			var name1 = `variantGet${indecNew}`
			var clss = `specialClass mb-0 sp${indecNew}`;

			var checkClass = 0; 

			$("[name='" + name1 + "']").each(function () {
				var value = $(this).parent().children("label").attr('class');

				if(value == clss){
					$(`.sp${index}`).removeClass('specialClass');
					$(e.target).attr('class', `specialClass mb-0 sp${index}`);
					checkClass = 1;
					return false;
					
				}else{
					checkClass = 0;
				}
			});

		}else{
			$(`.sp${index}`).removeClass('specialClass');
			$(e.target).attr('class', `specialClass mb-0 sp${index}`);
		}
		var i;

		for(i = index+1; i <= this.variants.length; i++) { 
			$(`.sp${i}`).removeClass('specialClass');
		}


		if(checkClass == 0){

			return false;
			// var error = `${this.variants[indecNew].variant_name}`
			// alert(`Please select ${this.variants[indecNew].variant_name} first`);
			// // this.commonService.toastWarning(this.translate.instant('COMMON.select'));
			// this.commonService.toastWarning(this.translate.instant('COMMON.select', {error: error}));


			// this.commonService.toastWarning(this.translate.instant(`COMMON.select ${this.variants[indecNew].variant_name} COMMON.first`));

		}
		
		else{

		$(`.varientItem`).addClass('varinatshide');
		$(`.varinatsIndex${index}`).addClass('varinatsshow');
		$(`.varinatsIndex${index+1}`).addClass('varinatsshow');	

		var j;
		for(j = index+2; j <= this.variants.length; j++) { 
			$(`.varinatsIndex${j}`).removeClass('varinatsshow');
			$(`.varinatsIndex${j}`).addClass('varinatshide');
		}
	
		if ($.inArray(variantsss, this.newIdsVarinatsComma) == 0){
			this.newIdsVarinatsComma = [] ;

		}

	

		// for(var  k = index+1; k <= this.newIdsVarinatsComma; k++) { 
		// 	console.log('k',k)
		// 	this.newIdsVarinatsComma.splice(k,1);

		// }
		this.newIdsVarinatsComma.length = index;

		if(this.newIdsVarinatsComma.length > 1){
			this.newIdsVarinatsComma.splice(index,1);


		}
		this.newIdsVarinatsComma.push(variantsss);
		var newss  = this.newIdsVarinatsComma.toString();
		this.flag = 0;
		var id;
		var product_variant_id;
		var ind = index + 1;

		if (this.variants.length != ind) {

			id = this.variants[ind].variantID;
			product_variant_id = ""

			if (product_variant_id == undefined) {
				product_variant_id = 0;
			}
			this.key = '1';
			this.fruits = [];
			this.commonService.showLoader();
			if (this.authService.isLoggedIn()) {
				var userId = this.currentUserId
			} else {
				userId = 0;
			}
			this.quantityShow =0;
			this.make_an_offerkey =1;
			this.buyerService.getVariant(this.productId, newss, id, product_variant_id, userId).subscribe((res) => {

				this.axz = res.data
				
				this.axz.map((val) => {
					val.variant_value.map(async (valuee) => {
						this.fruits.push(valuee);
					});
				})

				var result = this.fruits.reduce((unique, o) => {
					if (!unique.some(obj => obj.variantValueID === o.variantValueID && obj.variantValueID === o.variantValueID)) {
						unique.push(o);
					}
					return unique;
				}, []);


				this.fruits = result;



				// for(i = +1; i < this.variants.length; i++) { 

					


				// 	// this.variants = this.variants.slice(0, i); //Need to assign it to the same or another variable
				// }

				
				 this.variants = this.newVariants;


				//  console.log('this.fruits', this.fruits);
				//  console.log('this.variants', this.variants);

				this.variants[ind].variant_value = this.fruits;
				



				this.commonService.hideLoader();
				// this.commonService.toastSuccess(res.message);


			}, err => {
				this.commonService.errorhandler(err);
			});


		} else {

			if (this.productDetail.variants_options.length == 1) {

				this.productDetail.variant_list.map((valuess) => {

					valuess.variant_value.map((getVal)=>{

						if(getVal.variantValueID == variantsss){

							this.product_variant_id = valuess.productVariantID;
							// return false;
						}

					})

					
				})


				this.flag = 1;

			} else {
				this.fruits.map((values) => {
					if (values.variantValueID == variantsss) {
						this.product_variant_id = values.product_variant_id;
					}
				})

			}

			if (this.product_variant_id == undefined) {
				return false;
				
			}
			
			$(`.sp${index}`).removeClass('specialClass');
			$(e.target).attr('class', `specialClass mb-0 sp${index}`);

			this.flag = 1;
			id = "";

			if (this.authService.isLoggedIn()) {
				var userId = this.currentUserId
			} else {
				userId = 0;
			}

			this.commonService.showLoader();

			this.buyerService.getVariant(this.productId, newss, id, this.product_variant_id, userId).subscribe((productDetailData) => {
				this.commonService.hideLoader();


				this.productVariantIDWithVAriant = productDetailData.data[0];

				if (productDetailData.status == 'success') {
					this.commonService.scrollToTop();
					// this.newIdsVarinatsComma =[];
					this.galleryImages = [];
					this.galleryImage = this.productVariantIDWithVAriant.variant_image;
					this.productOfferPrice = this.productVariantIDWithVAriant.offer_price_with_admin_commission;
					this.productSellingPrice = this.productVariantIDWithVAriant.selling_price;
					this.discountPercent = Math.round(this.productVariantIDWithVAriant.discount_percent);
					this.productVariantQuantity = this.productVariantIDWithVAriant.quantity;
					// this.offer_status = productDetailData.data[0].offerItemID.status
					
					this.quantityShow = 1;
					this.quantityValue = 1;
					this.makeAnOfferImage = this.galleryImage[0];
					this.make_an_offerkey  = 0;
					this.galleryImage.forEach(element => {
						let galleryImagesData = {
							small: `${element.variant_image_url}${element.variant_image}`,
							medium: `${element.variant_image_url}${element.variant_image}`,
							big: `${element.variant_image_url}${element.variant_image}`
						};
						this.galleryImages.push(galleryImagesData);
					});

					this.getFirebaseToken();
					// this.setVariantData();

					this.setViewCount();
				} else {

					this.productDetail = [];
				}


			}, err => {
				this.commonService.errorhandler(err);
			});




		}
	}
	

}


	

	  // End
	  redirectFunction(detail, name,id1){
		var name1 = (name.split(' ').join(''));

		var url = encodeURIComponent(name1).replace(/[!'()*]/g, function (c) {
			return '-' ;
		});

		var removeSpace = (url.split('%').join('-'));
		var validUrl = detail+'/'+removeSpace+'/'+id1;
		this.router.navigate([validUrl]);
	
	  }


	  getDisputeTimeShow(dateTime) {

		return this.commonService.getDisputeTimeShow(dateTime);
	}


	getDate(dateTime) {

		return this.commonService.getDateTime2(dateTime);
	}


	// make offer function
	makeOfferSubmit() {
	console.log(' this.productDetail',  this.productDetail);
		
		var user_province = localStorage.getItem("user_province");

		if(this.noVariant == 0){
			var image = this.makeAnOfferImage.variant_image_url + this.makeAnOfferImage.variant_image;
		}else{
			 image = this.productDetail.feature_image_url;

		}


		if (this.quantity == undefined) {
			this.quantity = 1;
		}

		if (this.makeOfferForm.valid) {

			if (this.makeOfferForm.get('offer_price').value <= 0) {
				this.commonService.toastError(this.translate.instant('PROFILE.offer_price')); return false;
			}
			if (this.productDetail.discount_percent > '0.00') {
				this.priceeeeeee = this.productOfferPrice;
			} else {

				this.priceeeeeee = this.productSellingPrice;
			}




			const formData = new FormData();

			if(this.noVariant != 1){
				
				this.variantsId = this.productVariantIDWithVAriant.productVariantID;
	
				
			}else{
				this.variantsId = this.productDetail.variant_list[0].productVariantID;
			}

			formData.append('offer_price', this.makeOfferForm.get('offer_price').value);
			formData.append('product_variant_id', this.variantsId);
			formData.append('product_id', this.productId);
			formData.append('feature_image', image);


			if (this.productDetail.is_offer == 1) {
				formData.append('product_variant_price', this.productOfferPrice);

			} else {

				formData.append('product_variant_price', this.productSellingPrice);
			}

			formData.append('seller_id', this.productDetail.seller_id);
			formData.append('product_counter_price', '');
			formData.append('quantity', this.quantity);
			formData.append('user_province', user_province);


			this.commonService.showLoader();
			this.buyerService.makeAnOffer(formData).subscribe((res) => {
				if (res.status = "success") {

					this.commonService.hideLoader();

					this.commonService.toastSuccess(res.message);
					this.makeOfferForm.reset();
					$('#makeOffer').modal('hide');
					this.make_an_offerkey = 1;
					this.quantityValue = 1;

					this.ngOnInit();


					// this.redirectFunction('/product-detail',this.productDetail.name,this.productDetail.product_id)
						


				} else {

					this.commonService.hideLoader();

					this.commonService.toastSuccess(res.message);
					// this.makeOfferForm.reset();
				}


			}, err => {
				this.commonService.hideLoader();

				this.commonService.errorhandler(err);
				this.makeOfferForm.reset();
			});
		} else {
			this.validateAllFormFields(this.makeOfferForm); //{7}

		}
	}
}
