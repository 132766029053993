// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-store-detail',
//   templateUrl: './store-detail.component.html',
//   styleUrls: ['./store-detail.component.css']
// })
// export class StoreDetailComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }

import { AfterViewChecked, AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { BuyerService } from '../buyer/services/buyer.service';


import { DOCUMENT } from '@angular/common';
import { faAngleDoubleRight, faCheck, faStar, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormControl } from '@angular/forms';
import { Options } from 'ng5-slider';
import { CommonService } from '../services/common.service';
import { ShopService } from '../services/shop.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { HeaderBuyerComponent } from '../common/header-buyer/header-buyer.component';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import { OwlOptions } from 'ngx-owl-carousel-o';

declare const $: any;

@Component({
  selector: 'app-store-detail',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.css']
})
export class StoreDetailComponent implements OnInit, AfterViewChecked {

    faAngleDoubleRight = faAngleDoubleRight;
    faCheck = faCheck;
    faStar = faStar;
    faCircle = faCircle;
    currentUserId: number;
    filter: object;
    productList: any = [];
    categoryList: any = [];
    variantList: any = [];
    selectedColor: any = [];
    selectedSize: any = [];
    category: any = "";
    color: any = "";
    size: any = "";
    productCount: number = 0;
    limitOfProduct: number = 12;
    offsetOfProduct: number = 0;
    productloader: boolean = true;
    selectedCategory: any = [];
    minPrice: number = 0;
    maxPrice: number = 0;
    price_from: any = "";
    price_to: any = "";
    sort: any = "";
    search_term: string = "";
    deal: any = "";
    isCatRoute: boolean = false;
    isBreadcrumb: number = 1;
    isBreadcrumbCat: any = 'undefined';
    isBreadcrumbSubCat: any = 'undefined';
    condition: any = '';
    sellerWarranty: any = '';
    sellerRating: any = '';
    newArrival: any = '';
    discount: any = '';
    deliveryIn: any = [];
    deliveryInData: any = '';
    provinceList: any = [];
    provinceCheckArray: any = [];
    provinceData: any = '';
    brandCheckArray: any = [];
    brandData: any = '';
    isBrandListShow: boolean = false;
    brandList: any = [];
    remainingCategoryList: any = [];
    delivery_in: any;
    direct_pay: any = '';
    preventA: any = 1
    is_featured = ""
    superMarket: any = {}
    superMarketFlag: any = 0;
    // minValue: number = 50;
    // maxValue: number = 200;
    bannerImage : any;
    sliderForm: FormGroup = new FormGroup({
        sliderControl: new FormControl([0, 5000])
    });




    isShown: boolean = false; // hidden by default
    offerForm: any;
    variant: string;
    variantListData: any[];
    qvazon_warranty_use: number;
    is_wishlist: number;
    is_qvazon_warranty_applied: number;
    form: any;
    variantId: any;
    discount1: any;
    ids: any;
    encryptSecretKey: 1;
    fastDelivery: any = 0;
    productss = [];
    supermarket: any;
    categoryid: string;
    superMarketKey: any;
    categoryiName: any;
    type: any = 1;
    secondLevelCat: any = [];
    SuperMarketSecondLevelCat: any = [];
    secondLevelArray: any = [];
    placeholder: any = "";
    nameCat: any;
    notShow: any = 0;
  sellerID: string;
	SellerInfo: any = [];
    ;


    // 

    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};

    toggleShow() {
        this.isShown = !this.isShown;
        if (this.isShown) {
            this.document.body.classList.add('filter-open');
        } else {
            this.document.body.classList.remove('filter-open');
        }
    }

    @ViewChild('header', { static: true }) header: HeaderBuyerComponent;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private commonService: CommonService,
        private shopService: ShopService,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private translate: TranslateService,
        private buyerService: BuyerService,


    ) {
        
        translate.use(this.commonService.getLanguage());
        // if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras.state != undefined) {

        //     this.nameCat = this.router.getCurrentNavigation().extras.state.name;

        // }

    }


    ngAfterViewChecked(): void {
        // this.placeholder = this.translate.instant('ADD_PRODUCT.select_categorie'),
        setTimeout(()=>{
            this.placeholder = this.translate.instant('ADD_PRODUCT.select_categorie');


        }, 100);

            this.dropdownSettings = {
                singleSelection: false,
                idField: 'categoryID',
                textField: 'name',
                selectAllText: this.translate.instant('HOME.selectAll'),
                unSelectAllText: this.translate.instant('HOME.unselectAll'),
                itemsShowLimit: 2,
                allowSearchFilter: false,
            };
    }

   


    ngOnInit() {
        
        setTimeout(()=>{
            this.placeholder = this.translate.instant('ADD_PRODUCT.select_categorie');


        }, 100);

           
            this.dropdownSettings = {
                singleSelection: false,
                idField: 'categoryID',
                textField: 'name',
                selectAllText: this.translate.instant('HOME.selectAll'),
                unSelectAllText: this.translate.instant('HOME.unselectAll'),
                itemsShowLimit: 2,
                allowSearchFilter: false,
            };


        this.commonService.scrollToTop();



        // Get router parameter 
        this.activatedRoute.paramMap.subscribe(params => {
            this.sellerID = params.get("id");

        });




        if (this.is_featured != "" && this.is_featured != "null" && this.is_featured != undefined) {
            this.category = "";
        }
        const str = this.router.url;
        const regex = RegExp('/shop/category');
        const regexCombo = RegExp('/combo');
        if (regexCombo.test(str) == true) {
            this.type = 2;
        } else {
            this.type = 1;

        }

     


        if (regex.test(str) == true) {
            this.commonService.setTitle('Explora Nuestras Categorías de Productos con Mercadoba | Compra en Línea en Cuba'); //For title

            this.commonService.getMetaTages('Explora Nuestras Categorías de Productos con Mercadoba | Compra en Línea en Cuba', 'Encuentra exactamente lo que necesitas en Cuba a través de nuestras diversas categorías de productos en línea con Qvazon. Compra ahora y recibe rápidamente. Explora nuestras categorías y elige entre una amplia gama de opciones.')

        } else {
            this.commonService.setTitle('Compra en Línea en Cuba con Mercadoba | Entrega Rápida y Variedad de Productos'); //For title

            this.commonService.getMetaTages('Compra en Línea en Cuba con Mercadoba | Entrega Rápida y Variedad de Productos', 'Compra en línea en Cuba de manera conveniente y segura con Qvazon. Amplia variedad de productos y envío rápido. Haz tu pedido ahora y recíbelo pronto. Explora nuestras categorías y encuentra justo lo que necesitas.')

        }
        this.currentUserId = this.authService.getCurrentUserId(); //For current userID
        // this.getSuperMarket(this.supermarket,this.limitOfProduct, this.offsetOfProduct, this.getFilterData(2));
        // this.getSuperMarketCat();
        // this.getSuperMarketSecondLevelCat();
        // this.getBanner();
        // this.nameCat = localStorage.getItem('categoryName');
		this.productListApi(this.limitOfProduct, this.offsetOfProduct);

    }

    searchTimer: any;
    doneSearchInterval: any = 500;







    // End
    redirectFunction(detail, name, id1) {
        var name1 = (name.split(' ').join(''));
        var url = encodeURIComponent(name1).replace(/[!'()*]/g, function (c) {
            return '-';
        });
        var removeSpace = (url.split('%').join('-'));
        var validUrl = detail + '/' + removeSpace + '/' + id1;
        localStorage.removeItem("pageRoute");
        localStorage.setItem("pageRoute",'stores');
        this.router.navigate([validUrl]);

    }

    // Function for reset load more button
    resetLoadMore() {
        this.productList = [];
        this.limitOfProduct = 12;
        this.offsetOfProduct = 0;
    }
    // End

    // get data for filter
    getFilterData(init) {
        var filter = {
            'category': "",
            'color': "",
            'size': "",
            'sort': "",
            'price': "",
            'search_term': "",
            'condition': "",
            'sellerWarranty': "",
            'sellerRating': "",
            'newArrival': "",
            'discount': "",
            'deliveryInData': "",
            'provinceData': "",
            'brandData': "",
            'deal': "",
            'delivery_in': "",
            'direct_pay': "",
            'is_featured': ""
        }
        if (init != 1) { // for starting
            filter.category = this.category;
            filter.color = this.color;
            filter.size = this.size;
            // filter.price_from = this.price_from;
            // filter.price_to = this.price_to;
            filter.price = `${this.price_from}|${this.price_to}`;
            filter.sort = this.sort;
            filter.search_term = this.search_term.trim();;
            filter.condition = this.condition;
            filter.sellerWarranty = (this.sellerWarranty != '' || this.sellerWarranty != false) ? this.sellerWarranty : '';
            filter.sellerRating = this.sellerRating;
            filter.newArrival = this.newArrival;
            filter.discount = this.discount;
            filter.deliveryInData = this.deliveryInData;
            filter.provinceData = this.provinceData;
            filter.brandData = this.brandData;
            filter.deal = this.deal;
            filter.delivery_in = this.delivery_in;
            filter.delivery_in = this.deliveryInData;
            filter.direct_pay = this.direct_pay;
            filter.is_featured = this.is_featured;
        }
        return filter;
    }


    // Function for reset form
    resetForm() {
        // this.form.reset();
        this.qvazon_warranty_use = 0;
        this.is_qvazon_warranty_applied = 0;
        this.is_wishlist = 0;
    }
    // End

    // For reset offer form 
    resetOfferForm() {
        this.offerForm.reset();
        this.variantListData = [];
        this.variant = '';
    }

    // End

    //add wishlist
    addWishlist(productId, index) {
        // For check user is login or not
        if (this.authService.isLoggedIn()) {
            const formData = new FormData();
            formData.append('product_id', productId);

            this.shopService.addUserWishlist(formData).subscribe((data) => {
                this.header.getWishCount();// for decrease card count
                if (data.status == "fail") {
                } else {
                    this.resetForm();
                    this.productList[index].is_wishlist = data.data.is_wishlist;
                }

                //Redirect to solve selected varinat got empty after action
                if (this.activatedRoute.snapshot.url.length == 3) {  //get url array and length of array
                    let navigate = this.router.url;
                    this.router.navigate([navigate]);
                } else if (this.activatedRoute.snapshot.url.length == 4) {
                    let lastIndexOfUrl = this.activatedRoute.snapshot.url[3].path;
                    let popIndex = '/' + lastIndexOfUrl.toString();
                    let toStringArr = this.activatedRoute.snapshot.url.toString()
                    let urlSuffix = '/' + lastIndexOfUrl;
                }

            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });

        } else {
            // this.commonService.toastWarning(this.translate.instant('SHOP.please_login_before_product_add_in_wishlist'));
            //localStorage.setItem(environment.dataStore.redirectUri, this.router.url);
            this.router.navigate(['/login']);
        }
        // }
    }
    // End









    productListApi(limitOfProduct, offsetOfProduct) {
        // this.productList =[];
        this.commonService.showLoader();
        this.shopService.getStoreDetail(this.sellerID,limitOfProduct, offsetOfProduct).subscribe(
            (productData) => {
				console.log('productData', productData);
				this.offsetOfProduct =  productData.data.sellerdata[0].nextOffset

                this.commonService.hideLoader();
                if (productData.status == 'success') {

					this.productCount = productData.data.sellerdata[0].total_records; //total product
					this.SellerInfo = productData.data.sellerdata[0]; 
					productData.data.sellerdata[0].sellerProductlist.forEach(element => {
						this.productList.push(element);
					});
                } else {
                    this.productCount = 0;
                    this.productList = [];
                }
            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });



    }



    // For load more button product
    productLoadMore() { //load more 
        // this.offsetOfProduct = this.offsetOfProduct + Number(this.limitOfProduct);
		this.productListApi(this.limitOfProduct, this.offsetOfProduct);
    }// End

    // get banner of supermarket 


    // getBanner() {
    //     this.shopService.getBannerSuper().subscribe((bannerData) => {
    //         console.log('bannerData', bannerData);
    //         this.bannerImage = bannerData.data.banner;

    //     }, err => {
    //         this.commonService.hideLoader();
    //         this.commonService.errorhandler(err);
    //     });
    // }


    // For add item to cart
	addToCart(product, index) {
		var user_province = localStorage.getItem("user_province");
		// For check user is loggedin or not
		if (this.authService.isLoggedIn()) {
			const formData = new FormData();
			formData.append('product_varint_id',product.productVariantID );
			formData.append('product_quantity', '1');
			formData.append('user_province', user_province);
			// this.commonService.showLoader();

			this.shopService.addToCart(formData).subscribe((data) => {
                console.log('data', data.data);
				this.commonService.hideLoader();
                    this.productList[index].is_cart = 1;
                    this.productList[index].cartItemID = data.data.cartID;
                    this.header.getCartCount();// for decrease card count

					this.resetForm();
				
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		}

		// } 
		else {
			this.router.navigate(['/login']);
		}

		// }

	}

	//Remove cart item
	removeCartItem(cartItemId, index) {

		this.buyerService.removeCartItem(cartItemId).subscribe(
			(cartlistDataRemove) => {
                console.log('neha');
				this.commonService.hideLoader();
                this.productList[index].is_cart = 0;
                this.header.getCartCount();// for decrease card count
				
			}, err => {
				// this.commonService.hideLoader();
				// this.commonService.errorhandler(err);
			});
	}




}

