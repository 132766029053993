import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { DOCUMENT } from '@angular/common';


declare const showLoader: any;
declare const hideLoader: any;
declare const getTimeZone: any;
declare const ShowPopup: any;
declare const hidePopup: any;
declare const errorHidePopup: any;
declare const selectonReqHidePopup: any;
declare const warningHidePopup: any;
declare const errorShowPopup: any;
declare const selectionErrorShowPopup:any;
declare const warningShowPopup: any;
declare const alertShowPopup: any;
declare const alerthidePopup: any;



@Injectable({
	providedIn: 'root'
})
export class CommonService {
	searchTextStore: any = "";
	timeStore: any;

	constructor(
		private router: Router,
		private toastr: ToastrService,
		private title: Title,
		private authService: AuthService,
		private metaService: Meta,

		@Inject(DOCUMENT) private document: Document
	) { }

	showLoader() {
		showLoader();
	}

	hideLoader() {
		hideLoader();
	}

	// show sucess popup
	ShowPopup(id) {
		ShowPopup(id);
	}

	// show error popup
	errorShowPopup(id) {
		errorShowPopup(id);
	}



	warningShowPopup(id) {
		warningShowPopup(id);
	}

	// show selection required popup
	selectionErrorShowPopup(id) {
		selectionErrorShowPopup(id);
	}


	alertShowPopup(id) {
		alertShowPopup(id);
	}

	alerthidePopup() {
		alerthidePopup()
	}

	// hide sucess popup
	hidePopup() {
		hidePopup();
	}

	//hide errorpopup
	errorHidePopup() {
		errorHidePopup();
	}
	//hide errorpopup
	selectonReqHidePopup() {
		selectonReqHidePopup();
	}

	warningHidePopup() {
		warningHidePopup();
	}

	setTitle(title: any): void {
		this.title.setTitle(title); // For title show
	}

	scrollToTop(): void {
		window.scrollTo(0, 0);
	}

	// toastSuccess(message, data = {}) {
	//   this.toastr.success(message, 'success');
	// }

	// toastError(message, data = {}) {
	//   this.toastr.error(message, 'error');
	// }

	// toastWarning(message, data = {}) {
	//   this.toastr.warning(message, 'Warning');
	// }

	hidePopups() {
		var myInterval = setInterval(() => {
			this.hidePopup();
			clearInterval(myInterval)
		}, 2000)
	}

	getMetaTages(metaTiltle, metaDescription) {

		this.metaService.updateTag({ name: 'title', content:metaTiltle });
    	this.metaService.updateTag({ name: 'description', content:metaDescription });
	}



	errorHidePopups() {
		var myInterval = setInterval(() => {
			this.errorHidePopup();
			clearInterval(myInterval)
		}, 2000)
	}

	warningHidePopups() {
		var myInterval = setInterval(() => {
			this.warningHidePopup();
			clearInterval(myInterval)
		}, 2000)
	}

	selectionHidePopups() {
		var myInterval = setInterval(() => {
			this.selectonReqHidePopup();
			clearInterval(myInterval)
		}, 2000)
	}


	alerthidePopups() {
		var myInterval = setInterval(() => {
			this.alerthidePopup();
			clearInterval(myInterval)
		}, 2000)
	}


	toastSuccess(message, data = {}) {
		ShowPopup(message);
		this.hidePopups();
	}
	toastError(message, data = {}) {
		errorShowPopup(message);
		this.errorHidePopups();

	}




	toastWarning(message, data = {}) {
		warningShowPopup(message);
		this.warningHidePopups();

	}

	selectionError(message, data = {}) {
		selectionErrorShowPopup(message);
		this.selectionHidePopups();

	}

	toastALert(message, data = {}) {
		alertShowPopup(message);
		return false;
		this.alerthidePopups();

	}


	toastClear() {
		this.toastr.clear();
	}

	getTimeZone() {
		return getTimeZone();
	}

	// get user language
	getLanguage() {
		const language = JSON.parse(localStorage.getItem(environment.dataStore.language));

		if (language == null || language == '') {
			return 'es';
		} else {
			return language.code;
		}
	}

	// date format with object
	getCurrentDateObject() {
		return { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
	}

	timeConvert(time) {
		// Check correct time format and split into components
		time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

		if (time.length > 1) { // If time format correct
			time = time.slice(1);  // Remove full string match value
			time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
			time[0] = +time[0] % 12 || 12; // Adjust hours
		}
		return time.join(''); // return adjusted time or original string
	}

	timeSet(time) {
		if (time.toString().length == 1) {
			return '0' + time;
		} else {
			return time
		}
	}


	getOrderDate(dateTime, string) {

		

		if (this.authService.isLoggedIn()) {
			var lang = this.authService.getLanguage();


		}else{
			var lang = this.getLanguage();

		}



		if (typeof dateTime != 'undefined') {

			if (lang == 'en') {
				var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

			} else {
				var months = ["Ene", "Feb", "Mar", "Abr", "Puede", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

			}



			let last2 = string.slice(-2);
			let dateTimeF = dateTime.split(',')[1].trim();


			let dateTimeFCo = dateTimeF.split('-')[0].trim() + ' ' + this.timeConvert(dateTimeF.split('-')[1].trim());

			// let dt = new Date(`${dateTimeFCo} UTC`);
			let dt = new Date(`${dateTimeFCo}`);

			let timeShow = this.timeConvert(`${this.timeSet(dt.getHours())}:${this.timeSet(dt.getMinutes())}`);
			let ft = dt.getDate() + " " + months[dt.getMonth()] + " " + dt.getFullYear();
			let ftshow = string.split(',')[0] + ', ' + ft;
			return ftshow;
		}

	}


	getOrderTime(dateTime, string) {

		if (this.authService.isLoggedIn()) {
			var lang = this.authService.getLanguage();


		}else{
			var lang = this.getLanguage();

		}


		if (typeof dateTime != 'undefined') {

			if (lang == 'en') {
				var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

			} else {
				var months = ["Ene", "Feb", "Mar", "Abr", "Puede", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

			}



			let last2 = string.slice(-2);
			let dateTimeF = dateTime.split(',')[1].trim();


			let dateTimeFCo = dateTimeF.split('-')[0].trim() + ' ' + this.timeConvert(dateTimeF.split('-')[1].trim());

			// let dt = new Date(`${dateTimeFCo} UTC`);
			let dt = new Date(`${dateTimeFCo}`);

			let timeShow = this.timeConvert(`${this.timeSet(dt.getHours())}:${this.timeSet(dt.getMinutes())}`);
			let ft = dt.getDate() + " " + months[dt.getMonth()] + " " + dt.getFullYear() + " - " + timeShow;
			let ftshow = string.split(',')[0] + ', ' + ft;
			return ftshow;
		}

	}



	getDisputeTimeShow(dateTime) {
		if (this.authService.isLoggedIn()) {
			var lang = this.authService.getLanguage();


		}else{
			var lang = this.getLanguage();

		}

		var date = new Date(dateTime);


		if (typeof dateTime != 'undefined') {


			if (lang == 'en') {
				var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

			} else {
				var months = ["Ene", "Feb", "Mar", "Abr", "Puede", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

			}





			// let dt = new Date(`${dateTimeFCo} UTC`);
			let dt = new Date(`${date}`);
			let timeShow = this.timeConvert(`${this.timeSet(dt.getHours())}:${this.timeSet(dt.getMinutes())}`);
			let ft = dt.getDate() + " " + months[dt.getMonth()] + " " + dt.getFullYear();
			// let ftshow = string.split(',')[0] + ', ' + ft;
			return ft;
		}

	}




	getDateTime(dateTime) {

		if (this.authService.isLoggedIn()) {
			var lang = this.authService.getLanguage();


		}else{
			var lang = this.getLanguage();

		}
		var date = new Date(dateTime);


		if (typeof dateTime != 'undefined') {


			if (lang == 'en') {
				var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

			} else {
				var months = ["Ene", "Feb", "Mar", "Abr", "Puede", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

			}
			// let dt = new Date(`${dateTimeFCo} UTC`);
			let dt = new Date(`${date}`);

			let timeShow = this.timeConvert(`${this.timeSet(dt.getHours())}:${this.timeSet(dt.getMinutes())}`);
			let ft = dt.getDate() + " " + months[dt.getMonth()] + " " + dt.getFullYear() + " - " + timeShow;;
			// let ftshow = string.split(',')[0] + ', ' + ft;
			return ft;
		}

	}





	getDateTime1(dateTime) {


		if (this.authService.isLoggedIn()) {
			var lang = this.authService.getLanguage();


		}else{
			var lang = this.getLanguage();

		}


		if (typeof dateTime != 'undefined') {

			if (lang == 'en') {
				var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

			} else {
				var months = ["Ene", "Feb", "Mar", "Abr", "Puede", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

			}


			// ? str.trim() : ''
			// let dateTimeF = dateTime.split(',')[1].trim();


			let dateTimeFCo = dateTime.split('-')[0].trim() + ' ' + this.timeConvert(dateTime.split('-')[1].trim());

			let dt = new Date(`${dateTimeFCo} UTC`);
			// let dt = new Date(`${dateTimeFCo}`);

			let timeShow = this.timeConvert(`${this.timeSet(dt.getHours())}:${this.timeSet(dt.getMinutes())}`);


			// let ft = dt.getDate() + " " + months[dt.getMonth()] + " " + dt.getFullYear() ;
			let ft = dt.getDate() + " " + months[dt.getMonth()] + " " + dt.getFullYear() + " - " + timeShow;;

			// let ftshow = string.split(',')[0] + ', ' + ft;
			return ft;

		}

	}


	getDateTime2(dateTime) {


		if (this.authService.isLoggedIn()) {
			var lang = this.authService.getLanguage();


		}else{
			var lang = this.getLanguage();

		}




		if (typeof dateTime != 'undefined') {

			if (lang == 'en') {
				var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

			} else {
				var months = ["Ene", "Feb", "Mar", "Abr", "Puede", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

			}


			// ? str.trim() : ''
			// let dateTimeF = dateTime.split(',')[1].trim();


			let dateTimeFCo = dateTime.split('-')[0].trim() + ' ' + this.timeConvert(dateTime.split('-')[1].trim());

			let dt = new Date(`${dateTimeFCo} UTC`);
			// let dt = new Date(`${dateTimeFCo}`);

			let timeShow = this.timeConvert(`${this.timeSet(dt.getHours())}:${this.timeSet(dt.getMinutes())}`);


			// let ft = dt.getDate() + " " + months[dt.getMonth()] + " " + dt.getFullYear() ;
			let ft = dt.getDate() + " " + months[dt.getMonth()] + " " + dt.getFullYear();;

			// let ftshow = string.split(',')[0] + ', ' + ft;
			return ft;



		}

	}



	chatGroupTimeNotification(timestamp, isNotification = "") {

		if (this.authService.isLoggedIn()) {
			var lang = this.authService.getLanguage();


		}else{
			var lang = this.getLanguage();

		}

		if (lang == 'en') {
			var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

		} else {
			var months = ["Ene", "Feb", "Mar", "Abr", "Puede", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

		}



		const todayDateString = new Date();

		const todayDate = todayDateString.toLocaleDateString();

		const yesterdayString = new Date(todayDateString)
		yesterdayString.setDate(yesterdayString.getDate() - 1)
		const yesterdayDate = yesterdayString.toLocaleDateString();

		const timestampString = new Date(timestamp);
		const chatDate = timestampString.toLocaleDateString();

		// if(lang == 'en'){
		//   var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		// }else{
		//   var  months = ["Enero", "Febrero", "Marzo", "Abril", "Puede", "Junio", "julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

		// }





		if (todayDate == chatDate) {

			if (this.timeStore != "Today") {

				if (lang == "en") {
					this.timeStore = "Today";
					return "Today"
				} else {
					this.timeStore = "Hoy";
					return "Hoy"
				}
				;
			} else {
				if (lang == "en") {
					return (isNotification) ? "Today" : false;
				} else {
					return (isNotification) ? "Hoy" : false;

				}
			}

		} else if (yesterdayDate == chatDate) {

			if (this.timeStore != "Yesterday") {

				if (lang == "en") {

					this.timeStore = "Yesterday";
					return "Yesterday";
				} else {
					this.timeStore = "Ayer";
					return "Ayer";
				}
			} else {

				if (lang == 'en') {
					return (isNotification) ? "Yesterday" : false;
				}
				return (isNotification) ? "Ayer" : false;
			}

		} else {

			if (this.timeStore != chatDate) {
				this.timeStore = chatDate;
				return timestampString.getDate() + ' ' + months[timestampString.getMonth()] + ' ' + timestampString.getFullYear();
			} else {


				// let dateShow = months[timestampString.getMonth()] + ' ' + timestampString.getDate() + ', ' + timestampString.getFullYear();


				let dateShow1 = timestampString.getDate() + ' ' + months[timestampString.getMonth()] + ' ' + timestampString.getFullYear();

				return (isNotification) ? dateShow1 : false;
			}

		}
	}



	chatGroupTime(timestamp, isNotification = "") {


		if (this.authService.isLoggedIn()) {
			var lang = this.authService.getLanguage();


		}else{
			var lang = this.getLanguage();

		}

		const todayDateString = new Date();

		const todayDate = todayDateString.toLocaleDateString();

		const yesterdayString = new Date(todayDateString)
		yesterdayString.setDate(yesterdayString.getDate() - 1)
		const yesterdayDate = yesterdayString.toLocaleDateString();

		const timestampString = new Date(timestamp);
		const chatDate = timestampString.toLocaleDateString();

		// if(lang == 'en'){
		//   var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		// }else{
		//   var  months = ["Enero", "Febrero", "Marzo", "Abril", "Puede", "Junio", "julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

		// }


		if (lang == 'en') {
			var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

		} else {
			var months = ["Ene", "Feb", "Mar", "Abr", "Puede", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

		}



		if (todayDate == chatDate) {

			if (this.timeStore != "Today" && this.timeStore != "Hoy") {

				if (lang == "en") {
					this.timeStore = "Today";
					return "Today"
				} else {
					this.timeStore = "Hoy";
					return "Hoy"
				}
				;
			} else {
				if (lang == "en") {
					return (isNotification) ? "Today" : false;
				} else {
					return (isNotification) ? "Hoy" : false;

				}
			}

		} else if (yesterdayDate == chatDate) {

			if (this.timeStore != "Yesterday" && this.timeStore != "Ayer") {

				if (lang == "en") {

					this.timeStore = "Yesterday";
					return "Yesterday";
				} else {
					this.timeStore = "Ayer";
					return "Ayer";
				}
			} else {

				if (lang == 'en') {
					return (isNotification) ? "Yesterday" : false;
				}
				return (isNotification) ? "Ayer" : false;
			}

		} else {

			if (this.timeStore != chatDate) {
				this.timeStore = chatDate;
				return timestampString.getDate() + ' ' + months[timestampString.getMonth()] + ' ' + timestampString.getFullYear();
			} else {


				// let dateShow = months[timestampString.getMonth()] + ' ' + timestampString.getDate() + ', ' + timestampString.getFullYear();


				let dateShow1 = timestampString.getDate() + ' ' + months[timestampString.getMonth()] + ' ' + timestampString.getFullYear();

				return (isNotification) ? dateShow1 : false;
			}

		}
	}

	chatHistoryTimeAgo(timestamp) {

		const todayDateString = new Date();
		const todayDate = todayDateString.toLocaleDateString();

		const timestampString = new Date(timestamp);
		const chatDate = timestampString.toLocaleDateString();

		if (todayDate == chatDate) {
			//return timestampString.toLocaleTimeString();
			return this.timeConvert(`${this.timeSet(timestampString.getHours())}:${this.timeSet(timestampString.getMinutes())}`)
		} else {
			return this.timeSet(timestampString.getDate()) + '/' + this.timeSet((timestampString.getMonth() + 1)) + '/' + timestampString.getFullYear();
		}

	}

	ArrayFilter(collection: any, key: number) {
		var string;
		var array = [];

		collection.forEach(element => {
			switch (key) {
				case 1: // size & color
					array.push(element.variantValueID);
					break;
				case 2: // sub category
					array.push(element.categoryID);
					break;
				case 3: // sub province
					array.push(element.provinceID);
					break;
				case 4: // sub province
				array.push(element.id);
				break;
			}
		});

		return array.sort();
	}

	paymentStatus(statusNumber: any) {

		let status;
		switch (statusNumber) {
			case "0":
				status = 'Order Placed';
				break;
			case "1":
				status = 'Approved';
				break;
			case "2":
				status = 'Packed';
				break;
			case "3":
				status = 'Shipped';
				break;
			case "4":
				status = 'Zone';
				break;
			case "5":
				status = 'Delivered';
				break;
			case "7":
				status = 'Return Initiated';
				break;
			case "8":
				status = 'Return In Process';
				break;
			case "9":
				status = 'Picked Up';
				break;
			case "10":
				status = 'Received By Seller';
				break;
			case "11":
				status = 'Returned & Refunded';
				break;
		}

		return status;
	}

	createRatingRange(number) {
		var items: number[] = [];
		for (var i = 1; i <= number; i++) {
			items.push(i);
		}
		return items;
	}

	createRatingBlank(number) {

		let isRating = Number(number);
		let isHalfStar = (Number.isInteger(isRating)) ? false : true;

		let plusNumber = Number(5) - Number()

		let showRating = (isHalfStar) ? isRating + Number(1) : isRating;
		let blankRating = Number(5) - Math.floor(showRating);

		var items: number[] = [];
		for (var i = 1; i <= blankRating; i++) {
			items.push(i);
		}
		return items;
	}

	isFloat(n) {
		let isRating = Number(n);
		return (Number.isInteger(isRating)) ? false : true;
	}

	getDiscountPercent(amount, discount) {

		let discount_amount = Number(amount) - Number(discount);
		let discount_percent = Math.round(Number(100 * discount_amount / amount));

		return discount_percent;

	}

	findInvalidControls(form) {
		const invalid = [];
		const controls = form.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}
		return invalid;
	}

	// date format convert to yyyy-mm-dd
	dateFormatConverter(date) {
		let returnDate = `${date.year}-${date.month}-${date.day}`;
		return returnDate;

	}

	public generateThumbnail(videoFile: Blob): Promise<string> {
		const video: HTMLVideoElement = this.document.createElement('video');
		const canvas: HTMLCanvasElement = this.document.createElement('canvas');
		const context: CanvasRenderingContext2D = canvas.getContext('2d');
		return new Promise<string>((resolve, reject) => {
			canvas.addEventListener('error', reject);
			video.addEventListener('error', reject);
			video.addEventListener('canplay', event => {
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
				resolve(canvas.toDataURL());
			});
			if (videoFile.type) {
				video.setAttribute('type', videoFile.type);
			}
			video.preload = 'auto';
			video.src = window.URL.createObjectURL(videoFile);
			video.load();
		});
	}






	//  error handle for login 



	errorhandlerForLogin(error) {

		if (error.status == 400 && (error.error.error_type == 'SESSION_EXPIRED' || error.error.error_type == "INVALID_TOKEN")) {
			this.toastError(error.error.message);
			// this.authService.logout();
			// this.router.navigate(['login']);
		}

		else if (error.status == 400 && error.error.error_type == 'INVALID_USER_TYPE') {
			this.toastError(error.error.message);
			// this.authService.logout();
			// this.router.navigate(['login']);
		}

		else if (error.status == 400 && error.error.error_type == 'ACCOUNT_INACTIVE') {
			this.toastError(error.error.message);
			// this.authService.logout();
			// this.router.navigate(['login']);


		}


		else if (error.status == 400 && error.error.error_type == 'ACCOUNT_BLOCKED') {
			this.toastError(error.error.message);
			// this.authService.logout();
			// this.router.navigate(['login']);
			// },500);

		}

		else if (error.status == 400 && error.error.error_type == 'USER_NOT_FOUND') {
			// this.toastError('User not found');



			// this.authService.logout();
			// this.router.navigate(['login']);

		} else if (error.status == 400) {
			this.toastError(error.error.message);
		} else if (error.status == 0) {
			this.toastError("No internet connection");
		} else {
			throw error;
		}
	}
	errorhandler(error) {

		if (error.status == 400 && (error.error.error_type == 'SESSION_EXPIRED' || error.error.error_type == "INVALID_TOKEN")) {
			// this.toastError(error.error.message);
			if (this.authService.getLanguage() == 'en') {
				this.toastALert('Your session is expired, please login again');

			} else {
				this.toastALert('Su sesión ha caducado, por favor inicie sesión de nuevo');

			}
			this.authService.logout();
			this.router.navigate(['login']);
		}

		else if (error.status == 400 && error.error.error_type == 'INVALID_USER_TYPE') {
			this.toastError(error.error.message);
			this.authService.logout();
			this.router.navigate(['login']);
		}

		else if (error.status == 400 && error.error.error_type == 'ACCOUNT_INACTIVE') {

			if (this.authService.getLanguage() == 'en') {
				this.toastALert('Your session is expired, please login again');

			} else {
				this.toastALert('Su sesión ha caducado, por favor inicie sesión de nuevo');

			}

			// setTimeout(function(){
			this.authService.logout();
			this.router.navigate(['login']);
			// },500);

		}


		else if (error.status == 400 && error.error.error_type == 'ACCOUNT_BLOCKED') {
			// this.toastError(error.error.message);
			if (this.authService.getLanguage() == 'en') {
				this.alertShowPopup('Your session is expired, please login again');

			} else {
				this.alertShowPopup('Su sesión ha caducado, por favor inicie sesión de nuevo');

			}

			// this.toastError('Sorry, your account is blocked by administrator');
			// setTimeout(function(){
			this.authService.logout();
			this.router.navigate(['login']);
			// },500);

		}

		else if (error.status == 400 && error.error.error_type == 'USER_NOT_FOUND') {
			// this.toastError('User not found');


			//setTimeout(function(){
			if (this.authService.getLanguage() == 'en') {
				this.alertShowPopup('Your session is expired, please login again');

			} else {
				this.alertShowPopup('Su sesión ha caducado, por favor inicie sesión de nuevo');

			}
			this.authService.logout();
			this.router.navigate(['login']);
			//},500);
		} else if (error.status == 400) {
			this.toastError(error.error.message);
		} else if (error.status == 0) {
			this.toastError("Something went wrong");
		} else {
			throw error;
		}
	}
}
